import React from 'react'
import CheckBox from '../../components/CheckBoxes'
import { TextField, TextArea } from '../../components/Forms'

const FormDemo = () =>
  <form>
    <br />
    <hr />
    <h1>Forms</h1>
    <h2>Checkboxes</h2>
    <p>
      <CheckBox checked>
        Checked
      </CheckBox>
    </p>
    <p>
      <CheckBox checked={false}>
        Unchecked
      </CheckBox>
    </p>
    <p>
      <CheckBox className='focused' checked={false}>
        Focused
      </CheckBox>
    </p>
    <p>
      <CheckBox disabled checked={false}>
        Disabled
      </CheckBox>
    </p>
    <h2>TextFields</h2>
    <TextField label='Email Address' placeholder='geir@credlock.com' />
    <TextField label='Email Address' placeholder='geir@credlock.com' meta='error' />
    <TextField label='Email Address' placeholder='geir@credlock.com' meta='success' />
    <TextArea label='Tell your story' placeholder='Say something..' info={{txt: 'Some info txt', icon: {icon: 'user'}}} />
    <br />
  </form>

export default FormDemo
