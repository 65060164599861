import React from 'react'
import { dateToLocale } from 'components/Helpers/index'

const noPeriod = (period) =>
  !period || (!period.from && !period.to)

const Period = ({ period }) => {
  if (noPeriod(period)) {
    return <span>-</span>
  }

  const from = dateToLocale(period.from)
  const to = dateToLocale(period.to)

  // Return only one date if just one of them is present
  if (!period.to) return <span>{from}</span>
  if (!period.from) return <span>{to}</span>

  return (
    <span className='period'>
      {from} - {to}
    </span>
  )
}

export default Period
