const CHART_COLORS = {
  primary: 'rgba(125, 96, 255, 1)',
  secondary: 'rgba(239, 66, 102, 1)',
  transparent: {
    primary: 'rgba(125, 96, 255, 0)',
    secondary: 'rgba(239, 66, 102, 0)'
  }
}

export default CHART_COLORS
