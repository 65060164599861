import React, { useState, useEffect } from 'react'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {format} from 'date-fns'
import humanize from './humanize'
import ErrorMessage from './ErrorMessage'
import { useFormContext } from "react-hook-form"

const DateInput = ({ label, name, required = false }) => {
  const { register, errors, setValue, getValues } = useFormContext()

  useEffect(() => {
    register({ name: name })
  }, [register, name]);

  // If we're in the context of an existing date, use it!
  const existingValueOrNull = () => {
    let existingValue = getValues()[name]
    return existingValue ? Date.parse(existingValue) : null
  }

  const [date, setDate] = useState(existingValueOrNull())

  /*
   * onChange must do double bookkeeping (not cool):
   *
   *   1. set value on underlying DOM element for react-hook-form
   *   2. maintain controlled state using setDate() used to
   *      initialize DatePicker
   *
   *
   * Error message wrapped in div to force placement
   * under DatePicker.
   */
  return (
    <div style={{margin: '0 0.5rem 1rem 0'}}>
      <label style={{marginBottom: 0}}>
        { label ? label : humanize(name) }
      </label>

      <DatePicker
        selected = { date }
        onChange = { date => {
          var dateOnly = required ? new Date() : null // default to current date when required = true
          console.log(dateOnly)
          if (date !== null) {
          // Problem: the react-datepicker uses browser timezone AND includes the time
          // component even though we're only interested in the dates.
          // Then on submit it converts to UTC and we may be off by one...
          //
          // We're turning to Shahab for a solution: https://github.com/Hacker0x01/react-datepicker/issues/1018#issuecomment-517828476
            dateOnly = format(date,"YYYY-MM-DD").split('T')[0];//right
          } else if (required) {
            // ensure a date is set if date is required
            date = dateOnly
          }
          setValue(name, dateOnly) // This will be submitted to server
          setDate(date) // This will be used by react-datepicker only
        }}
        name = { name }
        className = { errors[name] ? 'error' : '' }
      />

      <div>
        <ErrorMessage name={name} errors={errors} />
      </div>
    </div>
  )
}

export default DateInput
