import React, { useState, useEffect } from 'react'
import FreePassAPI from 'services/api/free_passes'
import { Redirect } from 'react-router-dom'
import {
  viewFreePassUrl,
  Breadcrumbs,
} from './components'
import {
  Form,
  FormLine,
  TextInput,
  TextAreaInput,
  EmailInput,
  Mobile,
  Submit,
} from 'components/Forms'

const EditPersonlia = ({ match }) => {
  const { id, operatorID: operatorId } = match.params
  const [updatedId, setUpdatedId] = useState(null)
  const [freePass, setFreePass] = useState(null)
  const [validationErrors, setValidationErrors] = useState({})

  useEffect(() => {
    FreePassAPI.fetchFreePass(operatorId, id, setFreePass)
  }, [id, operatorId])

  if (!freePass) return null

  const onSubmit = data => {
    FreePassAPI.update(
      { ...data, id },
      id,
      successfulResult => setUpdatedId(successfulResult.id),
      errorResult => setValidationErrors(errorResult)
    )
  }

  if (updatedId) return <Redirect to={viewFreePassUrl(updatedId, operatorId)} />

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <Breadcrumbs operatorId={operatorId} freePass={freePass} />
        Edit free pass
      </h1>
      <div className='content-box form'>
        <Form onSubmit={onSubmit} defaultValues={freePass} validationErrors={validationErrors}>
          <Mobile/>
          <EmailInput name='email'/>
          <FormLine>
            <TextInput name='first_name'/>
            <TextInput name='last_name'/>
          </FormLine>
          <TextAreaInput name='description'/>
          <FormLine>
            <TextInput name='company'/>
            <TextInput name='title' label='Role'/>
          </FormLine>
          <Submit label='Update' />
        </Form>
      </div>
    </div>
  )
}

export default EditPersonlia
