import React from 'react'

const ProductIcon = props => (
  <svg width='1em' height='1em' viewBox='0 0 20 23' {...props}>
    <path
      d='M9.548 12.131v9.953L.719 16.787A.454.454 0 0 1 .5 16.4V6.703l9.048 5.428zm.904 0L19.5 6.703v9.7c0 .159-.084.306-.22.388l-8.828 5.293V12.13zM10 11.348L.952 5.919 9.767.63a.457.457 0 0 1 .466 0l8.815 5.29L10 11.347z'
    />
  </svg>
)

export default ProductIcon
