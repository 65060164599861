import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import AppContext from 'AppContext'
import { ActionButton } from 'components/Buttons'
import { DetailBox } from 'components/Boxes'
import PT_API from 'services/api/pts'
import { StatusBadge } from 'components/Badges'
import { format } from 'date-fns'
import { KeyActivation } from './components'

const baseUrl = operatorId => `/operators/${operatorId}/pts`

const PT = ({match}) => {
  const { id } = match.params
  const { operator: { id: operatorId } } = React.useContext(AppContext)
  const [pt, setPT] = useState(null)

  useEffect(() => {
    PT_API.fetchPT(operatorId, id, setPT)
  }, [id, operatorId])

  if (!pt) return null

  // Change expired badges from default grey to red
  let cssStatus = pt.status === 'expired' ? 'stopped' : 'active'

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <span className='breadcrumbs'>
          <Link to={ `${baseUrl(operatorId)}/by-pt` }>PTs</Link>
        </span>

        { `${pt.first_name} ${pt.last_name}` }

        <span style={{paddingLeft: '10px'}}>
          <StatusBadge status={pt.status} cssStatus={cssStatus} />
        </span>
      </h1>
      <div className='box-wrapper'>
        <DetailBox
          header='Personalia'
          items={[
            { label: 'Name', value: `${pt.first_name} ${pt.last_name}` },
            { label: 'Mobile', value: `${pt.mobile_prefix} ${pt.mobile_number }` },
            { label: 'E-mail', value: pt.email },
            { label: 'Street', value: pt.address_street },
            { label: 'Postal code', value: pt.address_postal_code },
            { label: 'Postal location', value: pt.address_postal_location },
            { label: 'Birthdate', value: pt.birthdate, type: 'date' },
          ]}
        >
          <ActionButton
            txt='Edit personalia'
            link={`${baseUrl(operatorId)}/${pt.id}/personalia/edit`}
            icon='edit white'
          />
        </DetailBox>
        <DetailBox
          header='Contract'
          items={[
            { label: 'Begin date', value: pt.begin_date, type: 'date' },
            { label: 'End date', value: pt.end_date, type: 'date' },
            { label: 'Company name', value: pt.company_name },
            { label: 'Company registration number', value: pt.company_registration_number },
            { label: 'Monthly fee', value: pt.monthly_fee, type: 'money' },
            { label: 'Fee payable from date', value: pt.fee_payable_from_date, type: 'date' },
            { label: 'Show PT on website', value: String(pt.publish) },
          ]}
        >
          <ActionButton
            txt='Edit contract'
            link={`${baseUrl(operatorId)}/${pt.id}/contract/edit`}
            icon='edit white'
          />
        </DetailBox>
        <PTLocations pt={pt} operatorId={operatorId} />
        <KeyActivation operatatorid={`${baseUrl(operatorId)}`} ownerid={`${pt.id}`}/>
        <PaymentMethods pt={pt} />
        <DetailBox
          header='Login to PT Dashboard'
        >
          <ActionButton
            txt='Login as PT'
            onClick={ () => ptLogin(id) }
          />
          {/*
          <br />
          <ActionButton
            txt='Login as PT (New site, unstable)'
            onClick={ () => ptLoginAlpha(id) }
           />
           */
          }
        </DetailBox>
      </div>
    </div>
  )
}

const PaymentMethods = ({ pt }) => {
  const pms = pt.payment_methods
  return (pms.map(pm => (
    <DetailBox
      header='Payment method'
      items={pmDetails(pm)} />
  )))
}

function pmDetails(pm) {
  return Object.entries(pm.provider_details)
    .map(([label, value]) => ({ label, value }))
}

const Keys = ({ pt }) => {
  const items = pt.accuser
    .map(a => a.keys)
    .flat()
    .map(k => ({ label: k.type, value: keyDescription(k) }))
  return (
    <DetailBox
      header='Keys'
      items={items} />
  )
}

function keyDescription(k) {
  const from = format(k.valid_from, 'YYYY-MM-DD')
  const to = k.valid_to ? format(k.valid_to, 'YYYY-MM-DD') : 'forever'
  return `${k.code} valid from ${from} to ${to}`
}

const PTLocations = ({ operatorId, pt }) => {
  const { locations } = React.useContext(AppContext)

  const locationNameOrId = (id) =>
    locations ? locations.find(l => l.id === id).name : id

  return (
    <DetailBox
      header='Locations'
      items={ pt.roles.map(assignment =>
        ({
          label: locationNameOrId(assignment.location_id),
          value: assignment.role
        })
      )}
    >
      <ActionButton
        txt='Edit locations'
        link={`${baseUrl(operatorId)}/${pt.id}/locations/edit`}
        icon='edit white'
      />
    </DetailBox>
  )
}

const ptLogin = (id) => {
  PT_API.auth(id, (response) => {
    const form = document.createElement('form')
    form.method = 'GET'
    form.action = process.env.REACT_APP_PT_UI + '/login'
    form.target = '_blank'

    const accessTokenElem = document.createElement('input')
    accessTokenElem.name = 'access_token'
    accessTokenElem.value = response.token
    accessTokenElem.type = 'hidden'
    form.appendChild(accessTokenElem)

    document.body.appendChild(form)

    form.submit()
  })
}

const ptLoginAlpha = (id) => {
  PT_API.auth(id, (response) => {
    window.open(`${process.env.REACT_APP_PT_SERVER}/authorize?token=${response.token}`, '_blank')
  })
}

export default PT
