import React, { useEffect } from 'react'
import Select from 'react-select'
import { useForm, useFormContext, Controller } from "react-hook-form"
import AppContext from 'AppContext'

// Set default selected locations
const defaultValues = (locations, selectedIds) => {
  if (!selectedIds) return []

  const result = []

  locations.forEach(l => {
    if (selectedIds.includes(l.id)) {
      result.push({ value: l.id, label: l.name })
    }
  })

  return result
}

const LocationSelect = ({ name, selectedIds = null }) => {
  const { locations } = React.useContext(AppContext)
  const { control } = useForm()
  const { register, setValue } = useFormContext()

  useEffect(() => {
    register({ name: name })
  }, [register, name]);

  if (!locations) return null

  return (
    <Controller
      as={Select}
      isMulti
      options={locations.map(l => ({ value: l.id, label: l.name }))}
      name={name}
      control={control}
      onChange={(selected) => {
        // Register selected values with the underlying DOM component
        // as react-select is a controlled component
        setValue(name, selected[0] ? selected[0].map( location => location.value ) : [])
      }}
      defaultValue={defaultValues(locations, selectedIds)}
    />
  )
}

export default LocationSelect
