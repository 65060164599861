import React from 'react'
import { Link } from 'react-router-dom'

import AppContext from 'AppContext'
import { DetailBox } from 'components/Boxes'
import { ActionButton } from 'components/Buttons'
import { timeToLocale } from 'components/Helpers'

const AccessToLocations = ({ restrictedTo, expanded, baseUrl, onClick }) => {
  const { locations } = React.useContext(AppContext)

  if (!locations) return null

  const Location = ({ location }) =>
    <Link
      to={{
        pathname: `${baseUrl}/locations/${location.id}`,
        state: { obj: location }
      }}
    >
      {location.name}
      <br />
    </Link>

  // Defaults to Unknown if for some reason the
  // membership does not have an access control user
  const value = restrictedTo
    ? restrictedTo.length || 'all'
    : 'Unknown'

  // Just return the text if the member does not have
  // an array of location_ids it is restricted to
  if (typeof value === 'string') { return value }

  return (
    <span onClick={onClick} style={{ cursor: 'pointer' }}>
      <Link to='#'>
        {value}
      </Link>
      <div style={{ display: `${expanded ? 'block' : 'none'}` }}>
        {
          locations
            .filter(l => restrictedTo.includes(l.id))
            .map(l => <Location location={l} />)
        }
      </div>
    </span>
  )
}

const Membership = ({ member, match }) => {
  const { operator } = React.useContext(AppContext)
  const [displayLocations, setDisplayLocations] = React.useState(false)

  const calcBindingEndDate = (member, bindingLength) => {
    const startDate = new Date(member.begin_date_promotion)
    const endDate = new Date(startDate)
    endDate.setMonth(startDate.getMonth() + bindingLength + 1) // +1 because we're gonna also do end of month which subtracts a month
    endDate.setDate(0) // End of month
    return endDate
  }

  const baseUrl = `/operators/${operator.id}`
  const bindingEndDate = !!member.promotion && member.promotion.binding_period_in_months > 0
    ? calcBindingEndDate(member, member.promotion.binding_period_in_months)
    : null

  const items = [
    {
      label: 'ID',
      value: member.oid
    },
    {
      label: 'Primary location',
      value:
  <Link
    to={{
      pathname: `${baseUrl}/locations/${member.primary_location.id}`,
      state: { obj: member.primary_location }
    }}
  >
    {member.primary_location.name}
  </Link>
    },
    {
      label: 'Access to locations',
      // We don't want to display this for cancelled/stopped members
      visible: !member.status.match(/cancelled/) && member.status !== 'stopped',
      value:
  <AccessToLocations
    onClick={() => setDisplayLocations(!displayLocations)}
    restrictedTo={member.restrict_to_location_ids}
    expanded={displayLocations}
    baseUrl={baseUrl}
  />
    },
    {
      label: 'Product',
      value:
  <Link to={`${baseUrl}/products/${member.product.id}`}>
    {member.product.name}
  </Link>
    },
    {
      label: 'Promotion',
      value:
        member.promotion
          ? <Link to={`${baseUrl}/promotions-v2/${member.promotion.promotion_type}/${member.promotion.id}`}>
            {member.promotion.name}
          </Link>
          : 'N/A'

    },
    member.begin_date_promotion !== member.begin_date
      ? {
          label: 'Promotion changed on',
          value: member.begin_date_promotion,
          type: 'date'
        }
      : null,
    {
      label: 'Purchased on',
      value: timeToLocale(member.created_at, operator.timezone), // Must convert from UTC before extracting date
      type: 'date'
    },
    {
      label: 'Begin date',
      value: member.begin_date,
      type: 'date'
    },
    {
      label: 'End date',
      value: member.end_date,
      type: 'date'
    },
    bindingEndDate
      ? {
          label: 'Binding end date',
          value: bindingEndDate,
          type: 'date'
        }
      : null,
    {
      label: 'Sheltered',
      value: member.sheltered ? 'Yes' : 'No'
    },
    {
      label: 'Referral code',
      value: member.referral_code
    }
  ].filter(item => item !== null)

  return (
    <DetailBox header='Membership' items={items}>
      <ActionButton txt='Manage membership' icon='edit white' link={`${match.url}/manage`} />
    </DetailBox>
  )
}

export default Membership
