import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Members, Member, MembershipsV2, IncompleteSignups, RevenueForecast } from './scenes/Customers'
import { Roadmap } from './scenes/Development'
import { Dashboard } from './scenes/Dashboard'
import StyleGuide from './scenes/StyleGuides'
import { PrivateRoute } from './components/WithAuth'
import { NoAccessPage } from './scenes/NoAccess'
import {
  Location,
  LocationList,
  NewLocation,
  EditLocation,
} from './scenes/Locations'
import {
  AdminList,
  Admin,
  NewAdmin,
  EditAdminPersonlia,
  EditAdminDates,
  EditAdminRoles,
} from './scenes/Admins'
import {
  FreePassList,
  NewFreePass,
  NewFreePassShort,
  FreePass,
  EditFreePassPersonlia,
  EditFreePassDates,
  EditFreePassPrivilegedAccess,
} from './scenes/FreePasses'

import {
  DistributorList,
  DistributorCreate,
  DistributorEdit,
  Distributor,
  PaidPassesGenerate
} from './scenes/PaidPasses'

import {
  ReportList,
  Report,
} from './scenes/Reports'
import Leads from './scenes/Leads'
import Promotions, {
  PromotionsV2,
  Promotion,
  NewSalesPromotion,
  EditPromotionDetails,
  EditPromotionSalesPeriod,
  EditPromotionPresentation,
  EditPromotionPTHours,
  EditPromotionSignupFee,
  EditPromotionTrialDays,
  EditPromotionPaymentPlan,
  EditPromotionTriggerLocations,
  EditPromotionReferralRewards,
} from './scenes/Promotions'
import { AccessChip, PTPackage } from './scenes/AddOns'
import ProductV2 from './scenes/Products'
import UnderDevelopmentPage from './scenes/UnderDevelopment'
import { ErrorComponent } from './scenes/Error'
import {
  PTListV2,
  NewPT,
  PT,
  EditPTPersonlia,
  EditPTContract,
  EditPTLocations,
} from './scenes/PTs'
import FacilityManagement from './scenes/FacilityManagement'
import Wellpass from './scenes/Wellpass'

const MemberRoutes = (props) => (
  [
    <PrivateRoute
      key='members'
      exact path='/operators/:operatorID/members'
      permission='memberships.member.list'
      component={Members}
      {...props}
    />,
    <PrivateRoute
      key='member'
      path='/operators/:operatorID/members/:memberID'
      permission='memberships.member.get'
      component={Member}
      {...props}
    />,
    <PrivateRoute
      key= 'memberships'
      exact path='/operators/:operatorID/memberships'
      permission='memberships.member.list'
      component={MembershipsV2}
      {...props}
    />,
    <PrivateRoute
      key= 'incomplete-signups'
      exact path='/operators/:operatorID/memberships/incomplete-signups'
      permission='memberships.member.list'
      component={IncompleteSignups}
      {...props}
    />,
    <PrivateRoute
      key= 'revenue-forecast'
      exact path='/operators/:operatorID/memberships/revenue-forecast'
      permission='memberships.member.list'
      component={RevenueForecast}
      {...props}
    />,
  ]
)

const ProductRoutes = (props) => [
  <PrivateRoute
    key='product'
    path='/operators/:operatorID/product'
    permission='memberships.product.get'
    component={ProductV2}
    {...props}
  />,
]

const LeadRoutes = (props) => {
  return [
    <PrivateRoute
      key='leads'
      path='/operators/:operatorID/leads'
      permission='memberships.member.list'
      component={Leads}
      {...props}
    />,
  ]
}

const FacilityManagementRoutes = (props) => {
  return [
    <Route
      key='maintenance'
      path='/operators/:operatorID/maintenance'
      component={FacilityManagement}
    />
  ]
}

const WellpassRoutes = (props) => {
  return [
    <Route
      key='wellpass'
      path='/operators/:operatorID/qualitrain-wellpass'
      component={Wellpass}
    />
  ]
}

const AddOnRoutes = (props) => [
  <PrivateRoute
    key="add-ons"
    path="/operators/:operatorID/add-ons/access-chip"
    permission="memberships.product.list"
    component={AccessChip}
    match={props.match}
    {...props}
  />,
  <PrivateRoute
    key="pt-packages"
    path="/operators/:operatorID/add-ons/pt-packages"
    permission="pts.pt.admin"
    component={PTPackage}
    match={props.match}
    {...props}
  />

]

const DummyLinks = () => {
  const baseURL = '/operators/:operatorID'
  return (
    [
      <Route
        key='settings'
        path={`${baseURL}/settings`}
        component={UnderDevelopmentPage}
      />
    ]
  )
}

const LocationRoutes = (props) => {
  const locationPath = '/operators/:operatorID/locations'
  return (
    [
      <PrivateRoute key='locations'
        exact path={locationPath}
        permission='operators.location.list'
        component={LocationList}
        {...props}
      />,
      <PrivateRoute key='new_location'
        exact path={`${locationPath}/new`}
        permission='operators.location.create'
        component={NewLocation}
        {...props}
      />,
      <PrivateRoute key='location'
        exact path={`${locationPath}/:locationID`}
        permission='operators.location.get'
        component={Location}
        {...props}
      />,
      <PrivateRoute key='edit_location'
        exact path={`${locationPath}/:locationID/edit`}
        permission='operators.location.get'
        component={EditLocation}
        {...props}
      />
    ]
  )
}

const AdminRoutes = (props) => {
  const adminPath = '/operators/:operatorID/administrators'
  return (
    [
      <PrivateRoute
        key='admins'
        exact path={adminPath}
        permission='admins.admin.list'
        component={AdminList}
        {...props}
      />,
      <PrivateRoute
        key='newAdmin'
        exact path={`${adminPath}/new`}
        permission='admins.admin.list' // TODO Correct permission
        component={NewAdmin}
        {...props}
      />,
      <PrivateRoute
        key='admin'
        exact path={`${adminPath}/:adminUUID`}
        permission='admins.admin.get'
        component={Admin}
        {...props}
      />,
      <PrivateRoute
        key='editAdminPersonlia'
        exact path={`${adminPath}/:id/edit/personlia`}
        permission='admins.admin.list' // TODO Correct permission
        component={EditAdminPersonlia}
        {...props}
      />,
      <PrivateRoute
        key='editAdminDates'
        exact path={`${adminPath}/:id/edit/dates`}
        permission='admins.admin.list' // TODO Correct permission
        component={EditAdminDates}
        {...props}
      />,
      <PrivateRoute
        key='editAdminRoles'
        exact path={`${adminPath}/:id/edit/roles`}
        permission='admins.admin.list' // TODO Correct permission
        component={EditAdminRoles}
        {...props}
      />,
    ]
  )
}

const PaidPassRoutes = (props) => {
  const paidPassPath = '/operators/:operatorId/paid-passes'
  return (
    [
      <PrivateRoute
        key='paidPasses'
        exact path={paidPassPath}
        permission='admins.admin.list'
        component={DistributorList}
        {...props}
      />,

      <PrivateRoute
        key='paidPasses'
        exact path={`${paidPassPath}/distributors/new`}
        permission='admins.admin.list'
        component={DistributorCreate}
        {...props}
      />,

      <PrivateRoute
        key='paidPasses'
        exact path={`${paidPassPath}/distributors/:id`}
        permission='admins.admin.list'
        component={Distributor}
        {...props}
      />,

      <PrivateRoute
        key='paidPasses'
        exact path={`${paidPassPath}/distributors/:id/edit`}
        permission='admins.admin.list'
        component={DistributorEdit}
        {...props}
      />,

      <PrivateRoute
        key='paidPasses'
        exact path={`${paidPassPath}/distributors/:distributorId/paid-passes/new`}
        permission='admins.admin.list'
        component={PaidPassesGenerate}
        {...props}
      />,
    ]
  )
}

const FreePassRoutes = (props) => {
  const freePassPath = '/operators/:operatorID/free-passes'
  return (
    [
      <PrivateRoute
        key='freePasses'
        exact path={freePassPath}
        permission='admins.admin.list' // TODO Correct permission
        component={FreePassList}
        {...props}
      />,
      <PrivateRoute
        key='newFreePassShort'
        exact path={`${freePassPath}/short/new`}
        permission='admins.admin.list' // TODO Correct permission
        component={NewFreePassShort}
        {...props}
      />,
      <PrivateRoute
        key='newFreePassLong'
        exact path={`${freePassPath}/long/new`}
        permission='admins.admin.list' // TODO Correct permission
        component={NewFreePass}
        {...props}
      />,
      <PrivateRoute
        key='freePass'
        exact path={`${freePassPath}/:id`}
        permission='admins.admin.list' // TODO Correct permission
        component={FreePass}
        {...props}
      />,
      <PrivateRoute
        key='editFreePassPersonlia'
        exact path={`${freePassPath}/:id/personlia/edit`}
        permission='admins.admin.list' // TODO Correct permission
        component={EditFreePassPersonlia}
        {...props}
      />,
      <PrivateRoute
        key='editFreePassDates'
        exact path={`${freePassPath}/:id/dates/edit`}
        permission='admins.admin.list' // TODO Correct permission
        component={EditFreePassDates}
        {...props}
      />,
      <PrivateRoute
        key='editFreePassPriviligedAccess'
        exact path={`${freePassPath}/:id/priviliged-access/edit`}
        permission='admins.admin.list' // TODO Correct permission
        component={EditFreePassPrivilegedAccess}
        {...props}
      />,
    ]
  )
}

const ReportRoutes = (props) => {
  const reportPath = '/operators/:operatorID/reports'
  return (
    [
      <PrivateRoute
        key='reports'
        exact path={reportPath}
        permission='admins.admin.list' // TODO Correct permission
        component={ReportList}
        {...props}
      />,
      <PrivateRoute
        key='report'
        exact path={`${reportPath}/:name`}
        permission='admins.admin.list' // TODO Correct permission
        component={Report}
        {...props}
      />,
    ]
  )
}

const DevelopmentRoutes = (props) => {
  const developmentPath = '/operators/:operatorID/development'
  return (
    [
      <PrivateRoute
        key='roadmap'
        exact path={developmentPath}
        permission='admins.admin.list' // TODO Correct permission
        component={Roadmap}
        {...props}
      />,
    ]
  )
}

const PTRoutes = (props) => {
  const ptPath = '/operators/:operatorID/pts'

  // Had to duplicate routes with exact paths into new microfrontend for legacy components to still work
  return (
    [
      <PrivateRoute
        key='PTsByLocation'
        exact path={`${ptPath}/by-location`}
        permission='pts.pt.admin'
        component={PTListV2}
        {...props}
      />,
      <PrivateRoute
        key='PTsById'
        exact path={`${ptPath}/by-pt`}
        permission='pts.pt.admin'
        component={PTListV2}
        {...props}
      />,
      <PrivateRoute
        key='newPT'
        exact path={`${ptPath}/new`}
        permission='pts.pt.admin'
        component={NewPT}
        {...props}
      />,
      <PrivateRoute
        key='pt'
        exact path={`${ptPath}/:id`}
        permission='pts.pt.admin'
        component={PT}
        {...props}
      />,
      <PrivateRoute
        key='editPTPersonalia'
        exact path={`${ptPath}/:id/personalia/edit`}
        permission='pts.pt.admin'
        component={EditPTPersonlia}
        {...props}
      />,
      <PrivateRoute
        key='editPTContract'
        exact path={`${ptPath}/:id/contract/edit`}
        permission='pts.pt.admin'
        component={EditPTContract}
        {...props}
      />,
      <PrivateRoute
        key='editPTLocations'
        exact path={`${ptPath}/:id/locations/edit`}
        permission='pts.pt.admin'
        component={EditPTLocations}
        {...props}
      />,
    ]
  )
}

const PromotionRoutes = (props) => {
  // const promotionPath = '/operators/:operatorID/promotions'
  return (
    [
      <PrivateRoute
        key= 'listPromotions'
        path='/operators/:operatorID/promotions-v2'
        permission='memberships.promotion.list'
        component={PromotionsV2}
        {...props}
      />,
      <PrivateRoute
        key= 'listPromotions'
        path='/operators/:operatorID/promotions/list'
        permission='memberships.promotion.list'
        component={Promotions}
        {...props}
      />,
      <PrivateRoute
        key='newSalesPromotion'
        exact path='/operators/:operatorID/promotions/sales/new'
        permission='memberships.promotion.get' // TODO correct permission
        component={NewSalesPromotion}
        {...props}
      />,
      <PrivateRoute
        key='viewPromotion'
        exact path='/operators/:operatorID/promotions/:promotionID'
        permission='memberships.promotion.get'
        component={Promotion}
        {...props}
      />,
      <PrivateRoute
        key='editPromotionDetails'
        path='/operators/:operatorID/promotions/:promotionID/details/edit'
        permission='memberships.promotion.get' // TODO correct permission
        component={EditPromotionDetails}
        {...props}
      />,
      <PrivateRoute
        key='editSalesPeriod'
        path='/operators/:operatorID/promotions/:promotionID/sales-period/edit'
        permission='memberships.promotion.get' // TODO correct permission
        component={EditPromotionSalesPeriod}
        {...props}
      />,
      <PrivateRoute
        key='editPresentation'
        path='/operators/:operatorID/promotions/:promotionID/presentation/edit'
        permission='memberships.promotion.get' // TODO correct permission
        component={EditPromotionPresentation}
        {...props}
      />,
      <PrivateRoute
        key='editTrialDays'
        path='/operators/:operatorID/promotions/:promotionID/trial-days/edit'
        permission='memberships.promotion.get' // TODO correct permission
        component={EditPromotionTrialDays}
        {...props}
      />,
      <PrivateRoute
        key='editPTHours'
        path='/operators/:operatorID/promotions/:promotionID/pt-hours/edit'
        permission='memberships.promotion.get' // TODO correct permission
        component={EditPromotionPTHours}
        {...props}
      />,
      <PrivateRoute
        key='editSignupFee'
        path='/operators/:operatorID/promotions/:promotionID/signup-fee/edit'
        permission='memberships.promotion.get' // TODO correct permission
        component={EditPromotionSignupFee}
        {...props}
      />,
      <PrivateRoute
        key='editPaymentPlan'
        path='/operators/:operatorID/promotions/:promotionID/payment-plan/edit'
        permission='memberships.promotion.get' // TODO correct permission
        component={EditPromotionPaymentPlan}
        {...props}
      />,
      <PrivateRoute
        key='editPaymentTriggerLocations'
        path='/operators/:operatorID/promotions/:promotionID/trigger-locations/edit'
        permission='memberships.promotion.get' // TODO correct permission
        component={EditPromotionTriggerLocations}
        {...props}
      />,
      <PrivateRoute
        key='editReferralRewards'
        path='/operators/:operatorID/promotions/:promotionID/referrer-rewards/edit'
        permission='memberships.promotion.get' // TODO correct permission
        component={EditPromotionReferralRewards}
        {...props}
      />,
    ]
  )
}

const Router = (props) => {
  if (props.error) {
    return (
      <main>
        <div className={'index'} style={{ paddingTop: '70px' }}>
          <div className={'content-box error'}>
            <ErrorComponent error={props.error.reason} />
          </div>
        </div>
      </main>
    )
  }

  return (
    <main>
      <Switch>
        <Route path='/dashboard' component={Dashboard}/>
        <Route path='/styleguide' component={StyleGuide}/>
        <Route path='/under-development' component={UnderDevelopmentPage}/>
        <PrivateRoute
          path='/no-access'
          permisson='no-access'
          component={NoAccessPage}
        />
        {PromotionRoutes(props)}
        {ProductRoutes(props)}
        {AddOnRoutes(props)}
        {LeadRoutes(props)}
        {WellpassRoutes(props)}
        {FacilityManagementRoutes(props)}
        {LocationRoutes(props)}
        {MemberRoutes(props)}
        {DummyLinks()}
        {ReportRoutes(props)}
        {AdminRoutes(props)}
        {FreePassRoutes(props)}
        {PaidPassRoutes(props)}
        {PTRoutes(props)}
        {DevelopmentRoutes(props)}
        <Redirect from='/' to='dashboard'/>
      </Switch>
    </main>
  )
}

export default Router
