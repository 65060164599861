import React from 'react'
import Select from 'react-select'

const filterColumns = (columns, selectedColumns) => {
  if (!selectedColumns || selectedColumns.length < 1) {
    return columns.map(c => Object.assign({}, c, { show: false }))
  }

  return columns.map(c => {
    const found = selectedColumns.find(i => i.value === c.accessor)

    if (found) {
      return Object.assign({}, c, { show: true })
    } else {
      return Object.assign({}, c, { show: !c.accessor })
    }
  })
}

const ColumnSelector = ({ columns, onClick, setColumns }) => {
  const columnsSelector = columns
    .filter(f => f.accessor)
    .map(c => ({ value: c.accessor, label: c.Header, show: c.show }))

  const defaultValue = columnsSelector.filter(f => f.show)

  return (
    <div>
      <h3>Select columns</h3>
      <Select
        isMulti
        options={columnsSelector}
        defaultValue={defaultValue}
        onChange={(selectedColumns) =>
          setColumns(filterColumns(columns, selectedColumns))
        }
      />
    </div>
  )
}

export {
  ColumnSelector,
  filterColumns, // Exported for test
}
