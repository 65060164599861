import React from 'react'

const LogStatusBadge = ({ type }) => {
  let className
  switch (type) {
    case 'my page':
      className = 'black'
      break
    case 'email':
      className = 'accent-2'
      break
    case 'sms':
      className = 'secondary'
      break
    case 'departure':
      className = 'accent-2'
      break
    case 'arrival_building':
      className = 'grey'
      break
    case 'arrival':
      className = 'accent-1'
      break
    default:
      className = 'grey'
  }

  return (
    <Badge
      className={`log ${className}`}
      txt={type.toUpperCase()}
    />
  )
}

const KeyStatusBadge = ({ validTo, txt }) => {
  // Set color based on if the key is valid or not
  // TODO: should perhaps get more info from backends and display as
  //  red if it isn't successfully uploaded to the doors
  const className = validTo && new Date(validTo) < Date.now()
    ? 'secondary'
    : 'accent-1'

  return (
    <Badge className={className} txt={txt} />
  )
}

// By default the status is used as className,
// but in some cases we want to override that and then
// a separate cssStatus can be included.
// Eg. if you want the expired status to displayed
// as if it was stopped status;
// then add status = expired and cssStatus = stopped
const StatusBadge = ({ status, cssStatus }) => {
  const className = cssStatus ? cssStatus : status.toLowerCase()

  return (
    <Badge
      className={`status ${className}`}
      txt={status.toUpperCase()}
    />
  )
}

const Badge = ({ txt, className = '', icon, style }) => {
  if (icon) {
    const color = icon.color ? icon.color : 'white'
    return (
      <span className={'badge ' + className} style={style}>
        <i className={`icon ${icon.icon} ${color}`} />
        { txt }
      </span>
    )
  }
  return (<span className={'badge ' + className} style={style}>{txt}</span>)
}

export {
  StatusBadge,
  Badge,
  LogStatusBadge,
  KeyStatusBadge
}

export default Badge
