import React from 'react'
import Select from 'react-select'
import AppContext from 'AppContext'

const LocationsSelector = ({ onChange, filter }) => {
  const { locations } = React.useContext(AppContext)

  if (!locations) return null

  const change = (ev) => {
    if (!ev || ev.length < 1)
      return { triggerLocations: null }

    return { triggerLocations: ev.map(e => e.value) }
  }

  const value = filter.triggerLocations
    ? locations
      .filter((l) => filter.triggerLocations.includes(l.id))
      .map(l => ({ value: l.id, label: l.name }))
    : []

  return (
    <Select
      isMulti
      options={locations.map(l => ({ value: l.id, label: l.name }))}
      placeholder={'By location(s)'}
      value={value}
      onChange={(ev) => onChange(change(ev))}
    />
  )
}

const TriggerLocationsSelector = ({ onTriggerLocationChange, filter  }) => {
  const options = [
    { label: 'Has trigger location(s)', value: true },
    { label: 'No trigger location(s)', value: false }
  ]

  const value = filter.triggerLocations
    ? []
    : options.find(o => o.value === filter.triggerLocations)

  return (
    <Select
      placeholder={'Yes/No'}
      isClearable
      options={options}
      onChange={(ev) =>
        onTriggerLocationChange({ hasTriggerLocations: ev ? ev.value : null }
        )}
      value={value}
    />
  )
}

const TriggerLocationFilters = ({ onTriggerLocationChange, filter, filters }) => {

  if (!filters.includes('locations')) return null

  return (
    <div>
      <h3>Filter by trigger locations</h3>
      <div style={{
        display: 'grid',
        gridTemplateColumns: '1fr 10px 1fr',
        gridColumnGap: '10px' }}>
        <LocationsSelector
          onChange={onTriggerLocationChange}
          filter={filter}
        />
        <p>or</p>
        <TriggerLocationsSelector
          onTriggerLocationChange={onTriggerLocationChange}
          filter={filter}
        />
      </div>
    </div>
  )
}

export default TriggerLocationFilters
