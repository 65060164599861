import React from 'react'
import { Link } from 'react-router-dom'
import viewFreePassUrl from './viewFreePassUrl'

const baseUrl = operatorId => `/operators/${operatorId}/free-passes`

const Separator = () => `${String.fromCharCode(160)}${String.fromCharCode(62)}${String.fromCharCode(160)}`

const Breadcrumbs = ({operatorId, freePass}) => {
  return (
    <>
      <span className='breadcrumbs'>
        <Link to={ baseUrl(operatorId) }>Free passes</Link>
        { freePass &&
          <>
            <Separator />
            <Link to={ viewFreePassUrl(freePass.id, operatorId) }>{freePass.first_name} {freePass.last_name}</Link>
          </>
        }
      </span>
    </>
  )
}

export default Breadcrumbs
