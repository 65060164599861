import React from 'react'
import Select from 'react-select'

const OperatorSelect = (props) => {
  // Ignore if we only have 1 (or none) operators
  if (props.operators.length <= 1) return null

  const options = props.operators.map(o => ({ value: o.id, label: o.name }))

  const handleChange = (event) => {
    props.onChange({ target: { name: 'operator', value: event.value } })
  }

  return (
    <Select
      options={options}
      placeholder={'Select operator'}
      onChange={handleChange}
    />
  )
}

export default OperatorSelect
