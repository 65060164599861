import React from 'react'
import { Hidden } from 'components/Forms'
import { useFormContext } from "react-hook-form"

const MonthlyPaymentScheduleEntry = ({month, index}) => {
  const { register, setValue } = useFormContext();

  let monthLabel = `Month ${month.begin_month_index}`
  if ( month.end_month_index !== month.begin_month_index ) {
    monthLabel = `${monthLabel} - ${month.end_month_index ? month.end_month_index : ''}`
  }

  const clearPercentage = () => {
    setValue(`monthly_payment_schedule[${index}][fee_as_percentage]`, null)
  }

  const clearAmount = () => {
    setValue(`monthly_payment_schedule[${index}][fee_as_amount]`, null)
  }

  return (
    <div style={{ display: 'grid', gridAutoFlow: 'column' }}>
      <Hidden name={`monthly_payment_schedule[${index}][begin_month_index]`} value={month.begin_month_index}/>
      <Hidden name={`monthly_payment_schedule[${index}][end_month_index]`} value={month.end_month_index}/>

      <span style={{ width: '95px' }}>{ monthLabel }</span>

      <span>
        <input
          type="number"
          name={`monthly_payment_schedule[${index}][fee_as_percentage]`}
          onChange={clearAmount}
          ref={(e) => {
              register(e)
            }
          }
          style = {{ display: 'inline-block' }}
        /> % of monthly fee
      </span>
      <span>
        <input
          type="number"
          step="0.01"
          min="0"
          pattern="[0-9]+([\.,][0-9]+)?"
          name={`monthly_payment_schedule[${index}][fee_as_amount]`}
          onChange={clearPercentage}
          ref={(e) => {
              register(e)
            }
          }
          style = {{ display: 'inline-block' }}
        /> EUR
      </span>
    </div>
  )
}

export default MonthlyPaymentScheduleEntry
