import React from 'react'
import LinkColumn from 'components/Table/components/LinkColumn'
import AppContext from 'AppContext'
import Icon from 'components/Icons'

const Cell = ({ obj, path }) => {
  const { operator } = React.useContext(AppContext)

  return (
    <LinkColumn url={`/operators/${operator.id}/${path}/${obj.id}`} obj={obj} />
  )
}

const Header = ({ setFilterable }) => {
  const [visible, setVisible] = React.useState(false)

  const toggle = () => {
    setFilterable(!visible)
    setVisible(!visible)
  }

  // FIXME: Use css instead of inline style?
  const style = {
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    lineHeight: '30px',
    fontSize: '25px',
    fontWeight: 'bold',
    cursor: 'pointer',
    color: '#0f003f'
  }

  return (
    <div onClick={toggle}>
      <span className={'filter'} style={style}>
        ...
      </span>
    </div>
  )
}

const filterAndLinkArrowColumn = (setFilterable, path) => ({
  Header: <Header setFilterable={setFilterable} />,
  maxWidth: 70,
  accessor: null,
  sortable: false,
  show: true,
  Cell: ({ original }) =>
    <Cell path={path} obj={original} />,
  Filter: () =>
      <span style={{ display: 'inline-block', fontSize: '1.5em', paddingTop: '5px', marginLeft: '-20px' }}>
        <Icon type={'search'} color={'black'} />
      </span>
})

export default filterAndLinkArrowColumn

export {
  Cell,
  Header
} // Exported for test purposes
