import React from 'react'
import { useState } from 'react'
import useReport from './components/helpers/useReport'

/* eslint-disable camelcase */
const TrialMembershipsByDaysUntilActivation = ({ location_id = '' }) => {
  const [reportArgs] = useState({ location_id })

  let { data, meta, totals } = useReport('trial-memberships-by-days-until-activation', reportArgs)

  if (!data) return null

  return (
    <div className='report-wrapper with-padding'>
      <header>
        <h2>Trials by days until activation</h2>
      </header>
      <table>
        <thead>
          <tr>
            { meta.map(column => <th key={ column.name } style={{ textTransform: 'uppercase' }}>{column.name}</th>) }
          </tr>
        </thead>
        <tbody>
          { data.map((location,i) => {
            return (
              <tr key={i}>
                <td>{location.location_name}</td>
                <td>{location.in_trial}</td>
                <td>{location.more_than_14_days_until_activation}</td>
                <td>{location._14}</td>
                <td>{location._13}</td>
                <td>{location._12}</td>
                <td>{location._11}</td>
                <td>{location._10}</td>
                <td>{location._9}</td>
                <td>{location._8}</td>
                <td>{location._7}</td>
                <td>{location._6}</td>
                <td>{location._5}</td>
                <td>{location._4}</td>
                <td>{location._3}</td>
                <td>{location._2}</td>
                <td>{location._1}</td>
              </tr>
            )
          })}
          <tr className='totals'>
            <td>{totals.location_name}</td>
            <td>{totals.in_trial}</td>
            <td>{totals.more_than_14_days_until_activation}</td>
            <td>{totals._14}</td>
            <td>{totals._13}</td>
            <td>{totals._12}</td>
            <td>{totals._11}</td>
            <td>{totals._10}</td>
            <td>{totals._9}</td>
            <td>{totals._8}</td>
            <td>{totals._7}</td>
            <td>{totals._6}</td>
            <td>{totals._5}</td>
            <td>{totals._4}</td>
            <td>{totals._3}</td>
            <td>{totals._2}</td>
            <td>{totals._1}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default TrialMembershipsByDaysUntilActivation
