import React from 'react'
import { Membership, PaymentMethod, Personalia, Visits } from './components'

const MemberDetails = ({ member, match }) => {
  return (
    <div className='box-wrapper'>
      <Personalia profile={member.customer} match={match} />
      <Membership member={member} match={match} />
      <PaymentMethod {...member} />
      <Visits member={member} />
    </div>
  )
}

export default MemberDetails
