const tagsMap = {
  fb: {
    klass: 'facebook',
    name: 'Facebook'
  },
  evolution: {
    klass: 'evolution',
    name: 'Evolution'
  },
  google: {
    klass: 'google',
    name: 'GoogleAd'
  },
  winback: {
    klass: 'winback',
    name: 'Winback'
  },
  sms: {
    klass: 'winback',
    name: 'Winback'
  },
  'cancelled-in-trial': {
    klass: 'winback',
    name: 'Winback'
  },
  'opening campaign': {
    klass: 'opening',
    name: 'Opening Campaign'
  }
}

// TODO: Get tags from backend, this is just a POC for UIs
const mockTags = (txt) => {
  const tagsArray = Object.keys(tagsMap).map(k => k.toUpperCase())

  return tagsArray.reduce((acc, cur) => {
    if (txt.toLowerCase().includes(cur.toLowerCase())) {
      let tag = tagsMap[cur.toLowerCase()]
      acc.push(tag)
    }
    return acc
  }, [])
}

export default mockTags
