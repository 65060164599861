import React from 'react'
import Badge from 'components/Badges'

const Tags = ({ tags }) => {
  if (!tags) return null

  return (
    tags.map(t =>
      <Badge
        key={t}
        className={'tag ' + t.klass.toLowerCase()}
        txt={t.name.toUpperCase()}
      />
    )
  )
}

export default Tags
