import React, { Component } from 'react'
import LocationAPI from 'services/api/locations'
import LocationView from './LocationView'

class Location extends Component {
  constructor (props) {
    super(props)

    this.state = { location: null }
  }

  componentWillMount () {
    // Use obj given in state if present
    if (this.props.location && this.props.location.state) {
      this.setState({ location: this.props.location.state.obj })
      return
    }

    // Otherwise fetch from api
    const { operatorID, locationID } = this.props.match.params
    LocationAPI.fetchLocation(operatorID, locationID, (location) => {
      this.setState({ location: location })
    })
  }

  render () {
    return (
      <LocationView location={this.state.location} match={this.props.match} />
    )
  }
}

export default Location
