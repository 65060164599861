import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import Icon from 'components/Icons'
import { Select } from 'components/Forms'

const LocationsWithRole = ({locationsWithRole, setLocationsWithRole, availableLocations, availableRoles}) => {
  // Add an id to the exitsing roles so they can be removed correctly
  locationsWithRole.forEach(lwr => lwr.id = uuidv4())
  let assignedLocationCount = locationsWithRole.filter(loc => loc.location_id !== '').length

  // Add empty location/role pair
  //
  // Use client side generated ID as reference in assign location/role and remove
  const addLocationWithRole = event => {
    event.preventDefault()
    setLocationsWithRole([
      ...locationsWithRole,
      { location_id: "", role: "" }
    ])
  }

  const removeLocationWithRole = (event, assignmentId, locationId) => {
    event.preventDefault()
    if (assignedLocationCount === 1 && locationId !== '') { return }
    setLocationsWithRole(
      locationsWithRole.filter(assignment => assignment.id !== assignmentId)
    )
  }

  const assignRole = (role, assignmentId) => {
    setLocationsWithRole(
      locationsWithRole.map(assignment => {
        return assignment.id === assignmentId ? { ...assignment, role } : assignment
      })
    )
  }

  const assignLocation = (location_id, assignmentId) => {
    setLocationsWithRole(locationsWithRole.map(assignment => {
      return assignment.id === assignmentId ? { ...assignment, location_id } : assignment
    }))
  }

  return (
    <div className="pt-roles">
      <span>Location</span>
      <span>Role</span>
      <span></span>
      { locationsWithRole.map((assignment, index) => {
        return (
          <React.Fragment key={assignment.id}>
            <Select
              key={`locationSelect${index}`}
              name={`locations[${index}][location_id]`}
              onChange={ event => assignLocation(event.target.value, assignment.id) }
              value={assignment.location_id}
            >
              <option value="" key="_locationKey">Choose location</option>
              { availableLocations.map((location, locationIndex) => {
                return (
                  <option
                    value={location.id}
                    key={`locationKey-${index}-${locationIndex}`}
                  >
                    {location.name}
                  </option>
                )
              })}
            </Select>
            <Select
              key={`roleSelect${index}`}
              name={`locations[${index}][role]`}
              onChange={ event => assignRole(event.target.value, assignment.id) }
              value={assignment.role}
            >
              <option value="" key="_roleKey">Choose role</option>
              { availableRoles.map((roleName, roleIndex) => {
                return (
                  <option
                    value={roleName}
                    key={`roleKey${index}-${roleIndex}`}
                  >
                    {roleName}
                  </option>
                )
              })}
            </Select>
            <button
              key={`removeButton${index}`}
              onClick={ e => removeLocationWithRole(e, assignment.id, assignment.location_id)}
            >
              <Icon type="trash" />
            </button>
          </React.Fragment>
        )
      })}
      <button onClick={addLocationWithRole}>
        <Icon type="pluss" color="blue"/>
        Add location
      </button>
    </div>
  )
}

export default LocationsWithRole
