import React from 'react'
import Modal from 'components/Modal'
import { ActionButton } from 'components/Buttons'
import { post } from 'services/api/api'

const AnonymizeModal = ({ onClose, membership }) => {
  const [errorMessage, setErrorMessage] = React.useState(null)

  const submit = () => {
    return post(`memberships/${membership.id}/anonymize`, null)
      .then(onClose)
      .catch(err => setErrorMessage(err.message))
  }

  return (
    <Modal>
      <h1 className='modal-header'>Confirm Anonymization</h1>
      <div className='model-content' style={{margin: '3em 5em'}}>
        <p>Please confirm that you wish to anonymise the membership.
          This action is not reversible and identifying attributes of the
          customer will be anonymized.</p>
        <p class="error">{errorMessage}</p>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <ActionButton
            txt={'Cancel'}
            onClick={onClose}
          />
          <ActionButton
            txt={'Confirm'}
            onClick={submit}
          />
        </div>
      </div>
    </Modal>
  )
}

export default AnonymizeModal
