import React from 'react'
import { Link } from 'react-router-dom'

import Icon from 'components/Icons'

const DropDownItem = ({ txt, link }) => {

  return (
    <Link to={ link }>
      { txt }
      <Icon type='pluss' color='#4727AA' />
    </Link>
  )
}

export default DropDownItem
