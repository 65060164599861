import React from 'react'
import { useState } from 'react'
import useReport from './components/helpers/useReport'
import { Button } from '../../components/Buttons'
import ReportPeriod from './components/ReportPeriod'
import { format, addMonths } from 'date-fns'

/* eslint-disable camelcase */
const MissingMembershipFees = () => {
  const [currentMonth, setCurrentMonth] = useState(0)
  const [reportArgs, setReportArgs] = useState(ReportPeriod.monthAtIndex(currentMonth))

  const previousMonth = () => {
    setCurrentMonth(currentMonth - 1)
    setReportArgs(ReportPeriod.monthAtIndex(currentMonth - 1))
  }

  const nextMonth = () => {
    setCurrentMonth(currentMonth + 1)
    setReportArgs(ReportPeriod.monthAtIndex(currentMonth + 1))
  }

  let { data, meta } = useReport('missing-membership-fees', reportArgs)

  if (!data) return null

  return (
    <div className='report-wrapper with-padding'>
      <header>
        <Button txt='Previous' onClick={previousMonth}/>
        <h3>{ format(addMonths(new Date(), currentMonth), 'MMMM YYYY') }</h3>
        <Button txt='Next' onClick={nextMonth} />
      </header>
      <table>
        <thead>
          <tr>
            { meta.map(column => <th key={ column.name } style={{ textTransform: 'uppercase' }}>{column.name}</th>) }
          </tr>
        </thead>
        <tbody>
          { data.map((membership,i) => {
            return (
              <tr key={i}>
                <td>{membership.first_name}</td>
                <td>{membership.last_name}</td>
                <td>{membership.mobile}</td>
                <td>{membership.email}</td>
                <td>{membership.location}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default MissingMembershipFees
