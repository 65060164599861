import React from 'react'
import Icon, { TopBarLogo } from 'components/Icons'

const GoBack = ({ history }) => {
  if (!history) return null

  return (
    <button className='btn' style={{ minWidth: '124px' }} onClick={history.goBack}>
      <Icon
        type='arrow'
        direction='left'
        color='white'
        style={{
          fontSize: '20px',
          marginBottom: '-5px'
        }}
      /> Go back
    </button>
  )
}

const UnderDevelopmentPage = ({ permission, history }) =>
  <div className='no-access-wrapper'>
    <UnderDevelopmentComponent permission={permission} history={history} />
  </div>

const UnderDevelopmentComponent = ({ history }) =>
  <div className='no-access'>
    <div className='content-box'>
      <TopBarLogo style={{ height: '124px', width: '124px' }} />
      <h1
        style={{
          lineHeight: 'unset',
          paddingTop: '30px',
          fontSize: '36px',
          fontWeight: 'bold'
        }}>
        Under development
      </h1>
      <p>We are sorry, but this site is still under development</p>
      <GoBack history={history} />
    </div>
  </div>

export default UnderDevelopmentPage

export {
  UnderDevelopmentComponent,
  UnderDevelopmentPage
}
