import React from 'react'
import { useState } from 'react'
import useReport from './components/helpers/useReport'
import ReportPeriod from './components/ReportPeriod'

/* eslint-disable camelcase */
const TrialStatusBySignupDate = ({ location_id = '' }) => {
  const [reportArgs] = useState(ReportPeriod.monthAtIndex(0))

  let { data, meta, totals } = useReport('trial-status-by-signup-date', reportArgs)

  if (!data) return null

  return (
    <div className='report-wrapper with-padding'>
      <header>
        <h2>Trial status by signup date</h2>
      </header>
      <table>
        <thead>
          <tr>
            { meta.map(column => <th key={ column.name } style={{ textTransform: 'uppercase' }}>{column.name}</th>) }
          </tr>
        </thead>
        <tbody>
          { data.map((row,i) => {
            return (
              <tr key={i}>
                <td>{row.signup_date}</td>
                <td>{row.total_started}</td>
                <td>{row.in_trial}</td>
                <td>{row.activated}</td>
                <td>{row.cancelled_in_trial}</td>
                <td>{row.conversion_rate}</td>
              </tr>
            )
          })}
          <tr className='totals'>
            <td></td>
            <td>{totals.total_started}</td>
            <td>{totals.in_trial}</td>
            <td>{totals.activated}</td>
            <td>{totals.cancelled_in_trial}</td>
            <td>{totals.conversion_rate}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default TrialStatusBySignupDate
