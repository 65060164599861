import React from 'react'

import humanize from './humanize'
import ErrorMessage from './ErrorMessage'
import { useFormContext } from "react-hook-form"

const EmailInput = ({ label, name, required = false }) => {
  const { register, errors } = useFormContext()

  return (
    <label>{ label ? label : humanize(name) }
      <input
        type = "email"
        name = { name }
        className = { errors[name] ? 'error' : '' }
        ref={
          register({ required: required })
        }
      />
      <ErrorMessage name={name} errors={errors} />
    </label>
  )
}

export default EmailInput
