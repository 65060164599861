import React, { useState, useEffect, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import AppContext from 'AppContext'
import { toast } from 'react-toastify'
import AdminAPI from 'services/api/admins'
import { Breadcrumbs } from '../components'
import {
  Form,
  FormGroup,
  TextInput,
  DateInput,
  EmailInput,
  CheckboxInput,
  Switch,
  Submit,
  FormButtons,
  humanize,
  Mobile,
} from 'components/Forms'

const NewAdmin = () => {
  const { operator: { id: operatorId } } = useContext(AppContext)
  const [newAdminId, setNewAdminId] = useState(null)
  const [roles, setRoles] = useState([])
  const [validationErrors, setValidationErrors] = useState({})

  useEffect(() => {
    AdminAPI.fetchRoles(
      operatorId,
      successResult => setRoles(successResult.data),
      errorResult => alert(errorResult.data) // TODO display error message
    )
  }, [operatorId])

  const onSubmit = data => {
    if( !data.roles )
      data.roles = []

    AdminAPI.create(
      data,
      operatorId,
      successResult => {
        toast.success("Admin created")
        setNewAdminId(successResult.id)
      },
      errorResult => setValidationErrors(errorResult)
    )
  }

  if (newAdminId) return <Redirect to={viewAdminListUrl(operatorId)} />

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <Breadcrumbs operatorId={operatorId} />
        New admin
      </h1>
      <div className='form'>
        <Form onSubmit={onSubmit} defaultValues={{}} validationErrors={validationErrors}>
          <FormGroup header='Personalia'>
            <TextInput name='first_name'/>
            <TextInput name='last_name'/>
            <EmailInput name='email'/>
            <Mobile focus={false} operator={operatorId} />
          </FormGroup>
          <FormGroup header='Valid'>
            <DateInput name='begin_date'/>
            <DateInput name='end_date'/>
          </FormGroup>
          <FormGroup header='Roles'>
            { roles.map((value, index) => {
              return <CheckboxInput name='roles' value={value.id} key={value.id} label={humanize(value.name)} />
            })}
          </FormGroup>
          <FormGroup header='Key'>
            <Switch
              label='Generate PIN code for this admin'
              naem='generate_pin_code'
            />
          </FormGroup>
          <FormGroup header='Invite options'>
            <Switch
              label='Send invite e-mail upon creation'
              name='send_email'
            />
          </FormGroup>
          <FormButtons>
            <Submit label='Create admin' />
          </FormButtons>
        </Form>
      </div>
    </div>
  )
}

function viewAdminListUrl(operatorId) {
  return `/operators/${operatorId}/administrators/`
}

export default NewAdmin
