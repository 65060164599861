import React from 'react'
import { ActionButton } from 'components/Buttons'
import { DetailBox } from 'components/Boxes'

const BindingPeriod = ({ promotion }) => {
  const items = [
    { label: 'Months', value: 0, demo: true }
  ]

  return (
    <DetailBox header='Binding period' items={items}>
      <ActionButton
        txt='Edit binding period'
        icon='edit white'
        link='/under-development'
      />
    </DetailBox>
  )
}

export default BindingPeriod
