import React from 'react'
import { Select } from 'components/Forms'

const AdministrativeAreaSelect = ({ value, areas }) => {
  return (
    <label>Administrative area
      <Select className='administrative-area-select' name='administrative_area_id' value={value}>
        <option value="" key="-1">Choose administrative area</option>
        { areas.map(area => {
          return (
            <option value={area.id} key={area.id}>{area.name}</option>
          )
        })}
      </Select>
    </label>
  )
}

export default AdministrativeAreaSelect
