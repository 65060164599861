import React from 'react'

const Typography = () =>
  <div>
    <br />
    <hr />
    <h1> Typhography</h1>
    <h1>
      A mad boxer shot a quick, gloved jab to the jaw of his dizzy opponent.
      <br />
      1234567890!@#$%^&*()_+
    </h1>
    <h1 className='light'>
      A mad boxer shot a quick, gloved jab to the jaw of his dizzy opponent.
      <br />
      1234567890!@#$%^&*()_+
    </h1>
    <h2>
      A mad boxer shot a quick, gloved jab to the jaw of his dizzy opponent.
      <br />
      1234567890!@#$%^&*()_+
    </h2>
    <h2 className='light'>
      A mad boxer shot a quick, gloved jab to the jaw of his dizzy opponent.
      <br />
      1234567890!@#$%^&*()_+
    </h2>
    <p>
      <strong>
        A mad boxer shot a quick, gloved jab to the jaw of his dizzy opponent.
        <br />
        1234567890!@#$%^&*()_+
      </strong>
    </p>
    <p>
      A mad boxer shot a quick, gloved jab to the jaw of his dizzy opponent.
      <br />
      1234567890!@#$%^&*()_+
    </p>
    <br />
  </div>

export default Typography
