import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { updateMember } from 'services/api/members'

export default class EditPersonalia extends Component {
  constructor (props) {
    super(props)

    const { customer } = this.props.member
    this.state = {
      first_name: customer.first_name,
      last_name: customer.last_name,
      email: customer.email,
      street: customer.address.street,
      postal_code: customer.address.postal_code,
      postal_location: customer.address.postal_location,
      prefix: customer.mobile.prefix,
      number: customer.mobile.number,
      birthdate: customer.birthdate
    }

    this._handleSubmit = this._handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.profileHash = this.profileHash.bind(this)
  }

  profileHash () {
    // NOTE: duplicating email in body with view to removing credentials
    return (
      {
        operator_id: this.props.member.operator_id,
        credentials: {
          username: this.state.email
        },
        profile: {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          email: this.state.email,
          address: {
            street: this.state.street,
            postal_code: this.state.postal_code,
            postal_location: this.state.postal_location
          },
          mobile: {
            prefix: this.state.prefix,
            number: this.state.number,
          },
          birthdate: this.state.birthdate
        }
      }
    )
  }

  handleInputChange (event) {
    const { value, name } = event.target
    this.setState({ [name]: value })
  }

  _handleSubmit (event) {
    event.preventDefault()
    const profileHash = this.profileHash()
    // TODO: Fixme. This is just for testing purpose
    this.setState({ hash: profileHash })
    const { customer } = this.props.member
    const member = this.props.member

    updateMember(profileHash, member.operator_id, member.id, (res) => {
      toast.success('Changes saved')
      this.props.memberFromServer()
      this.props.history.push(`/operators/${member.operator_id}/members/${member.id}`)
    }, (err) => {
      console.log(err)
      toast.error('Something went wrong')
    })
  }

  _labelInput (id, label) {
    return (
      [
        <label key='label' htmlFor={id}>
          { label }
        </label>,
        <input
          key='input'
          id={id}
          type='text'
          name={id}
          defaultValue={this.state[id]}
          onChange={this.handleInputChange}
        />
      ]
    )
  }

  render () {
    return (
      <div>
        <form className='personalia-form' onSubmit={this._handleSubmit}>
          <div className='content-box' style={{ padding: '20px' }}>
            <h2 style={{ fontSize: '16px' }}>Personalia</h2>
            { this._labelInput('first_name', 'First name') }
            { this._labelInput('last_name', 'Last name') }
            { this._labelInput('email', 'Email') }
            { this._labelInput('prefix', 'Mobile prefix') }
            { this._labelInput('number', 'Mobile number') }
            { this._labelInput('street', 'Street') }
            { this._labelInput('postal_code', 'Postal code') }
            { this._labelInput('postal_location', 'Postal location') }
            { this._labelInput('birthdate', 'Birth date') }
          </div>
          <div style={{ padding: '0 20%' }}>
            <button style={{marginRight: '20px'}} type='submit' className='btn'>
              Save changes
            </button>
            <button className='btn' type='button' onClick={this.props.history.goBack}>
              Cancel x
            </button>
          </div>
        </form>
      </div>
    )
  }
}
