import React, { Component } from 'react'
import { reactStateToParams } from 'components/Helpers'
import { exportToExcel } from 'components/Table/helpers'
import PromotionsAPI from 'services/api/promotions'
import mockTags from './components/MockTags'
import AppContext from 'AppContext'
import defaultColumns from './components/columns'
import Filters from './components/filters'

const defaultSort = [
  { id: 'sales_period_begins_at', desc: true }
]

const WithPromotionFilter = ({component: WrappedComponent, ...props}) => {
  const [columns, setColumns] = React.useState(
    defaultColumns(props).filter(c => props.columns.includes(c.accessor))
  )

  return (
    <div>
      <div className='content-box no-background hidden small_devices'>
        <Filters
          onFilterChanges={props.onFilterChanges}
          columns={columns}
          setColumns={setColumns}
          filter={props.filter}
          enabledFilters={props.enabledFilters}
          exportToExcel={props.exportToExcel}
        />
      </div>
      <WrappedComponent
        columns={columns}
        promotions={props.promotions}
        onFetchData={props.onFetchData}
        loading={props.loading}
        pages={props.pages}
        page={props.page}
        onPageChange={props.onPageChange}
        match={props.match}
        operator={props.operator}
        timezone={props.operator.timezone}
        locations={props.locations}
        defaultSorted={props.defaultSorted}
      />
    </div>
  )
}

const withPromotions = (WrappedComponent, filter, columns, filters, sort = defaultSort) => {
  return class extends Component {
    static contextType = AppContext
    constructor (props) {
      super(props)
      this.state = {
        pages: -1,
        loading: false,
        tableState: {
          // Creating a new object to prevent mutating the original object
          filter: Object.assign({}, filter, {}),
        }
      }

      this._searchString = ''
      this._setSearchInputRef = el => {
        this._searchString = el
      }

      this.fetchPromotions = this.fetchPromotions.bind(this)
      this.search = this.search.bind(this)
      this.onPageChange = this.onPageChange.bind(this)
      this.onStatusChange = this.onStatusChange.bind(this)
      this.onTriggerLocationChange = this.onTriggerLocationChange.bind(this)
      this.onOtherFilterChanges = this.onOtherFilterChanges.bind(this)
      this.exportToExcel = this.exportToExcel.bind(this)
    }

    exportToExcel (columns) {
      const params = reactStateToParams(
        Object.assign(
          {},
          this.state.tableState,
          // We must set the pageSize to be the as big as the
          // expected result to get all of the records and not paginated
          { pageSize: this.state.records }
        )
      )

      exportToExcel(
        columns,
        params,
        this.state.selected_operator,
        PromotionsAPI.search,
        this.context,
        'promotions_export.xlsx'
      )
    }

    fetchPromotions (state) {
      state.filter = this.state.tableState.filter

      this.setState(
        {
          loading: true,
          // Hack to be able to change the table state
          tableState: {
            page: state.page,
            pageSize: state.pageSize,
            filter: state.filter,
            sort: sort
          }
        }
      )

      let args = reactStateToParams(state)

      // FIXME: Use same sorting as customer to keep consistency
      if (state.sorted && state.sorted.length) {
        args.sort = state.sorted.map(s =>
          ({ field: s.id, order: s.desc ? 'desc' : 'asc' })
        )
      } else {
        args.sort = sort.map(s =>
          ({ field: s.id, order: s.desc ? 'desc' : 'asc' }))
      }

      const operatorID = this.props.match.params.operatorID

      PromotionsAPI.search(operatorID, args, (res) => {
        const promotions = res.data.reduce((acc, cur) => {
          cur.tags = mockTags(cur.name)
          acc.push(cur)
          return acc
        }, [])

        this.setState({
          promotions: promotions,
          loading: false,
          records: res.records,
          pages: Math.ceil(res.records / state.pageSize)
        })
      })
    }

    search (ev) {
      ev.preventDefault()

      // TODO: Refactor?
      let state = Object.assign({}, this.state.tableState)
      state.filter.search = this._searchString.value

      // We reset the page to first page on search
      this.setState({ page: 0 })

      this.fetchPromotions(
        state
      )
    }

    onStatusChange (status) {
      let state = Object.assign({}, this.state.tableState)

      if (status === 'all') {
        delete state.filter.status
      } else {
        state.filter.status = status
      }

      // We reset the page to first page when we change the filter
      this.setState({ page: 0 })

      this.fetchPromotions(
        state
      )
    }

    onTriggerLocationChange ({ triggerLocations, hasTriggerLocations }) {
      let state = Object.assign({}, this.state.tableState)

      if (triggerLocations) {
        state.filter.triggerLocations = triggerLocations
      } else {
        delete state.filter['triggerLocations']
      }

      if (typeof hasTriggerLocations === 'boolean') {
        state.filter.hasTriggerLocations = hasTriggerLocations
      } else {
        delete state.filter['hasTriggerLocations']
      }

      // We reset the page to first page when we change the filter
      this.setState({ page: 0 })

      this.fetchPromotions(
        state
      )
    }

    onOtherFilterChanges (filter) {
      let state = Object.assign({}, this.state.tableState)

      const currentAdmin = this.context.user.user

      if (!filter) {
        delete state.filter.other
        delete state.filter.admin
      }

      if (['created_by', 'updated_by', 'administrator_id'].includes(filter)) {
        delete state.filter.admin
        state.filter.other = { field: filter, value: currentAdmin }
      }

      if (filter === 'admin') {
        delete state.filter.other
        state.filter.admin = currentAdmin
      }

      // We reset the page to first page when we change the filter
      this.setState({ page: 0 })

      this.fetchPromotions(
        state
      )
    }

    onPageChange (page) {
      this.setState({ page: page })
    }

    render () {
      if (!this.context.operator.timezone) return null

      return (
        <span>
          <form onSubmit={this.search}>
            <input
              id='promotionSearch'
              placeholder='Search'
              ref={this._setSearchInputRef}
            />
          </form>
          <WithPromotionFilter
            enabledFilters={filters}
            filter={this.state.tableState.filter}
            onFilterChanges={{
              onStatusChange: this.onStatusChange,
              onTriggerLocationChange: this.onTriggerLocationChange,
              onOtherFilterChanges: this.onOtherFilterChanges
            }}
            exportToExcel={this.exportToExcel}
            component={WrappedComponent}
            columns={columns}
            promotions={this.state.promotions}
            onFetchData={this.fetchPromotions}
            loading={this.state.loading}
            pages={this.state.pages}
            page={this.state.page}
            onPageChange={this.onPageChange}
            match={this.props.match}
            operator={this.context.operator}
            locations={this.context.locations}
            defaultSorted={sort}
          />
        </span>
      )
    }
  }
}

export default withPromotions
