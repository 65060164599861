import React from 'react'
import { Link } from 'react-router-dom'

import { ActionButton } from 'components/Buttons'
import { Filters } from './components'
import Table from 'components/Table'
import AppContext from 'AppContext'
import { dateToLocale } from 'components/Helpers'

const initColumns = (baseUrl) =>
  [
    {
      Header: 'Name',
      minWidth: 180,
      accessor: 'name',
      show: true,
      style: {
        fontWeight: 'bold',
        color: '#4727AA'
      },
      Cell: props => {
        const obj = props.original
        const path = `${baseUrl}/${obj.id}`
        return (
          <Link to={{ pathname: path, state: { obj } }}>
            {obj.name}
          </Link>
        )
      }
    },
    {
      Header: 'Status',
      minWidth: 120,
      accessor: 'status',
      show: true,
    },
    {
      Header: 'Geographical region',
      accessor: 'geographical_region',
      show: true,
      style: { whiteSpace: 'unset' },
      Export: ({ geographical_region }) => geographical_region.name,
      Cell: ({ value }) => value ? value.name : null,
      hide_for: {
        phone: true,
        tablet: true
      }
    },
    {
      Header: 'Opening date',
      minWidth: 110,
      accessor: 'opening_date',
      show: true,
      Export: ({ opening_date }) => dateToLocale(opening_date),
      Cell: ({ value }) => dateToLocale(value)
    },
    {
      Header: 'Closing date',
      minWidth: 110,
      accessor: 'closing_date',
      show: true,
      Export: ({ closing_date }) => dateToLocale(closing_date),
      Cell: ({ value }) => dateToLocale(value),
      hide_for: {
        phone: true,
      }
    },
  ]

const LocationListView = ({ data, search, onFilterChange, exportToExcel }) => {
  const { operator } = React.useContext(AppContext)
  const baseURL = `/operators/${operator.id}/locations`

  const [columns, setColumns] = React.useState(initColumns(baseURL))

  if (!operator) return null

  const searchInput = React.createRef()

  const onSubmit = (ev) => {
    ev.preventDefault()
    search(searchInput.current.value)
  }

  return (
    <div className='index'>
      <h1>
        Locations
        <span className='header-action-wrapper'>
          <ActionButton
            txt='Add location'
            link={`${baseURL}/new`}
            icon='pluss white'
          />
        </span>
      </h1>
      <form onSubmit={onSubmit}>
        <input
          id='promotionSearch'
          placeholder='Search'
          ref={searchInput}
        />
      </form>
      <Filters
        columns={columns}
        exportToExcel={() => exportToExcel(columns)}
        setColumns={setColumns}
        onFilterChange={onFilterChange}
      />
      <div className='content-box locations'>
        <Table
          columns={columns}
          data={data}
          config={{class_name: 'locations'}}
          url={baseURL}
        />
      </div>
    </div>
  )
}

export default LocationListView
