import React from 'react'
import { hasAccess } from './Helpers'
import NoAccessPage, { NoAccessComponent } from '../scenes/NoAccess'
import { Route } from 'react-router-dom'

// TODO: Use or remove
// const WithAuthFunctional = (injectedProps) => (Component, user, permission) => {
//   if (!hasAccess(user, permission)) {
//     return (<NoAccess permission={permission} />)
//   } else {
//     return (<Component {...injectedProps} />)
//   }
// }

// TODO: Use or remove
// const WithAuthComponent = (injectedProps) => (Component, user, permission) => {
//   console.log('WithAuth called')
//   return class extends React.Component {
//     render () {
//       if (!hasAccess(user, permission)) {
//         return (<NoAccess permission={permission} />)
//       }
//       return (
//         <Component {...injectedProps} />
//       )
//     }
//   }
// }

const NoAccess = ({ Component, subComponent, permission, ...rest }) =>
  subComponent
    ? <NoAccessComponent permission={permission} {...rest} />
    : <NoAccessPage permission={permission} {...rest} />

const PrivateRoute = ({ component: Component, user, permission, subComponent, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      hasAccess(user, permission)
        ? <Component user={user} {...props} {...rest} />
        : <NoAccess permission={permission} subComponent={subComponent} {...props} {...rest} />
    }
  />

export {
  PrivateRoute
}
