import React from 'react'
import CorporatePromotionListView from './CorporatePromotionListView'
import withPromotions from '../withPromotions'

const columns = [
  'code',
  'status',
  'name',
  'corporation.name',
  'sales_period',
  'pt_hours',
  'signup_fee',
  'monthly_payment_schedule',
  'created_by.name',
  'updated_by.name',
  'responsible_administrator.name',
  'trial_days'
]

const CorporatePromotionListViewWrapper = (props) =>
  <div className='content-box promotions'>
    <CorporatePromotionListView {...props} />
  </div>

const filters = [
  'status',
  'responsible_administrator'
]

export default withPromotions(
  CorporatePromotionListViewWrapper,
  { types: ['corporate'] },
  columns,
  filters
)
