import React from 'react'
import PropTypes from 'prop-types'
import Table  from 'components/Table'
import promotionUrl from './promotionUrl'

const PromotionTable = (props) => {
  if (!props.columns) return

  return (
    <Table
      data={props.promotions}
      pages={props.pages}
      onPageChange={props.onPageChange}
      page={props.page}
      defaultPageSize={8}
      serverSidePagination
      onFetchData={props.onFetchData}
      loading={props.loading}
      columns={props.columns}
      url={promotionUrl(props.match)}
      defaultSorted={props.defaultSorted}
    />
  )
}

PromotionTable.propTypes = {
  promotions: PropTypes.array,
  onFetchData: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  page: PropTypes.number,
  columns: PropTypes.array.isRequired
}
export default PromotionTable
