import React from 'react'
import Table from 'components/Table'
import { dateToLocale } from 'components/Helpers'
import { StatusBadge } from 'components/Badges'
import Icon from 'components/Icons'
import MembersAPI from 'services/api/members'

const Referrals = ({ operator, member }) => {
  const [referrals, setReferrals] = React.useState([])

  React.useEffect(() => {
    MembersAPI.referrals(member.id, (res) => {
      setReferrals(res.data)
    })
  }, [member])

  return (
    <div className='index'>
      <div className='content-box referrals'>
        <Table
          className='referrals-list'
          data={referrals}
          columns={[
            {
              Header: 'date',
              accessor: 'created_at',
              Cell: props =>
                dateToLocale(props.value)
            },
            {
              Header: 'First name',
              accessor: 'referred.first_name',
            },
            {
              Header: 'Last name',
              accessor: 'referred.last_name',
            },
            {
              Header: 'Begin date',
              accessor: 'referred.begin_date'
            },
            {
              Header: 'End date',
              accessor: 'referred.end_date'
            },
            {
              Header: 'Status',
              accessor: 'referred.status',
              Cell: props => {
                if (props.value) {
                  return <StatusBadge status={props.value} />
                } else {
                  return null
                }
              }
            },
            {
              Header: '',
              maxWidth: 70,
              accessor: null,
              sortable: false,
              filterable: false,
              Cell: ({ original }) => {
                if (!original.referred.membership_id) {
                  return null
                }

                const id = original.referred.membership_id
                const pathname = `/operators/${operator}/members/${id}`

                return (
                  // We can't use Link component, because it
                  // preserves the current member in state
                  <a href={pathname}>
                    <Icon
                      type='arrow'
                      direction='right'
                      style={{
                        fontSize: '20px',
                        marginBottom: '-5px'
                      }}
                    />
                  </a>
                )
              }
            }
          ]}
        />
      </div>
    </div>
  )
}

export default Referrals
