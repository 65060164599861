import React from 'react'

const BellIcon = props =>
  <svg height='1em' width='1em' viewBox='0 0 24 29' {...props}>
    <path
      d='M12 28.2c1.6 0 2.8-1.2 2.8-2.8H9.2c0 1.6 1.2 2.8 2.8 2.8zm9.2-8.4V12c0-4.4-3-7.9-7.1-8.9v-1C14.1 1 13.1 0 12 0S9.9 1 9.9 2.1v1c-4.1 1-7.1 4.5-7.1 8.9v7.8L0 22.6V24h24v-1.4l-2.8-2.8z'
    />
  </svg>

export default BellIcon
