import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../../Icons'

const LinkColumn = ({url, obj, id}) => {
  const pathname = id ? `${url}/${id}` : url

  return (
    <Link to={{ pathname: pathname,
      state: { obj } }} >
      <Icon
        type='arrow'
        direction='right'
        style={{
          fontSize: '20px',
          marginBottom: '-5px'
        }}
      />
    </Link>
  )
}


export default LinkColumn
