import React from 'react'

import { FilterButton } from 'components/Buttons'
import { ColumnSelector, ExportSelector } from 'components/Table'
import Regions from './Regions'

const Filters = ({ columns, exportToExcel, setColumns, onFilterChange }) => {
  const [active, setActive] = React.useState(false)

  const style = {
    display: 'grid',
    gridTemplateColumns: '2fr 2fr 2fr 1fr',
    gridColumnGap: '20px'
  }

  return (
    <div className='content-box no-background hidden small_devices'>
      <FilterButton setActive={setActive} active={active} />
      <div style={{ display: active ? 'block' : 'none' }}>
        <ColumnSelector columns={columns} setColumns={setColumns} />
        <div style={style}>
          <Regions onFilterChange={onFilterChange} />
          <ExportSelector exportToExcel={exportToExcel} />
        </div>
      </div>
    </div>
  )
}

export default Filters
