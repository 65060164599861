import React, { Component } from 'react'
import InvoiceAPI from 'services/api/invoices'
import reactStateToParams from 'components/Helpers/reactStateToParams'
import ScheduledInvoiceListView from './ScheduledInvoiceListView'

class ScheduledInvoiceList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      invoices: [],
      pages: -1
    }
  }

  componentDidMount () {
    this.setState({ loading: true })
    const { operatorID, memberID } = this.props.match.params

    const params = reactStateToParams(this.state)
    InvoiceAPI.fetchScheduledInvoices(operatorID, memberID, params, (res) => {
      this.setState({
        invoices: res.data,
        pages: Math.ceil(res.records / this.state.pageSize),
        loading: false
      })
    })
  }

  render () {
    const { invoices } = this.state
    return (
      <ScheduledInvoiceListView
        invoices={invoices}
      />)
  }
}

export default ScheduledInvoiceList
