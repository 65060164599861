import React from 'react'

const SettingIcon = props => {
  const style = props.color ? { fill: props.color } : null

  return (
    <svg width='1em' height='1em' viewBox='0 0 18 18' style={style} {...props}>
      <path
        d='M18.003 10.5v-3h-1.655a7.422 7.422 0 0 0-1.098-2.631l1.176-1.175-2.12-2.122-1.177 1.176a7.402 7.402 0 0 0-2.628-1.095V0h-3v1.653a7.44 7.44 0 0 0-2.63 1.096L3.697 1.575l-2.12 2.122L2.751 4.87A7.385 7.385 0 0 0 1.654 7.5H0v3h1.654a7.422 7.422 0 0 0 1.098 2.63l-1.176 1.178 2.123 2.12 1.174-1.176a7.37 7.37 0 0 0 2.63 1.095V18h3v-1.654a7.43 7.43 0 0 0 2.63-1.098l1.175 1.174 2.12-2.122-1.175-1.173a7.402 7.402 0 0 0 1.095-2.628h1.655zM9 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6z'
      />
    </svg>
  )
}

export default SettingIcon
