import moment from 'moment'

// Dates in format '2009-11-03' is not supported by Safari and Firefox
// but '2009/11/03' so we are replacing - with /
const fixDateForAllBrowsers = dateString => dateString.replace(/-/g, '/')

const dateToLocale = (date) => {
  if (!date) return 'N/A'
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }

  return new Date(date)
    .toLocaleDateString('en-UK', options)
}

const excelDate = (date) => {
  if (!date) return 'N/A'

  return new Date(date)
    .toLocaleDateString('en-GB')
}

const excelTime = (time, timeZone) => {
  if (!time) return 'N/A'
  if (!timeZone) return 'Missing timezone'

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/London',
    hour12: false
  }

  // NOTE: We are removing the comma to ensure
  //  that Excel might understand the format
  return new Date(time)
    .toLocaleString('en-GB', options)
    .replace(',','')
}

const timeToLocale = (time, timeZone, na = 'N/A') => {
  if (!time) return na
  if (!timeZone) return 'Missing timezone'
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    timeZone: timeZone
  }

  return new Date(time).toLocaleDateString('en-UK', options)
}

const timeToAge = (time) => {
  if (!time) return 'N/A'
  return moment().diff(time, 'years')
}

export default dateToLocale

export {
  dateToLocale,
  excelDate,
  excelTime,
  timeToLocale,
  fixDateForAllBrowsers,
  timeToAge
}
