import React, { useState } from 'react'
import Report from './components/Report'
import ReportPeriod from './components/ReportPeriod'
import CHART_COLORS from './components/ChartJSColors'
import formatYearMonth from './components/helpers/formatYearMonth'
import useReport from './components/helpers/useReport'

const TrialConversionHistory = ({ location_id = '' }) => {
  const [reportArgs, setReportArgs] = useState({...ReportPeriod.lastTwelveMonths(), location_id })

  const setReportPeriod = period => setReportArgs({...period(), location_id})

  const { data, meta } = useReport('dashboard-trial-conversion', reportArgs)

  if (!data) return null

  return <Report
    title='trial conversion'
    chartData={toChartJS(data)}
    excelData={{meta, data}}
    onReportPeriodChange={setReportPeriod}
    style={{ isFilled: false, withPadding: true }}
  />
}

const toChartJS = data => {
  return {
    type: 'line',
    data: {
      labels: data.map(period => period.yearmonth),
      xLabels: data.map(period => formatYearMonth(period.yearmonth)),
      datasets: [{
        label: 'converted',
        data: data.map(period => period.percent_converted),
        colors: [CHART_COLORS.primary, CHART_COLORS.transparent.primary],
        lineColor: 'rgba(125, 96, 255, 1)',
        fillGradientStartColor: 'rgba(125, 96, 255, 0.5)',
        fillGradientStopColor: 'rgba(125, 96, 255, 0)'
      }]
    }
  }
}

export default TrialConversionHistory
