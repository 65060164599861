import React from 'react'
import Select from 'react-select'
import { FilterButton } from 'components/Buttons'
import { ColumnSelector, ExportSelector } from 'components/Table'
import TriggerLocationFilters from './TriggerLocations'
import StatusSelector from './Status'

const MiscFilters = ({ onOtherFilterChanges, enabledFilters }) => {
  const options = [
    { label: 'Created by me', value: 'created_by'  },
    { label: 'Last updated by me', value: 'updated_by' },
    { label: 'Created or last updated by me', value: 'admin' }
  ]

  if (enabledFilters.includes('responsible_administrator')) {
    options.push({ label: 'I am responsible admin', value: 'administrator_id' })
  }

  const onChange = (ev) => {
    onOtherFilterChanges(ev ? ev.value : null)
  }

  return (
    <div>
      <h3>Misc filters</h3>
      <Select
        isClearable
        options={options}
        onChange={onChange}
      />
    </div>
  )
}

const Filters = ({ setColumns, columns, filter, enabledFilters, onFilterChanges, exportToExcel }) => {
  const [active, setActive] = React.useState(false)

  if (!enabledFilters) return null

  const {
    onStatusChange,
    onTriggerLocationChange,
    onOtherFilterChanges
  } = onFilterChanges

  const style = {
    display: 'grid',
    gridTemplateColumns: '2fr 2fr 2fr 1fr',
    gridColumnGap: '20px'
  }

  return (
    <>
      <FilterButton setActive={setActive} active={active} />
      <div style={{ display: active ? 'block' : 'none' }}>
        <ColumnSelector
          columns={columns}
          setColumns={setColumns}
        />
        <div style={style}>
          <StatusSelector
            filters={enabledFilters}
            onChange={onStatusChange}
            filter={filter}
          />
          <MiscFilters
            onOtherFilterChanges={onOtherFilterChanges}
            enabledFilters={enabledFilters}
          />
          <TriggerLocationFilters
            filters={enabledFilters}
            onTriggerLocationChange={onTriggerLocationChange}
            filter={filter}
            columns={columns}
          />
          <ExportSelector exportToExcel={() => exportToExcel(columns)} />
        </div>
      </div>
    </>
  )
}

export default Filters
