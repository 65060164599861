import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  Form,
  FormLine,
  FormGroup,
  TextInput,
  DateInput,
  Switch,
  Submit,
  FormButtons,
} from 'components/Forms'
import {
  RegionSelect,
  AdministrativeAreaSelect,
} from './components'
import {
  viewLocationsUrl
} from './utils'
import LocationsAPI from 'services/api/locations'

const EditLocation = ({match}) => {
  const { locationID: id, operatorID: operatorId } = match.params
  const [location, setLocation] = useState(null)
  const [validationErrors, setValidationErrors] = useState({})
  const [regions, setRegions] = useState([])
  const [administrativeAreas, setAdministrativeAreas] = useState([])
  const [isDone, setDone] = useState(false)

  useEffect(() => {
    LocationsAPI.fetchLocation(
      operatorId, // TODO: not used
      id,
      successResult => setLocation(successResult)
    )
  }, [id, operatorId])

  useEffect(() => {
    LocationsAPI.fetchRegions(successResult => setRegions(successResult.data))
  }, [operatorId])

  useEffect(() => {
    LocationsAPI.fetchAdministrativeAreas(successResult => setAdministrativeAreas(successResult.data))
  }, [operatorId])

  const onSubmit = data => {
    LocationsAPI.update(
      {...data, id},
      successResult => setDone(true),
      errorResult => setValidationErrors(errorResult),
    )
  }

  if (!location)
    return null

  if (isDone) {
    toast.success("Location updated");
    return <Redirect to={viewLocationsUrl(id, operatorId)} />
  }

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        Edit location
      </h1>
      <div className='form'>
        <Form onSubmit={onSubmit} defaultValues={location} validationErrors={validationErrors}>
          <FormGroup header='Location details'>
            <TextInput name='name' placeholder='Enter the name of the location'/>
            <TextInput name='street' placeholder='Street name and number'/>
            <FormLine>
              <TextInput name='postal_code' placeholder='Enter postal code'/>
              <TextInput name='postal_location' placeholder='Enter postal location'/>
            </FormLine>
            <RegionSelect value={location.region_id} regions={regions}/>
            <AdministrativeAreaSelect value={location.administrative_area_id} areas={administrativeAreas}/>
            <FormLine>
              <DateInput name='opening_date'/>
              <DateInput name='closing_date'/>
            </FormLine>
            <Switch
              label='Active. Display location in Sign-up and on My Page'
              name='publish'
              checked={location.publish}
            />
          </FormGroup>
          <FormGroup header='Capacity'>
            <TextInput name='max_memberships'/>
          </FormGroup>
          <FormButtons>
            <Submit label='Update location' />
          </FormButtons>
        </Form>
      </div>
    </div>
  )
}

export default EditLocation
