// Removes some special characters that might conflict with match()
const sanitize = (str) =>
  str.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '')

const matches = (original, search) =>
  !!original.toLowerCase().match(sanitize(search.toLowerCase()))

const defaultFilterMethod = (filter, row) => {
  if (!row[filter.id]) return false

  return matches(row[filter.id], filter.value)
}

export default defaultFilterMethod
