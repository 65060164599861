import React from 'react'

const CheckBox = ({ checked, className, disabled = false, children, onClick }) =>
  <label className='checkbox-container'>
    <input
      className={className}
      type='checkbox'
      defaultChecked={checked}
      disabled={disabled}
      onClick={onClick}
    />
    <span className='checkmark' />
    { children }
  </label>

export default CheckBox
