import { toast } from 'react-toastify'
import toExcel from './toExcel'

/**
 *
 * @param columns   The columns from react-table.
 *                  The Header is used as header in first column,
 *                  and the accessor is used to get the value in the rows
 * @param params    Params to the API call
 * @param operator  Operator ID
 * @param apiMethod The method to be called to fetch the data.
 *                  Assumed to be a method that takes 3 params:
 *                  operator, params and success callback
 * @param context   AppContext
 * @param fileName  Optional fileName for the exported file
 */
const exportToExcel = (columns, params, operator, apiMethod, context = {}, fileName) => {

  if (params.page_size > 10000) {
    return alert('The dataset to export is to big. Reduce it by filtering using filters. You can export max 10000 row')
  }

  const toastId = toast.info(
    'Export in progress',
    // Assuming that params.page_size is the same as the record count
    // using this to mimic how long time it might take to download
    // The toast is automatically closed when we get success from the API
    { autoClose: params.page_size + 1000, hideProgressBar: false }
  )

  // NOTE: We assume that all methods passed has the same format of parameters
  //   operator, params, callback
  apiMethod(operator, params, (res) => {
    toExcel(res.data, columns, context, fileName)

    // We check if the toast is still active just in case
    // it has autoClosed before we get to this point
    // If it is still active we update it
    // otherwise we create a new success toast
    if (toast.isActive(toastId)) {
      toast.update(
        toastId,
        {
          render: 'Export complete',
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
          hideProgressBar: true
        }
      )
    } else {
      toast.success('Export complete')
    }
  })
}

export default exportToExcel
