import React, { Component } from 'react'
import Modal from 'components/Modal'
import { ActionButton } from 'components/Buttons'
import PromotionsAPI from 'services/api/promotions'
import MemberAPI from 'services/api/members'
import { reactStateToParams } from 'components/Helpers'
import SuccessModal from './SuccessModal'
import ErrorModal from './ErrorModal'
import PromotionModalList from './PromotionModalList'
import ProcessingModal from './ProcessingModal'
import Icon from 'components/Icons'

const Submit = () =>
  <button
    id='ModalSubmit'
    style={{ float: 'right', marginLeft: '10px' }}
    type='submit'
    className='btn'
  >
    Search
  </button>

const Info = () =>
  <p>
    <Icon
      type='info'
      style={{
        fontSize: '18px',
        marginBottom: '-4px',
        marginRight: '5px'
      }}
    />
    You can move this customer to another active promotion.
    A user can only have one active promotion at the time.
  </p>

export default class PromotionModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      status: 'active',
      promotions: [],
      tableState: {
        filter: {
          status: 'active'
        }
      }
    }

    this._searchString = React.createRef()
    this._active = React.createRef()

    this.fetchPromotions = this.fetchPromotions.bind(this)
    this.toggleActive = this.toggleActive.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  fetchPromotions (state) {
    this.setState({ loading: true })

    // We must ensure that state.filter is initialized
    // with our default values
    if (!state.filter) {
      state.filter = this.state.tableState.filter
    }

    const args = reactStateToParams(state)

    // We keep tableState to be able to manually
    // call fetchPromotions as well as letting
    // the table call it with onFetchData
    this.setState({
      tableState: {
        page: state.page,
        pageSize: 9,
        filtered: state.filtered,
        filter: state.filter || this.state.tableState.filter
      }
    })

    const { operatorID } = this.props

    PromotionsAPI.search(operatorID, args, (res) => {
      this.setState({
        promotions: res.data,
        loading: false,
        pages: Math.ceil(res.records / state.pageSize),
        records: res.records
      })
    })
  }

  search (status = this.state.status) {
    const filter = {
      search: this._searchString && this._searchString.current ? this._searchString.current.value : '',
      status: status
    }

    this.fetchPromotions(
      Object.assign(
        this.state.tableState,
        { filter: filter }
      )
    )
  }

  handleSubmit (ev) {
    ev.preventDefault()
    this.search()
  }

  toggleActive () {
    if (this.state.status === 'active') {
      this.search('all')
      this.setState({ status: 'all' })
    } else {
      this.search('active')
      this.setState({ status: 'active' })
    }
  }

  moveToPromotion (promotion) {
    this.setState({ processLoading: true, selectedPromotion: promotion })
    console.log('move membership to promotion', promotion.id)

    const { operatorID, memberID } = this.props
    const args = { promotion_id: promotion.id }

    MemberAPI.updatePromotion(args, operatorID, memberID, (res) => {
      // We use a 1 sec timeout just to reduce the probability
      // that we might refresh the data for member from the API
      // before the change has actually populated into the admin-viewdb.
      setTimeout(() => {
        this.props.memberFromServer()
        this.setState({ success: true, processLoading: false })
      }, 1000)
    }, (err) => {
      this.setState({ error: err })
      console.log('something went wrong', err)
    })
  }

  form () {
    return (
      <form id='#PromotionSearch' onSubmit={this.handleSubmit}>
        <input
          id='searchInput'
          style={{ width: '99%' }}
          type='text'
          ref={this._searchString}
          placeholder='Search'
        />
        <div style={{ clear: 'both', paddingTop: '20px' }} />
        <Submit />
        <label className='checkbox-container'>
          <input
            id='toggleActive'
            type='checkbox'
            ref={this._active}
            defaultChecked={this.state.tableState.filter.status}
            onClick={this.toggleActive}
          />
          <span className='checkmark' />
          Display only active promotions
        </label>
      </form>
    )
  }

  errorModal () {
    return (
      <ErrorModal
        error={this.state.error}
        close={this.props.closeModal}
      />
    )
  }

  successModal () {
    return (
      <SuccessModal
        promotion={this.state.selectedPromotion}
        close={this.props.closeModal}
      />
    )
  }

  modalCloseButton () {
    return (
      <ActionButton
        className='cancel'
        onClick={this.props.closeModal}
        txt='Cancel'
      />
    )
  }

  render () {
    if (this.state.error) return this.errorModal()
    if (this.state.processLoading) return (<ProcessingModal />)
    if (this.state.success) return this.successModal()

    return (
      <Modal className='promotion'>
        <h1 className='modal-header'>Move to promotion</h1>
        <div className='modal-content'>
          <Info />
          { this.form() }
          <PromotionModalList
            promotions={this.state.promotions}
            onFetchData={this.fetchPromotions}
            moveToPromotion={(p) => this.moveToPromotion(p)}
            currentPromotion={this.props.promotion}
            loading={this.state.loading}
            pages={this.state.pages}
          />
          <p style={{ textAlign: 'right' }}>
            Found { this.state.records } promotions
          </p>
        </div>
        { this.modalCloseButton() }
      </Modal>
    )
  }
}
