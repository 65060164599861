import React from 'react'
import Modal from 'components/Modal'
import { ActionButton } from 'components/Buttons'

const SuccessModal = ({promotion, close}) =>
  <Modal className='promotion'>
    <h1 className='modal-header'>
      Success!
    </h1>
    <div className='modal-content' style={{ textAlign: 'center' }}>
      <p>Member moved to promotion</p>
      <p style={{ fontWeight: 'bold', marginBottom: '40px' }}>
        { promotion ? promotion.name : ''}
      </p>
    </div>
    <ActionButton
      className='cancel'
      onClick={close}
      txt='Close'
    />
  </Modal>

export default SuccessModal
