import React from 'react'
import AppContext from 'AppContext'

const AccessChip = ({match}) => {
  let env = "staging"
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = "production";
  }
  const token = window.localStorage.getItem('auth-token')
  const { operator: {id, currency, timezone} } = React.useContext(AppContext)

  // We may have to wait for AppContext to be available
  if(!id) {
    return (
      <div className='index sales-promotions'>
        <p>waiting for operator...</p>
      </div>
    )
  }

  // Must leave empty h1 there for element below not to be cropped...
  return (
    <div className='index sales-promotions'>
      { /* eslint-disable-next-line */ }
      <h1></h1>
      <cl-access-chip
          mountPath={`/operators/${id}/add-ons`}
          apihost={`https://memberships.${env}.credlock.net`}
          operatorid={id}
          token={token}
          timezone={timezone}
          currency={currency}
        >
      </cl-access-chip>
    </div>
  )
}

export default AccessChip

