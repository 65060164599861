import React from 'react'
import { DetailBox } from 'components/Boxes'
import { dateToLocale } from 'components/Helpers'

const Visits = ({ member }) => {
  if (!member || !member.visits) return null

  const { visits } = member

  const items = [
    { label: 'Number of visits', value: visits.total },
    { label: 'Average visits per week', value: visits.average + ' (previous 12 months)' },
    { label: 'Last visit', value: dateToLocale(visits.last_visit) }
  ]

  return (
    <DetailBox header='Visits' items={items} />
  )
}

export default Visits
