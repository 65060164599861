import React from 'react'

const IconMail = props =>
  <svg width='1em' height='1em' viewBox='0 0 24 22' {...props}>
    <path
      d='M21.4517116,11.4490564 C16.7508243,9.40906587 13.3290219,7.88361839 10.8226699,6.76544175 C5.93451069,4.58636092 4.61997132,4 3.70452101,4 C2.9190701,4 2.53270825,4.47636143 2.40543612,4.63181526 C1.92452925,5.22453979 1.8663477,6.06180864 2.25816407,6.7136238 L5.59996678,12.283598 L2.25907316,17.8517541 C1.8663477,18.5044783 1.92543834,19.3408381 2.4063452,19.9335626 C2.53361734,20.0908346 2.91997919,20.567196 3.70633919,20.567196 L3.70633919,20.567196 C4.62088041,20.567196 5.92996526,19.9826533 10.7963064,17.8126633 C13.3053857,16.6935776 16.7344607,15.1635847 21.4526207,13.1172305 C21.7844373,12.9717767 21.9989818,12.6445054 21.9989818,12.2826889 C21.9989818,11.9208724 21.7844373,11.5926921 21.4517116,11.4490564 Z M3.89270196,5.84180966 C4.61997132,5.99089988 6.55541691,6.85453224 10.0817642,8.42725224 C11.8181198,9.20179411 14.0017461,10.1745169 16.7335516,11.3736022 L7.17450495,11.3736022 L7.17450495,11.3736022 L3.89270196,5.84180966 Z M10.0554007,16.1517619 C6.47178093,17.7508454 4.53815352,18.6126596 3.8490658,18.7335682 L7.17450495,13.1908666 L16.7344607,13.1908666 C13.9881098,14.3981337 11.7953927,15.3754019 10.0554007,16.1517619 Z'
      fill={props.color}
    />
  </svg>

export default IconMail
