import React from 'react'
import { Link } from 'react-router-dom'
import { DetailBox } from 'components/Boxes'
import { ActionButton } from 'components/Buttons'
import { dateToLocale, timeToAge } from 'components/Helpers'

const Personalia = ({ profile, match }) => {
  const postAndLocation = profile.address ? profile.address.postal_code + ' ' + profile.address.postal_location : 'N/A'

  const postalStreet = profile.address ? profile.address.street : 'N/A'

  const email = <Link to={`${match.url}/no-access`}>{profile.email}</Link>

  const items = [
    { label: 'Name', value: profile, type: 'name' },
    { label: 'Mobile', value: profile.mobile, type: 'mobile' },
    { label: 'E-mail', value: email },
    { label: 'Street', value: postalStreet },
    { label: 'Postal code', value: postAndLocation },
    { label: 'Gender', value: profile.gender },
    { label: 'Birthdate', value: `${dateToLocale(profile.birthdate)} (${timeToAge(profile.birthdate)})` }
  ]

  return (
    <DetailBox header='Personalia' items={items}>
      <ActionButton
        txt='Edit personalia'
        icon='edit white'
        link={`${match.url}/edit`}
      />
    </DetailBox>
  )
}

export default Personalia
