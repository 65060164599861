const getNestedProperty = (obj, path) =>
  path.split('.').reduce((acc, cur) => acc ? acc[cur] : acc, obj)

const value = (col, row, context) =>
  col.Export ? col.Export(row, context) : getNestedProperty(row, col.accessor)

const buildRow = (columns, row, context) => {
  const newRow = {}
  columns.filter(c => c.accessor).forEach(col => {
    newRow[col.accessor] = value(col, row, context)
  })

  return newRow
}

export default buildRow
