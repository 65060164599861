import React from 'react'

const IconBars = props =>
  <svg width='1em' height='1em' viewBox='0 0 22 13' {...props}>
    <path
      d='M10.77 12H.23c-.127 0-.23.224-.23.5 0 .275.103.5.23.5h10.54c.127 0 .23-.225.23-.5 0-.276-.103-.5-.23-.5m5.876-6c.195 0 .354.224.354.5s-.159.5-.354.5H.354C.16 7 0 6.776 0 6.5S.159 6 .354 6h16.292zM.458 1C.205 1 0 .776 0 .5S.205 0 .458 0h21.084c.253 0 .458.224.458.5s-.205.5-.458.5H.458z'
      fill={props.color}
    />
  </svg>

export default IconBars
