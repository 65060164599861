const Concept = ({ systemConcept, humanConcept }) => {
  let result

  if (humanConcept) {
    result = humanConcept
  } else {
    // TODO: Missing translation of system concept to human readable strings
    switch (systemConcept) {
      case 'base_service_fee':
        result = 'Monthly fee'
        break
      case 'base_service_rest_of_month_fee':
      case 'cancel_freeze_fee':
        result = 'Monthly fee (partial)'
        break
      case 'signup_fee':
        result = 'Signup fee'
        break
      case 'billing_adjustment':
        result = 'Billing adjustment'
        break
      case 'freeze_fee':
        result = 'Freeze fee'
        break
      case 'referral_credit':
        result = 'Referral credit'
        break
      default:
        result = 'Unknown'
    }
  }

  return result
}

export default Concept
