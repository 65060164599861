import React from 'react'
import { Link } from 'react-router-dom'
import viewPTUrl from './viewPTUrl'

const baseUrl = operatorId => `/operators/${operatorId}/pts`

const Separator = () => `${String.fromCharCode(160)}${String.fromCharCode(62)}${String.fromCharCode(160)}`

const Breadcrumbs = ({operatorId, pt}) => {
  return (
    <>
      <span className='breadcrumbs'>
        <Link to={ baseUrl(operatorId) }>PTs</Link>
        { pt &&
          <>
            <Separator />
            <Link to={ viewPTUrl(pt.id, operatorId) }>{pt.first_name} {pt.last_name}</Link>
          </>
        }
      </span>
    </>
  )
}

export default Breadcrumbs
