import React from 'react'
import { Link } from 'react-router-dom'
import { Error, Input, OperatorSelector } from './index'

const Success = ({ success, resetState }) => {
  return (
    <div className={'info'}>
      <h1>Reset instructions sent</h1>
      <p>
        You will soon receive an email with <br />
        instructions on how to reset your password
      </p>
      <Link style={{ float: 'right' }} to={'/'} onClick={resetState}>
        Back to login
      </Link>
    </div>
  )
}

const ResetPassword = ({ error, success, onChange, onSubmit, operatorsToSelect, resetState }) => {
  if (success) return <Success success={success} resetState={resetState} />

  return (
    <form onSubmit={onSubmit}>
      <h1>Reset password</h1>
      <p>
        Type in your email to receive information <br />
        about how to reset your password
      </p>
      <Input name={'username'} onChange={onChange} />

      <OperatorSelector
        operators={operatorsToSelect}
        onChange={onChange}
      />

      <Error error={error} />

      <Link style={{ float: 'right' }} to={'/'} onClick={resetState}>
        Back to login
      </Link>

      <button
        type='submit'
        className='btn'
      >
        Submit
      </button>
    </form>
  )
}

export default ResetPassword
