import React, { useState, useEffect } from 'react'
import PT_API from 'services/api/pts'
import { viewPTUrl, Breadcrumbs } from './components'
import { toast } from 'react-toastify'
import {
  Form,
  FormLine,
  TextInput,
  EmailInput,
  Submit,
  DateInput,
  FormButtons,
  Mobile,
} from 'components/Forms'

const EditPersonalia = ({ match, history }) => {
  const { id, operatorID: operatorId } = match.params
  const [pt, setPT] = useState(null)
  const [validationErrors, setValidationErrors] = useState({})

  useEffect(() => {
    PT_API.fetchPT(operatorId, id, setPT)
  }, [id, operatorId])

  if (!pt) return null

  const onSubmit = (data) => {
    PT_API.update(
      { ...data, id },
      id,
      (successfulResult) => {
        console.log('pt updated')
        toast.success('Changes saved')
        history.push(viewPTUrl(id, operatorId))
      },
      (errorResult) => {
        console.error('failed to update', errorResult)
        toast.error(errorResult)
        setValidationErrors(errorResult)
      }
    )
  }

  return (
    <div className="index">
      <h1 className="with-breadcrumbs">
        <Breadcrumbs operatorId={operatorId} pt={pt} />
        Edit PT personalia
      </h1>
      <div className="content-box form">
        <Form
          onSubmit={onSubmit}
          defaultValues={pt}
          validationErrors={validationErrors}
        >
          <FormLine>
            <TextInput name="first_name" />
            <TextInput name="last_name" />
          </FormLine>
          <Mobile required={true} />
          <EmailInput name="email" />
          <TextInput name="address_street" label="Street" />
          <FormLine>
            <TextInput name="address_postal_code" label="Postal code" />
            <TextInput name="address_postal_location" label="Postal location" />
          </FormLine>
          <DateInput name="birthdate" />
          <FormButtons>
            <Submit label="Update" />
          </FormButtons>
        </Form>
      </div>
    </div>
  )
}

export default EditPersonalia
