import React from 'react'
import { ActionButton } from 'components/Buttons'
import { DetailBox } from 'components/Boxes'
import { referrerDiscountText } from '../../components/referrerRewards'

const ReferralRewards = ({ promotion }) => {
  if (promotion.promotion_type !== 'referral') return null
  const items = [
    { label: 'Free PT hours for referrer', value: promotion.referrer_pt_hours },
    { label: 'Discounted months', value: referrerDiscountText(promotion) }
  ]

  return (
    <DetailBox header='Referrer rewards' items={items}>
      <ActionButton
        txt='Edit rewards'
        icon='edit white'
        link={`/operators/${promotion.operator_id}/promotions/${promotion.id}/referrer-rewards/edit`}
      />
    </DetailBox>
  )
}

export default ReferralRewards
