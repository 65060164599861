import React, { Component } from 'react'
import AdminAPI from 'services/api/admins'
import ErrorPage from '../../Error'
import AdminListView from './AdminListView'

class Admins extends Component {
  constructor (props) {
    super(props)
    this.state = {
      admins: [],
      roles: null,
      loading: true
    }
  }

  componentDidMount () {
    this._fetchRoles(this.props.selected_operator)
    this._fetchAdmins(this.props.selected_operator)
  }

  render () {
    if (this.state.error) {
      return (
        <ErrorPage
          history={this.props.history}
          error={this.state.error}
        />
      )
    }

    // FIXME: Tmp hack because we don't want to build the react table
    //  columns in AdminListView before we actually have the roles...
    if (!this.state.roles) return null

    return (
      <AdminListView
        admins={this.state.admins}
        roles={this.state.roles}
        operator={this.props.selected_operator}
        loading={this.state.loading}
      />
    )
  }

  _fetchAdmins = (operatorID) => {
    AdminAPI.fetchAdmins(operatorID, (res) => {
      // console.log('admins', res.data)
      this.setState({admins: res.data, loading: false})
    }, (err) => {
      this.setState({error: err.reason})
      console.log(err)
    })
  }

  _fetchRoles = (operatorId) => {
    AdminAPI.fetchRoles(operatorId, (res) => {
      this.setState({ roles: res.data })
    }, (err) => {
      this.setState({ error: err.reason })
    })
  }
}

export default Admins
