import React from 'react'
import Select from 'react-select'
import { capitalizeFirstLetter } from 'components/Helpers'

const StatusSelector = ({ onChange, filter, filters }) => {
  if (!filters.includes('status')) return null

  const allStatuses = [
    'all',
    'active',
    'expired',
    'scheduled'
  ]

  const option = (status) => ({
    value: status,
    label: capitalizeFirstLetter(status.split('_').join(' '))
  })

  const options = allStatuses
    .map(option)

  return (
    <div>
      <h3>Filter by status</h3>
      <Select
        options={options}
        placeholder={filter && filter.status ? filter.status : 'All'}
        onChange={(ev) => onChange(ev.value)}
      />
    </div>
  )
}

export default StatusSelector
