import React, { useState } from 'react'
import CHART_COLORS from './components/ChartJSColors'
import Report from './components/Report'
import ReportPeriod from './components/ReportPeriod'
import formatYearMonth from './components/helpers/formatYearMonth'
import useReport from './components/helpers/useReport'

const ActiveVsFreezeIBHistory = ({ location_id = '' }) => {
  const [reportArgs, setReportArgs] = useState({ ...ReportPeriod.lastTwelveMonths(), location_id })

  const setReportPeriod = period => setReportArgs({...period(), location_id})

  const { data, meta } = useReport('dashboard-active-vs-freeze', reportArgs)

  if (!data) return null

  return (
    <Report
      title='active vs freeze'
      chartData={toChartJS(data)}
      tableData={toHTMLTable(data)}
      excelData={{meta, data}}
      onReportPeriodChange={setReportPeriod}
      style={{ isFilled: false, withPadding: true }}
    />
  )
}

const toChartJS = data => {
  return {
    type: 'bar',
    data: {
      labels: data.map(ib => formatYearMonth(ib.yearmonth)),
      datasets: [
        {
          label: 'active',
          barPercentage: 0.4,
          data: data.map(ib => ib.active),
          backgroundColor: CHART_COLORS.secondary
        },
        {
          label: 'freeze',
          barPercentage: 0.4,
          data: data.map(ib => ib.freeze),
          backgroundColor: CHART_COLORS.primary
        }
      ]
    }
  }
}

const toHTMLTable = data => {
  let active = data.map((ib, index) => <td key={index} className='column'>{ib.active}</td>)
  let freeze = data.map((ib, index) => <td key={index} className='column'>{ib.freeze}</td>)
  let totals = data.map((ib, index) => <td key={index} className='column'>{ib.total}</td>)

  active = [
    <td key='active' className='label'>
      <span className='circle'style={{ backgroundColor: CHART_COLORS.secondary }} />
      active
    </td>,
    ...active
  ]
  freeze = [
    <td key='freeze' className='label'>
      <span className='circle'style={{ backgroundColor: CHART_COLORS.primary }} />
      freeze
    </td>,
    ...freeze
  ]
  totals = [<td key='total' className='label'>total</td>, ...totals]

  return (
    <table>
      <tbody>
        <tr key='active'>{ active }</tr>
        <tr key='freeze'>{ freeze }</tr>
        <tr key='totals' className='totals'>{ totals }</tr>
      </tbody>
    </table>
  )
}

export default ActiveVsFreezeIBHistory
