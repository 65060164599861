import React from 'react'
import BreadCrumbs from '../components/BreadCrumbs'
import SubMenu from 'components/Navigations/SubMenu'
import MembershipTable from './components/MembershipTable'

const mockMenuItems = (operator) =>
  [
    {
      name: 'Members',
      path: `/operators/${operator}/members`
    },
    {
      name: 'Drop-ins',
      path: '/under-development'
    }
  ]

const MemberListView = (props) => {
  return (
    <div className='index members'>
      <h1 className={'with-breadcrumbs'}>
        <BreadCrumbs baseURL={props.url} />
        Customers
      </h1>
      <SubMenu items={mockMenuItems('')} active='Members' />
      <MembershipTable {...props} />
    </div>
  )
}

export default MemberListView
