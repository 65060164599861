import React from 'react'
import ReactDOM from 'react-dom'
// use HashRouter instead of BrowserRouter if we want # in route
import { BrowserRouter } from 'react-router-dom'
import './style/App.css'
import App from './App'
// import registerServiceWorker from './registerServiceWorker'

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)
// Service worker doesn't work for iOS/Safari
// And does some weird caching for chrome?
// Stuck on old page without force refresh without cache
// https://github.com/facebookincubator/create-react-app/issues/2398
// registerServiceWorker();

// Allows for partial re-render in development
// instead of fully refresh
// TODO: Figure out if this might affect prod env
if (module.hot) {
  module.hot.accept()
}
