import React from 'react'

const VisitIcon = props =>
  <svg width='24' height='24' viewBox='0 0 24 24' {...props}>
    <path
      d='M9.01,14H2v2h7.01v3L13,15l-3.99-4V14z M14.99,13v-3H22V8h-7.01V5L11,9L14.99,13z'
      fill={props.color}
    />
  </svg>

export default VisitIcon
