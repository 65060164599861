import React, { useState, useEffect, useContext } from 'react'
import AppContext from 'AppContext'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'
import {
  Form,
  FormLine,
  FormGroup,
  TextInput,
  DateInput,
  Switch,
  Submit,
  FormButtons,
} from 'components/Forms'
import {
  RegionSelect,
  AdministrativeAreaSelect,
} from './components'
import {
  viewLocationsUrl
} from './utils'
import LocationsAPI from 'services/api/locations'

const NewLocation = () => {
  const { operator: { id: operatorId } } = useContext(AppContext)
  const [newLocationId, setNewLocationId] = useState(null)
  const [validationErrors, setValidationErrors] = useState({})
  const [regions, setRegions] = useState([])
  const [administrativeAreas, setAdministrativeAreas] = useState([])

  useEffect(() => {
    LocationsAPI.fetchRegions(successResult => setRegions(successResult.data))
  }, [operatorId])

  useEffect(() => {
    LocationsAPI.fetchAdministrativeAreas(successResult => setAdministrativeAreas(successResult.data))
  }, [operatorId])

  const onSubmit = data => {
    LocationsAPI.create(
      data,
      successResult => setNewLocationId(successResult.id),
      errorResult => setValidationErrors(errorResult),
    )
  }

  if (newLocationId) {
    toast.success("Location created");
    return <Redirect to={viewLocationsUrl(operatorId)} />
  }

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        New location
      </h1>
      <div className='form'>
        <Form onSubmit={onSubmit} defaultValues={{}} validationErrors={validationErrors}>
          <FormGroup header='Location details'>
            <TextInput name='name' placeholder='Enter the name of the location' focus={true}/>
            <TextInput name='street' placeholder='Street name and number' size="large"/>
            <FormLine>
              <TextInput name='postal_code' placeholder='Enter postal code' size="small"/>
              <TextInput name='postal_location' placeholder='Enter postal location'/>
            </FormLine>
            <RegionSelect regions={regions}/>
            <AdministrativeAreaSelect areas={administrativeAreas}/>
            <FormLine>
              <DateInput name='opening_date'/>
              <DateInput name='closing_date'/>
            </FormLine>
            <Switch
              label='Active. Display location in Sign-up and on My Page'
              name='publish'
            />
          </FormGroup>
          <FormGroup header='Capacity'>
            <TextInput name='max_memberships'/>
          </FormGroup>
          <FormButtons>
            <Submit label='Add location' />
          </FormButtons>
        </Form>
      </div>
    </div>
  )
}

export default NewLocation
