const API = require('./api')

const baseURL = 'pts'

const search = (operatorId, state, success) => {
  API.fetch(baseURL, success, state)
}

const create = (args, operatorId, success, error) => {
  let url = process.env.REACT_APP_SERVER + '/' + baseURL
  API.post(url, success, args, error)
}

const update = (args, ptId, success, error) => {
  let url = process.env.REACT_APP_SERVER + '/' + baseURL + '/' + ptId
  API.patch(url, success, args, error)
}

const fetchPT = (operatorId, ptId, success) => {
  const url = baseURL + '/' + ptId
  API.fetchWithError(url, success)
}

const fetchRoles = (operatorId, success) => {
  success({ data: ['PT', 'CA'] }) // TODO Use real API
}

const auth = (ptId, success) => {
  const url = baseURL + '/auth'
  API.fetch(url, success, { pt_id: ptId })
}

module.exports = {
  search,
  create,
  fetchPT,
  update,
  fetchRoles,
  auth,
}
