import React from 'react'
import PromotionStatus from './PromotionStatus'
import { promotionReferrerRewardsToText } from '../../components/referrerRewards'
import Tags from './Tags'
import SalesPeriod, { salesPeriod } from './SalesPeriod'
import Amount, { toHumanReadable } from 'components/Helpers/Amount'
import PaymentPlan, { paymentPlanAsText } from './PaymentPlan'
import { dateToLocale, excelTime, timeToLocale } from 'components/Helpers'

const defaultColumns = ({ operator, locations }) => [
  {
    width: 70,
    Header: 'Code',
    accessor: 'code',
    show: true,
  },
  {
    width: 120,
    Header: 'Status',
    accessor: 'status',
    sortable: false,
    show: true,
    Cell: p =>
      <PromotionStatus promotion={p.original} />
  },
  {
    minWidth: 300,
    Header: 'Name',
    accessor: 'name',
    style: { whiteSpace: 'unset', minHeight: '100px' },
    show: true
  },
  {
    minWidth: 180,
    Header: 'Client',
    accessor: 'corporation.name',
    style: { whiteSpace: 'unset' },
    show: true,
  },
  {
    Header: 'Referrer rewards',
    accessor: 'referrer_rewards',
    sortable: false,
    show: true,
    Export: (row) => promotionReferrerRewardsToText(row),
    Cell: p =>
      promotionReferrerRewardsToText(p.original)
  },
  {
    width: 130,
    Header: 'Tags',
    accessor: 'tags',
    sortable: false,
    show: false,
    Cell: p =>
      <Tags tags={p.original.tags} />
  },
  {
    width: 150,
    Header: 'Begins at',
    accessor: 'sales_period_begins_at',
    show: true,
    exportFormat: { numFmt: 'dd/mm/yyyy hh:mm' },
    Export: (promotion, { operator }) =>
      excelTime(promotion.sales_period_begins_at, operator.timezone),
    Cell: p =>
      <span>{ dateToLocale(p.original.sales_period_begins_at, 'Europe/Oslo') }</span>
  },
  {
    Header: 'Ends at',
    accessor: 'sales_period_ends_at',
    show: true,
    exportFormat: { numFmt: 'dd/mm/yyyy hh:mm' },
    Export: (promotion, { operator }) =>
      excelTime(promotion.sales_period_ends_at, operator.timezone),
    Cell: p =>
      <span>{ dateToLocale(p.original.sales_period_ends_at) }</span>
  },
  {
    width: 170,
    Header: 'Begin date - End date',
    style: { whiteSpace: 'unset' },
    accessor: 'sales_period',
    sortable: false,
    show: true,
    Export: (promotion, { operator }) => {
      const { from, to } = salesPeriod(promotion, true, operator.timezone)

      return `${from} - ${to}`
    },
    Cell: p =>
      <SalesPeriod promotion={p.original} timezone={operator.timezone} />
  },
  {
    Header: 'PT hours',
    accessor: 'pt_hours',
    show: false
  },
  {
    width: 100,
    Header: 'Trial days',
    accessor: 'trial_days',
    show: false,
    sortable: true
  },
  {
    Header: 'Signup fee',
    accessor: 'signup_fee',
    show: false,
    Export: ({ signup_fee }) => toHumanReadable(signup_fee, 'NOK'),
    Cell: p => <Amount currency={operator.currency} amount={p.value} />
  },
  {
    width: 220,
    Header: 'Payment plan',
    accessor: 'monthly_payment_schedule',
    sortable: false,
    show: false,
    Export: ({ monthly_payment_schedule }, { operator }) =>
      paymentPlanAsText(monthly_payment_schedule, operator.currency)
    ,
    Cell: p =>
      <PaymentPlan schedule={p.value} currency={operator.currency} />
  },
  {
    width: 150,
    Header: 'Created at',
    accessor: 'created_at',
    show: true,
    exportFormat: { numFmt: 'dd/mm/yyyy hh:mm' },
    Export: (promotion, { operator }) =>
      excelTime(promotion.created_at, operator.timezone),
    Cell: p =>
      <span>{ timeToLocale(p.original.created_at, operator.timezone) }</span>
  },
  {
    Header: 'Created by',
    accessor: 'created_by.name',
    sortable: false,
    show: false,
  },
  {
    Header: 'Updated by',
    accessor: 'updated_by.name',
    sortable: false,
    show: false,
  },
  {
    minWidth: 140,
    Header: 'Responsible admin',
    accessor: 'responsible_administrator.name',
    sortable: false,
    show: false,
  },
  {
    width: 200,
    Header: 'Trigger locations',
    accessor: 'trigger_location_ids',
    sortable: false,
    show: false,
    Export: ({ trigger_location_ids }, { locations }) => {
      if (!trigger_location_ids || !trigger_location_ids.length) return null

      return trigger_location_ids
        .map(l => locations.find(loc => loc.id === l).name)
        .join('\n')
    }
    ,
    Cell: p => {
      if (!p.value.length) return null

      const locationNames = p.value.map(l =>
        locations.find(loc => loc.id === l)
      )

      return (
        <span>
          {
            locationNames.map(l =>
              <span key={l.id}>
                { l.name }
                <br />
              </span>
            )
          }
        </span>
      )
    }
  }
]

export default defaultColumns
