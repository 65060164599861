import React, { useState } from 'react'
import AppContext from 'AppContext'
import PaidPassAPI from 'services/api/paid-passes'
import { Redirect } from 'react-router-dom'

import {
  Form,
  FormLine,
  TextInput,
  EmailInput,
  Mobile,
  Submit
} from 'components/Forms'

import ErrorMessage from 'components/Forms/components/ErrorMessage'
import PaidPassBreadCrumbs from './components/PaidPassBreadCrumbs'

const url = (operatorId, id) => {
  return `/operators/${operatorId}/paid-passes/distributors/${id}`
}

const DistributorCreate = () => {
  const { operator: { id: operatorId } } = React.useContext(AppContext)
  const [id, setId] = useState(null)
  const [validationErrors, setValidationErrors] = useState({})

  const onSubmit = data => {
    PaidPassAPI.distributors.create(
      {...data, source: 'admin-ui' },
      operatorId,
      successfulResult => setId(successfulResult.id),
      errorResult => setValidationErrors(errorResult)
    )
  }

  if (id) return <Redirect to={url(operatorId, id)} />

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <PaidPassBreadCrumbs operatorId={operatorId} />
        New paid pass distributor
      </h1>
      <div className='content-box form'>
        <Form onSubmit={onSubmit} defaultValues={{}} validationErrors={validationErrors.errors}>
          <TextInput name="name" />
          <TextInput name="organization_number" />
          <TextInput name="street" />
          <FormLine>
            <TextInput name="postal_code" />
            <TextInput name="postal_location" />
          </FormLine>
          <TextInput name="contact" />
          <Mobile focus={false} operator={operatorId} />
          <EmailInput name='email'/>
          <TextInput name="signup_fee" />
          <TextInput name="monthly_fee" />
          <TextInput name="paid_passes_fee" />
          <Submit label='Add distributor' />
          <ErrorMessage name='message' errors={validationErrors} />
        </Form>
      </div>
    </div>
  )
}

export default DistributorCreate
