import React from 'react'
import AppContext from 'AppContext'
import Select from 'react-select'

const onLocationsChange = (ev, filtered) => {
  const location = ev.target.value

  if (location === 'all') {
    return filtered.filter(f => f.id !== 'locations')
  }

  const previous = filtered.find(f => f.id === 'locations')
  const locations = previous ? previous.value : []

  let newFilter

  if (locations.includes(location)) {
    newFilter = { id: 'locations', value: locations.filter(l => l !== location) }
  }
  else {
    newFilter = { id: 'locations', value: locations.concat(location) }
  }

  return filtered.filter(f => f.id !== 'locations').concat(newFilter)
}

const LocationsSelector = ({ onChange, filtered }) => {
  const { locations } = React.useContext(AppContext)

  if (!locations) return null

  const change = (ev) => {
    if (!ev || ev.length < 1)
      return filtered.filter(f => f.id !== 'locations')

    return filtered
      .filter(f => f.id !== 'locations')
      .concat({ id: 'locations', value: ev.map(e => e.value) })
  }

  const filteredLocations = filtered.find(f => f.id === 'locations')

  const value = filteredLocations ?
    filteredLocations.value.map(f => ({ value: f, label: locations.find(l => l.id === f).name })) :
    []

  return (
    <Select
      isMulti
      options={locations.map(l => ({ value: l.id, label: l.name }))}
      onChange={(ev) => onChange(change(ev))}
      value={value}
    />
  )
}

const LocationsFilter = ({ onChange, filtered }) => {
  const { locations } = React.useContext(AppContext)

  if (!locations) return null

  const filteredLocations = filtered.find(f => f.id === 'locations')

  return (
    <select
      style={{ width: '100%' }}
      name={'locations'}
      multiple
      onChange={(ev) => onChange(onLocationsChange(ev, filtered))}
      defaultValue={filteredLocations ? filteredLocations.value : ['all']}
    >
      <option value={'all'}>All</option>
      {
        locations.map(l =>
          <option key={l.name} value={l.id}>{ l.name }</option>
        )
      }
    </select>
  )
}

export default LocationsFilter

export {
  LocationsSelector,
  onLocationsChange // Only exported for testing
}
