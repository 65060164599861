import React from 'react'

const SignInLine = props => (
  <svg width={210} height={278} {...props}>
    <title>{`Line 4`}</title>
    <defs>
      <linearGradient x1='102.833%' y1='102.113%' x2='0%' y2='0%' id='sign-in-line-a'>
        <stop stopColor='#0F003F' offset='0%' />
        <stop stopColor='#D73C67' offset='100%' />
      </linearGradient>
    </defs>
    <path
      d='M135.5-.5l208 277'
      transform='translate(-135)'
      stroke='url(#sign-in-line-a)'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='square'
    />
  </svg>
)

export default SignInLine
