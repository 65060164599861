const API = require('./api')

const baseURL = 'free-passes'

const search = (operatorId, state, success) => {
  API.fetch(baseURL, success, state)
}

const create = (args, operatorId, success, error) => {
  let url = process.env.REACT_APP_SERVER + '/' + baseURL
  API.post(url, success, args, error)
}

const update = (args, freePassId, success, error) => {
  let url = process.env.REACT_APP_SERVER + '/' + baseURL + '/' + freePassId
  API.patch(url, success, args, error)
}

const fetchFreePass = (operatorId, freePassId, success, error) => {
  const url = baseURL + '/' + freePassId
  API.fetchWithError(url, success, error)
}

const locales = (operatorId, success, error) => {
  const url = baseURL + `/locales/${operatorId}`
  API.fetchWithError(url, success, error)
}

module.exports = {
  search,
  create,
  update,
  fetchFreePass,
  locales,
}
