import React from 'react'

const IconRefund = props =>
  <svg width='1em' height='1em' viewBox='0 0 24 22' {...props}>
    <path
      d='M12.4801973,4.96761374 L6.10306831,4.96761374 C3.52167318,4.96761374 1.41931821,7.13799001 1.41931821,9.82593998 L1.41931821,10.7541741 C1.41931821,13.4421241 3.52167318,15.6125003 6.10306831,15.6125003 C6.49500221,15.6125003 6.81272741,15.9302255 6.81272741,16.3221594 C6.81272741,16.7140933 6.49500221,17.0318185 6.10306831,17.0318185 C2.72751381,17.0318185 0,14.2160565 0,10.7541741 L0,9.82593998 C0,6.36405753 2.72751381,3.54829553 6.10306831,3.54829553 L12.4801973,3.54829553 L10.1433657,1.21146387 C9.86622656,0.934324752 9.86622656,0.484993459 10.1433657,0.20785434 C10.4205048,-0.0692847799 10.8698361,-0.0692847799 11.1469752,0.20785434 L14.6952707,3.75614987 C14.9724099,4.03328899 14.9724099,4.48262028 14.6952707,4.7597594 L11.1469752,8.30805493 C10.8698361,8.58519405 10.4205048,8.58519405 10.1433657,8.30805493 C9.86622656,8.03091581 9.86622656,7.58158451 10.1433657,7.3044454 L12.4801973,4.96761374 Z M9.51980266,17.0318185 L11.8566343,19.3686502 C12.1337734,19.6457893 12.1337734,20.0951206 11.8566343,20.3722597 C11.5794952,20.6493988 11.1301639,20.6493988 10.8530248,20.3722597 L7.30472926,16.8239642 C7.02759014,16.5468251 7.02759014,16.0974938 7.30472926,15.8203547 L10.8530248,12.2720591 C11.1301639,11.99492 11.5794952,11.99492 11.8566343,12.2720591 C12.1337734,12.5491983 12.1337734,12.9985295 11.8566343,13.2756687 L9.51980266,15.6125003 L15.8969317,15.6125003 C18.4783268,15.6125003 20.5806818,13.4421241 20.5806818,10.7541741 L20.5806818,9.82593998 C20.5806818,7.13799001 18.4783268,4.96761374 15.8969317,4.96761374 C15.5049978,4.96761374 15.1872726,4.64988854 15.1872726,4.25795463 C15.1872726,3.86602073 15.5049978,3.54829553 15.8969317,3.54829553 C19.2724862,3.54829553 22,6.36405753 22,9.82593998 L22,10.7541741 C22,14.2160565 19.2724862,17.0318185 15.8969317,17.0318185 L9.51980266,17.0318185 Z'
      fill={props.color}
    />
  </svg>

export default IconRefund
