import React from 'react'

import Arrow from './IconArrow'
import Bars from './IconBars'
import BellIcon from './Bell'
import CustomersIcon from './CustomersIcon'
import GradientLock from './GradientLock'
import HouseIcon from './House'
import Edit from './IconEdit'
import Info from './IconInfo'
import Error from './IconError'
import LocationIcon from './LocationIcon'
import LockIcon from './LockIcon'
import Mail from './IconMail'
import MaintenanceIcon from './MaintenanceIcon'
import MasterCard from './IconMasterCard'
import Pluss from './IconPluss'
import ProductIcon from './ProductIcon'
import PromotionIcon from './PromotionIcon'
import FreePassesIcon from './FreePassesIcon'
import PTsIcon from './PTsIcon'
import ReportsIcon from './ReportsIcon'
import Refund from './IconRefund'
import Report from './IconReport'
import Search from './IconSearch'
import SettingsIcon from './SettingsIcon'
import SignInLogo from './SignInLogo'
import SignInLine from './SignInLine'
import SignInLogoMobile from './SignInLogoMobile'
import TopBarLogo from './TopBarLogo'
import Trash from './IconTrash'
import Visa from './IconVisa'
import VisitIcon from './IconVisit'
import Filter from './IconFilter'

const icons = {
  arrow: Arrow,
  bars: Bars,
  bell: BellIcon,
  customers: CustomersIcon,
  edit: Edit,
  error: Error,
  house: HouseIcon,
  info: Info,
  location: LocationIcon,
  lock: LockIcon,
  maintenance: MaintenanceIcon,
  mailsend: Mail,
  mastercard: MasterCard,
  mc: MasterCard,
  new: Pluss,
  pluss: Pluss,
  product: ProductIcon,
  promotion: PromotionIcon,
  freePasses: FreePassesIcon,
  reports: ReportsIcon,
  pts: PTsIcon,
  refund: Refund,
  repeat: Refund,
  report: Report,
  search: Search,
  settings: SettingsIcon,
  trash: Trash,
  visa: Visa,
  visit: VisitIcon,
  filter: Filter
}

const Icon = props => {
  const Component = icons[props.type]

  // Just return null if no icon exists
  if (!Component) {
    return null
  }

  return <Component className={'icon ' + props.type} {...props} />
}

export {
  GradientLock,
  SignInLogo,
  SignInLine,
  SignInLogoMobile,
  TopBarLogo
}

export default Icon
