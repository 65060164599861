import React from 'react'
import {
  SignInLine,
  SignInLogo,
  SignInLogoMobile
} from 'components/Icons'

const Logo = () =>
  <>
    <div className='logo-desktop'>
      <SignInLine className='line' />
      <SignInLogo className='logo' />
    </div>
    <div className='logo-mobile-top-bar'>
      <SignInLogoMobile className='logo' />
    </div>
  </>

export default Logo
