import React from 'react'

const IconEdit = props =>
  <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
    <path
      d='M20.8211683,3.179 C20.0611683,2.418 19.0491683,2 17.9741683,2 C16.9001683,2 15.8881683,2.418 15.1281683,3.179 L3.71916832,14.587 C3.71916832,14.587 3.71816832,14.59 3.71616832,14.591 C3.63516832,14.673 3.49716832,14.889 3.45516832,15.052 L2.03016832,20.756 C1.94416832,21.096 2.04516832,21.458 2.29316832,21.705 L2.29416832,21.706 L2.29516832,21.707 C2.54316832,21.955 2.90316832,22.056 3.24416832,21.971 L8.94816832,20.544 C9.11116832,20.502 9.32816832,20.364 9.40916832,20.283 C9.41016832,20.282 9.41316832,20.28 9.41316832,20.28 L20.8221683,8.873 C21.5821683,8.112 22.0011683,7.102 22.0001683,6.026 C22.0001683,4.951 21.5821683,3.94 20.8221683,3.179 L20.8211683,3.179 Z M14.4111683,6.728 L17.2741683,9.591 L8.70616832,18.159 L5.84316832,15.296 L14.4111683,6.728 Z M4.37516832,19.626 L4.97016832,17.252 L6.75016832,19.033 L4.37516832,19.626 Z M19.4091683,4.594 C20.1731683,5.359 20.1731683,6.693 19.4091683,7.458 L18.6901683,8.178 L15.8241683,5.313 L16.5451683,4.594 C17.3101683,3.83 18.6441683,3.829 19.4091683,4.594 Z'
      fill={props.color}
    />
  </svg>

export default IconEdit
