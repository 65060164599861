import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import AppContext from 'AppContext'
import Table, {
  defaultFilterMethod,
  filterAndLinkArrowColumn,
  Filters
} from 'components/Table'
import { StatusBadge } from 'components/Badges'
import { DropDown, DropDownItem, Breadcrumbs } from './components'
import FreePassAPI from 'services/api/free_passes'
import { dateToLocale } from 'components/Helpers'
import { fullName } from './components'

const baseUrl = operatorId => `/operators/${operatorId}/free-passes`

const FreePassFilters = ({ columns, setColumns, toggleFiltered }) => {
  return (
    <Filters {...{ columns, setColumns, toggleFiltered }} />
  )
}

const columnDefinitions = (operatorId, setFilterable) => {
  return [
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => {
        // Change expired badges from default grey to red
        let cssStatus = value === 'expired' ? 'stopped' : 'active'

        return <StatusBadge status={value} cssStatus={cssStatus} />
      },
      show: true,
      filterable: false,
      sortable: false,
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: (a) => fullName(a),
      Cell: ({ original, value }) => {
        const freePass = original
        const path = `${baseUrl(operatorId)}/${freePass.id}`
        return (
          <Link to={{ pathname: path, state: { freePass } }}>
            { value }
          </Link>
        )
      },
      show: true,
    },
    {
      Header: 'Company',
      accessor: 'company',
      show: true,
    },
    {
      Header: 'Role',
      accessor: 'title',
      show: true,
    },
    {
      Header: 'Description',
      accessor: 'description',
      show: true,
    },
    {
      id: 'mobile',
      Header: 'Mobile',
      accessor: (a) => `${a.mobile_prefix} ${a.mobile_number}`,
      show: true,
    },
    {
      Header: 'E-mail',
      accessor: 'email',
      show: false
    },
    {
      Header: 'Begin date',
      accessor: 'begin_date',
      Cell: ({ value }) => dateToLocale(value),
      show: false,
      filterable: false,
    },
    {
      Header: 'End date',
      accessor: 'end_date',
      Cell: ({ value }) => dateToLocale(value),
      show: false,
      filterable: false,
    },
    filterAndLinkArrowColumn(setFilterable, 'free-passes')
  ]
}

const FreePassList = ({match}) => {
  const { operator: { id: operatorId } } = React.useContext(AppContext)

  const [freePasses, setFreePasses] = useState([])
  const [statusFilter, setStatusFilter] = useState('active')
  const [filterable, setFilterable] = useState(false)
  const [columns, setColumns] = useState(columnDefinitions(operatorId, setFilterable))

  useEffect(() => {
    FreePassAPI.search(
      operatorId,
      { filter: { status: statusFilter }},
      result => setFreePasses(result.data)
    )
  }, [operatorId, statusFilter])

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <Breadcrumbs operatorId={operatorId} />
        Free passes
        <span className='header-action-wrapper'>
          <DropDown>
            <DropDownItem
              txt='Complete'
              link={`${baseUrl(operatorId)}/long/new`}
            />
            <DropDownItem
              txt='Quick'
              link={`${baseUrl(operatorId)}/short/new`}
            />
          </DropDown>
        </span>
      </h1>
      <div className='menu-bar'>
        <ul>
          <li>
            <NavLink
              to={`${match.url}`}
              isActive={ () => statusFilter === 'active' }
              onClick={() => setStatusFilter('active')}
            >
              Active
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}`}
              isActive={ () => statusFilter === 'expired' }
              onClick={() => setStatusFilter('expired')}
            >
              Expired
            </NavLink>
          </li>
        </ul>
      </div>
      <FreePassFilters
        {...{ columns, setColumns }}
        toggleFiltered={setFilterable}
      />
      <div className='content-box'>
        <Table
          data={freePasses}
          loading={freePasses.length < 1}
          { ...{ columns, defaultFilterMethod, filterable } }
        />
      </div>
    </div>
  )
}

export default FreePassList
