import React  from 'react'
import { StatusBadge } from 'components/Badges'
import { ActionButton } from 'components/Buttons'

const LocationView = ({ location, match }) => {
  const token = window.localStorage.getItem('auth-token')
  const api = window.localStorage.getItem('reports-api')
  const root = window.localStorage.getItem('reports-root')

  if (!location) return null

  const { id, name } = location

  return (
    <div className='content-wrapper'>
      <h1>
        {name}
        <span style={{paddingLeft: '10px'}}>
          <StatusBadge status={location.status} />
        </span>
        <div className='header-action-wrapper'>
          <ActionButton txt='Edit' icon='edit white' link={`${match.url}/edit`} />
        </div>
      </h1>
      <reports-dashboard api={api} root={root} token={token} location-id={id}></reports-dashboard>
    </div>
  )
}

export default LocationView
