import React from 'react'

const PromotionIcon = props => (
  <svg width='1em' height='1em' viewBox='0 0 20 20' {...props}>
    <path
      d='M15 7a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm-4 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm-2 .682c0 .178-.224.318-.5.318s-.5-.14-.5-.318V7.318C8 7.146 8.224 7 8.5 7s.5.146.5.318v6.364zM6 10a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM17.985 0h-5.332a3.67 3.67 0 0 0-2.613 1.087L.69 10.433A2.353 2.353 0 0 0 0 12.107c0 .626.245 1.22.69 1.666l5.54 5.54c.438.44 1.046.687 1.667.687H7.9c.63 0 1.223-.247 1.668-.687l9.35-9.353A3.665 3.665 0 0 0 20 7.347V2.013A2.019 2.019 0 0 0 17.985 0z'
    />
  </svg>
)

export default PromotionIcon
