const API = require('./api')

const fetchLog = (operatorID, memberID, params, success) => {
  const url = '/memberships/' + memberID + '/keys'
  API.fetch(url, success, params)
}

module.exports = {
  fetchLog: fetchLog
}
