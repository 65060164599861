import React from 'react'

const AppContext = React.createContext({
  operator: {
    id: 1
  },
  user: {}
})

export default AppContext
