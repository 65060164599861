import React, { Component } from 'react'
import InvoiceAPI from 'services/api/invoices'
import reactStateToParams from 'components/Helpers/reactStateToParams'
import InvoiceListView from './InvoiceListView'

class InvoiceList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      invoices: [],
      operatorID: this.props.match.params.operatorID,
      memberID: this.props.match.params.memberID,
      pages: -1
    }
  }

  componentDidMount () {
    this.setState({ loading: true })
    const { operatorID, memberID } = this.state

    const params = reactStateToParams(this.state)
    InvoiceAPI.fetchInvoices(operatorID, memberID, params, (res) => {
      this.setState({ invoices: res.data })
      this.setState({ pages: Math.ceil(res.records / this.state.pageSize) })
      this.setState({ loading: false })
    })

    InvoiceAPI.fetchScheduledInvoices(operatorID, memberID, params, (res) => {
      let scheduled = res.data[0]
      scheduled.total = res.records
      this.setState({ scheduled: scheduled })
    })
  }

  render () {
    return <InvoiceListView {...this.state} />
  }
}

export default InvoiceList
