import React from 'react'

const IconPluss = props =>
  <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
    <path
      d='M15.2173913,10.4347826 L10.4347826,10.4347826 L10.4347826,15.2173913 C10.4347826,15.4573913 10.24,15.6521739 10,15.6521739 C9.76,15.6521739 9.56521739,15.4573913 9.56521739,15.2173913 L9.56521739,10.4347826 L4.7826087,10.4347826 C4.5426087,10.4347826 4.34782609,10.24 4.34782609,10 C4.34782609,9.76 4.5426087,9.56521739 4.7826087,9.56521739 L9.56521739,9.56521739 L9.56521739,4.7826087 C9.56521739,4.5426087 9.76,4.34782609 10,4.34782609 C10.24,4.34782609 10.4347826,4.5426087 10.4347826,4.7826087 L10.4347826,9.56521739 L15.2173913,9.56521739 C15.4573913,9.56521739 15.6521739,9.76 15.6521739,10 C15.6521739,10.24 15.4573913,10.4347826 15.2173913,10.4347826 M10,0 C4.48608696,0 0,4.48608696 0,10 C0,15.513913 4.48608696,20 10,20 C15.513913,20 20,15.513913 20,10 C20,4.48608696 15.513913,0 10,0'
      fill={props.color}
    />
  </svg>

export default IconPluss
