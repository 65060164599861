const API = require('./api')

const fetchOperators = (success, error) => {
  const url = 'operators'
  return API.fetchWithError(url, success, error)
}

module.exports = {
  fetchOperators: fetchOperators
}
