const COLORS = {
  primary: '#4727AA',
  secondary: '#EF4266',
  black: '#0F003F',
  accent1: '#1AB371',
  accent2: '#FFD23E',
  accent3: '#E6143E'
}

const DEMO = process.env.REACT_APP_DEMO === 'true'

export {
  COLORS,
  DEMO
}
