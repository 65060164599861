import React from 'react'
import './ChartJSConfig'
import ChartComponent from 'react-chartjs-2'

/*
 * Uses the generic wrapper lib ChartComponent (as opposed to Bar, Line etc)
 * to match the Report component that expects the full Chart.js object (starting
 * with type, data, options).
 *
 * The wrapping lib is used to avoid issues with flickering (and worse) after
 * windows resize using Chart.js directly. The wrapping library is doing something
 * smart which your dear author could not / was not willing to figure out.
 */
const Chart = ({ type, data, options }) => {
  return (
    // We must wrap Chart.js chart in a dedicated element to allow
    // for responsiveness
    <div className='chart'>
      <ChartComponent
        type={type}
        data={data}
        options={options}
        height={350}
        width={1300}
      />
    </div>
  )
}

export default Chart
