import { format } from 'date-fns'

// Returns e.g. Apr 19
const formatYearMonth = yearmonth => {
  let year = yearmonth.toString().substring(0, 4)
  let month = yearmonth.toString().substring(4, 6)
  let day = '01'
  let date = new Date(`${year}-${month}-${day}`)

  let pattern = 'MMM YY'

  return format(date, pattern)
}

export default formatYearMonth
