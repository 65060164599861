import React from 'react'

const CustomersIcon = props => (
  <svg width='1em' height='1em' viewBox='0 0 20 21' {...props}>
    <path
      d='M19.99 12.525l-.565-4.484a2.198 2.198 0 0 0-2.181-1.916h-2.173c-.244 0-.49.043-.733.128a.435.435 0 0 0-.277.525c.043.162.083.327.104.497l.583 5.083a2.353 2.353 0 0 1-.58 1.838c-.097.109-.215.21-.37.321a.43.43 0 0 0-.184.387l.346 4.813a.44.44 0 0 0 .439.408h3.519c.23 0 .422-.178.439-.407l.41-5.72c.347-.024.671-.18.903-.442.25-.283.367-.657.32-1.03m-6.116-.07l-.583-5.082c-.14-1.21-1.104-2.124-2.243-2.124H8.802c-1.14 0-2.104.913-2.243 2.124l-.583 5.082c-.049.425.083.848.361 1.158.24.268.56.424.904.442l.486 6.539a.44.44 0 0 0 .44.405h3.518c.23 0 .42-.178.44-.405l.484-6.539c.346-.018.663-.175.904-.442.277-.308.41-.733.36-1.157m-8.193 1.74a2.356 2.356 0 0 1-.579-1.838l.584-5.083c.02-.184.062-.36.11-.533a.44.44 0 0 0-.312-.54 2.18 2.18 0 0 0-.556-.077H2.756A2.196 2.196 0 0 0 .574 8.042L.01 12.525c-.047.374.07.75.32 1.032.232.261.557.418.903.442l.411 5.72a.438.438 0 0 0 .439.407h3.519a.44.44 0 0 0 .439-.406l.338-4.714a.432.432 0 0 0-.207-.403 2.365 2.365 0 0 1-.492-.406m4.245-9.822c1.214 0 2.2-.98 2.2-2.188A2.194 2.194 0 0 0 9.924 0c-1.215 0-2.2.98-2.2 2.187 0 1.209.986 2.188 2.2 2.188m6.233.875c1.214 0 2.2-.98 2.2-2.188a2.194 2.194 0 0 0-2.2-2.187c-1.214 0-2.199.979-2.199 2.187 0 1.209.985 2.188 2.2 2.188m-12.317 0c1.214 0 2.2-.98 2.2-2.188a2.194 2.194 0 0 0-2.2-2.187c-1.214 0-2.199.98-2.199 2.187 0 1.209.985 2.188 2.2 2.188'
    />
  </svg>
)

export default CustomersIcon
