import React from 'react'
import AppContext from 'AppContext'
import { Link } from 'react-router-dom'
import Table from 'components/Table/index'
import Refunds from './components/Refunds'
import { dateToLocale, timeToLocale } from 'components/Helpers/index'
import Period from './components/Period'
import Amount from 'components/Helpers/Amount'
import InvoiceBadge from './components/InvoiceBadge'
import LinkColumn
  from 'components/Table/components/LinkColumn'

const notAlreadyPresent = (scheduled, invoices) => {
  if (!scheduled) return
  if (invoices.length === 0) return true

  return invoices[invoices.length - 1].status !== 'scheduled'
}

const path = (invoice) =>
 invoice.status === 'scheduled' ?
   'scheduled-invoices' :
   `invoices/${invoice.id}/payments`

const paidDate = (invoice) => {
  if(!invoice || !invoice.payments) return null;
  const acceptedPayment = invoice.payments.find(p => p.status === 'accepted')
  return acceptedPayment ? acceptedPayment.created_at : null
}

const InvoiceListView = ({ invoices, operatorID, memberID, scheduled }) => {
  const { operator: {timezone} } = React.useContext(AppContext)

  if (!invoices) return null


  // Only push scheduled to invoices unless it is already added
  // otherwise we get multiple scheduled invoices when clicking around
  if (notAlreadyPresent(scheduled, invoices)) {
    invoices.push(scheduled)
  }

  return (
    <div className='index'>
      <div className='content-box invoices'>
        <Table
          className='invoice-list'
          data={invoices}
          columns={[
            {
              Header: 'Invoice',
              accessor: 'oid',
              width: 100,
              hide_for: {
                tablet: true,
                phone: true
              }
            },
            {
              Header: 'Status',
              id: 'status',
              width: 180,
              Cell: props =>
                <div className='invoice-status'>
                  <InvoiceBadge
                    invoice={props.original}
                    operatorID={operatorID}
                    memberID={memberID}
                  />
                  <Refunds payments={props.original.payments} />
                </div>
            },
            {
              Header: 'Invoice date',
              accessor: 'invoice_date',
              width: 120,
              style: {
                justifyContent: 'none'
              },
              Cell: props =>
                dateToLocale(props.original.invoice_date)
            },
            {
              Header: 'Amount',
              accessor: 'amount',
              width: 110,
              style: {
                justifyContent: 'none'
              },
              Cell: props => {
                const pathname = `/operators/${operatorID}/members/${memberID}/${path(props.original)}`

                return (
                  <Link to={{
                    pathname: pathname,
                    state: { invoice: props.original }
                  }}>
                    <Amount amount={props.original.amount} currency={props.original.currency} />
                  </Link>
                )
              }
            },
            {
              Header: 'Period',
              width: 240,
              accessor: 'period',
              style: {
                justifyContent: 'none'
              },
              Cell: props =>
                <Period period={props.original.period} />
            },
            {
              Header: 'Date paid',
              width: 120,
              style: {
                justifyContent: 'none'
              },
              Cell: props => {
                const datePaid = paidDate(props.original);
                if(!datePaid) return null

                return dateToLocale(timeToLocale(datePaid, timezone))
              }
            },
            {
              Header: '',
              maxWidth: 70,
              accessor: null,
              sortable: false,
              filterable: false,
              Cell: ({ original }) => {
                const pathname = `/operators/${operatorID}/members/${memberID}/${path(original)}`

                return (
                  <LinkColumn url={pathname} />
                )
              }
            }
          ]}
          defaultSorted={[
            {
              id: 'invoice_date',
              desc: true
            }
          ]}
        />
      </div>
    </div>
  )
}

export default InvoiceListView
