import React from 'react'
import Modal from 'components/Modal'
import { ActionButton } from 'components/Buttons'

const ErrorModal = ({error, close}) =>
  <Modal className='promotion'>
    <h1 className='modal-header'>Something went wrong</h1>
    <div className='modal-content' style={{ textAlign: 'center', marginBottom: '30px' }}>
      { error }
    </div>
    <ActionButton
      className='cancel'
      onClick={close}
      txt='Close'
    />
  </Modal>

export default ErrorModal
