import React from 'react'
import { Select } from 'components/Forms'

const RegionSelect = ({ value, regions }) => {
  return (
    <label>Region
      <Select className='region-select' name='region_id' value={value}>
        <option value="" key="-1">Choose region</option>
        { regions.map(region => {
          return (
            <option value={region.id} key={region.id}>{region.name}</option>
          )
        })}
      </Select>
    </label>
  )
}

export default RegionSelect
