import React, { Component } from 'react'
import InvoiceAPI from 'services/api/invoices'
import { extractInvoiceIDFromURL } from './components/Helpers'
import InvoicePaymentsView from './InvoicePaymentsView'
import AppContext from 'AppContext'

class InvoicePayments extends Component {
  static contextType = AppContext

  constructor (props) {
    super(props)

    this.state = {
      loading: true,
      invoice: null
    }
  }

  componentDidMount () {
    const { operatorID, memberID } = this.props.match.params
    const invoiceID = extractInvoiceIDFromURL(this.props.location)

    InvoiceAPI.fetchInvoice(operatorID, memberID, invoiceID, (res) => {
      this.setState({ invoice: res, loading: false })
    })
  }

  render () {
    return (
      <InvoicePaymentsView
        invoice={this.state.invoice}
        timezone={this.context.operator.timezone}
        loading={this.state.loading}
      />
    )
  }
}

export default InvoicePayments
