import React from 'react'
import { StatusBadge } from 'components/Badges'
import { Link } from 'react-router-dom'
import { ActionButton } from 'components/Buttons'
import { DetailBox } from '../../../components/Boxes'
import { Breadcrumbs, KeyActivation } from '../components'

const Name = ({ profile }) =>
  profile.first_name + ' ' + profile.last_name

const Role = ({ role }) =>
  role.charAt(0).toUpperCase() + role.split('_').join(' ').slice(1)

const Roles = ({ roles }) =>
  roles.map(r => <p key={r}><Role role={r} /></p>)

const Personalia = ({ admin }) => {
  const { profile, credentials } = admin
  const email = credentials ? credentials.username : ''

  const personaliaItems = [
    { label: 'Name', value: profile, type: 'name' },
    { label: 'Mobile', value: profile.mobile, type: 'mobile' },
    { label: 'Email', value: <Link to='/no-access'>{email}</Link> }
  ]

  return (
    <DetailBox header='Personalia' items={personaliaItems}>
      <ActionButton
        txt='Edit personalia'
        icon='edit white'
        link={`/operators/${admin.operator_id}/administrators/${admin.id}/edit/personlia`}
      />
    </DetailBox>
  )
}

const Valid = ({ admin }) => {
  const items = [
    { label: 'Begin date', value: admin.begin_date, type: 'date' },
    { label: 'End date', value: admin.end_date, type: 'date' }
  ]

  return (
    <DetailBox header='Valid' items={items}>
      <ActionButton
        txt='Edit validity'
        icon='edit white'
        link={`/operators/${admin.operator_id}/administrators/${admin.id}/edit/dates`}
      />
    </DetailBox>
  )
}

const InviteDetails = () => {
  return (
    <DetailBox header='Invite details'>
      <p>Sending a new invite will also generate a new temporary password.</p>
      <ActionButton
        txt='Send new invite'
        icon='repeat white'
        link='/under-development'
      />
    </DetailBox>
  )
}

const AssignedRoles = ({ admin }) => {
  return (
    <DetailBox header='Assigned roles'>
      <Roles roles={admin.roles} />
      <ActionButton
        txt='Manage roles'
        icon='edit white'
        link={`/operators/${admin.operator_id}/administrators/${admin.id}/edit/roles`}
      />
    </DetailBox>
  )
}

const ActiveKeys = ({ admin }) => {
  return (
    <KeyActivation ownerid={`${admin.id}`}/>
  )
}

const AdminView = ({ operator, admin }) =>
  <div className='content-wrapper'>
    <h1 className='with-breadcrumbs'>
      <Breadcrumbs operatorId={operator} />
      <Name profile={admin.profile} />
      <span style={{ paddingLeft: '10px' }}>
        <StatusBadge status={admin.status} />
      </span>
    </h1>
    <div className='box-wrapper administrators'>
      <Personalia admin={admin} />
      <AssignedRoles admin={admin} />
      <Valid admin={admin} />
      <ActiveKeys admin={admin} />
      <InviteDetails />
    </div>
  </div>

export default AdminView
