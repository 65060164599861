import React from "react";

export default function FormGroup({ header, children }) {
  return (
    <div className={'content-box'}>
      <div className='form-group'>
        <h2>{ header }</h2>
        { children }
      </div>
    </div>
  )
}
