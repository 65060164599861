import React from 'react'

const LockIcon = props => (
  <svg width='1em' height='1em' viewBox='0 0 14 20' {...props}>
    <title>{'Group'}</title>
    <defs>
      <path
        d='M12 8.11V5c0-2.757-2.243-5-5-5S2 2.243 2 5v3.11A6.976 6.976 0 0 0 0 13c0 3.86 3.141 7 7 7s7-3.14 7-7a6.976 6.976 0 0 0-2-4.89zM7 2c1.654 0 3 1.346 3 3v1.686A6.924 6.924 0 0 0 7 6c-1.074 0-2.09.25-3 .686V5c0-1.654 1.346-3 3-3zm0 16c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5zm0-2a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z'
        id='lock_icon_a'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <mask id='lock_icon_b' fill='#fff'>
        <use xlinkHref='#lock_icon_a' />
      </mask>
      <g mask='url(#lock_icon_b)' fill='#0F003F'>
        <path d='M-5-2h24v24H-5z' />
      </g>
    </g>
  </svg>
)

export default LockIcon
