import React, { useRef, useEffect, useState } from 'react'

import ErrorMessage from 'components/Forms/components/ErrorMessage'
import { Select } from 'components/Forms'
import { useFormContext } from "react-hook-form"

const Lifetime = ({ focus = true }) => {
  const [lifetime, setLifetime] = useState('')
  const [amount, setAmount] = useState('24')
  const [scale, setScale] = useState('hours')
  const handleLifetimeChange = (e) => {
    setLifetime(`${amountRef.current.value} ${scale}`)
  }

  const updateScale = (e) => {
    setScale(e.currentTarget.value)
  }

  const updateAmount = (e) => {
    setAmount(e.currentTarget.value)
  }

  const amountRef = useRef(null)

  useEffect(() => {
    if(focus) {
      amountRef.current.focus()
    }
    handleLifetimeChange()
  })

  const { register, errors } = useFormContext()

  let options = scales.map((prefix) =>
    <option key={prefix} value={prefix}>{prefix}</option>
  )

  return (
    <div style={{marginBottom: '1rem'}}>
      <label style={{margin: 0}}>Lifetime</label>
      <input
        name='lifetime'
        type='hidden'
        value={lifetime}
        ref={register()}
        readOnly
      />
      <div style={{display: 'flex'}}>
        <input
          type='number'
          name='_lifetime_amount'
          value={amount}
          min="1"
          ref={amountRef}
          onChange={updateAmount}
        />

        <Select
          name='_lifetime_scale'
          onChange={updateScale}
          value={scale}
        > {options} </Select>
      </div>
      <ErrorMessage name='lifetime' errors={errors} />
    </div>
  )
}

const scales = ['minutes', 'hours', 'days']

export default Lifetime
