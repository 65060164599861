import React, { Component } from 'react'
import { updateSheltered } from 'services/api/members'
import { toast } from 'react-toastify'
import { SwitchWithLabelRight } from 'components/Switches'

export default class Product extends Component {
  constructor (props) {
    super(props)

    this.state = {
      sheltered: this.props.member.sheltered
    }

    this.submit = this.submit.bind(this)
    this.toggleSheltered = this.toggleSheltered.bind(this)
  }

  submit (event) {
    event.preventDefault()
    // submit sheltered through api
    console.log('Updating sheltered to', this.state.sheltered)

    const { id, operator_id: operatorID } = this.props.member

    updateSheltered({ sheltered: this.state.sheltered }, operatorID, id, (res) => {
      // console.log(res)
      this.props.memberFromServer()
      toast.success('saved')
    }, (err) => {
      console.log(err)
      toast.error('error')
    })
  }

  toggleSheltered () {
    console.log('toggleShelter to', !this.state.sheltered)
    this.setState({ sheltered: !this.state.sheltered })
  }

  render () {
    return (
      <div className='box-wrapper single'>
        <div className='box'>
          <h2>Product</h2>
          { this.props.member.product.name }
        </div>
        <div className='box'>
          <h2 style={{marginBottom: '5px'}}>Sheltered</h2>
          <form id='shelterForm' onSubmit={this.submit}>
            <SwitchWithLabelRight
              checked={this.state.sheltered}
              onClick={this.toggleSheltered}
            />
          </form>
        </div>
        <div style={{ margin: 'auto', marginTop: '20px' }}>
          <button form='shelterForm' type='submit' className='btn'>
            Save changes
          </button>
        </div>
      </div>
    )
  }
}
