import React from 'react'
import { ActionButton } from 'components/Buttons'
import { DetailBox } from 'components/Boxes'

const PTHours = ({ promotion }) => {
  const items = [
    { label: 'Free PT hours', value: promotion.pt_hours }
  ]

  return (
    <DetailBox header='PT Hours' items={items}>
      <ActionButton
        txt='Edit PT hours'
        icon='edit white'
        link={`/operators/${promotion.operator_id}/promotions/${promotion.id}/pt-hours/edit`}
      />
    </DetailBox>
  )
}

export default PTHours
