import React from 'react'

const IconReport = props =>
  <svg width='1em' height='1em' viewBox='0 0 24 22' {...props}>
    <path
      d='M15.9235556,2 L9.33333333,2 C8.35288889,2 7.55555556,2.79822222 7.55555556,3.77777778 L7.55555556,16.2222222 C7.55555556,17.2026667 8.35288889,18 9.33333333,18 L18.2222222,18 C19.2026667,18 20,17.2026667 20,16.2222222 L20,6.07644444 L15.9235556,2 Z M18.2231111,16.2222222 L9.33333333,16.2222222 L9.33333333,3.77777778 L14.6666667,3.77777778 L14.6666667,7.33333333 L18.2222222,7.33333333 L18.2231111,16.2222222 Z M5.77777778,5.55555556 L5.77777778,19.7777778 L16.4444444,19.7777778 L16.4444444,21.5555556 L5.77777778,21.5555556 C4.79733333,21.5555556 4,20.7582222 4,19.7777778 L4,5.55555556 L5.77777778,5.55555556 Z'
      fill={props.color}
    />
  </svg>

export default IconReport
