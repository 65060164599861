import React from 'react'
import { Link } from 'react-router-dom'
import { ActionButton } from 'components/Buttons'
import { DetailBox } from 'components/Boxes'
import { capitalizeFirstLetter, dateToLocale } from 'components/Helpers'
import { StatusBadge } from 'components/Badges'

const adminLink = (obj, date, operatorId) => {
  if (!obj.name) return dateToLocale(date)

  return (
    <span>
      { dateToLocale(date) } by&nbsp;
      <Link to={`/operators/${operatorId}/administrators/${obj.id}`}>
        { obj.name }
      </Link>
    </span>
  )
}

const promotionType = ({ promotion_type: type }) =>
  capitalizeFirstLetter(type) + ' promotion'

const PromotionDetails = ({ promotion }) => {
  const items = [
    { label: 'Name', value: promotion.name },
    { label: 'Type', value: promotionType(promotion) },
    { label: 'Created', value: adminLink(promotion.created_by, promotion.created_at, promotion.operator_id) },
    { label: 'Updated', value: adminLink(promotion.updated_by, promotion.updated_at, promotion.operator_id) },
    { label: 'Status', value: <StatusBadge status={promotion.status} /> }
  ]

  return (
    <DetailBox header='Promotion Details' items={items}>
      <ActionButton
        txt='Edit promotion details'
        icon='edit white'
        link={`/operators/${promotion.operator_id}/promotions/${promotion.id}/details/edit`}
      />
    </DetailBox>
  )
}

export default PromotionDetails
