import React from 'react'
import Amount from 'components/Helpers/Amount'
import Period from './components/Period'
import Concept from './components/Concept'
import InvoiceNumberWithBadge from './components/InvoiceNumberWithBadge'
import RefundButton from './components/RefundButton'

const InvoiceView = ({ invoice, loading }) => {
  if (loading) {
    return null
  }

  const invoiceLines = invoice.lines.map((line, index) =>
    <tr key={index}>
      <td><Period period={line.period} /></td>
      <td><Concept systemConcept={line.system_concept} humanConcept={line.human_concept} /></td>
      <td><Amount amount={line.amount} currency={invoice.currency} /></td>
    </tr>
  )

  return (
    <div className='index'>
      <div className='work-in-progress' style={{display: 'flex', 'justifyContent': 'space-between'}} >
        <InvoiceNumberWithBadge invoice={invoice} />
        <RefundButton invoice={invoice} />
      </div>

      <div className='content-box'>
        <table style={{ padding: '2em 2em 5em 2em' }}>
          <thead>
            <tr>
              <th>Period</th>
              <th>Description</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            { invoiceLines }
          </tbody>
          <tr>
            <td />
            <td>Total</td>
            <td><Amount amount={invoice.amount} currency={invoice.currency} /></td>
          </tr>
          <tfoot />
        </table>
      </div>
    </div>
  )
}

export default InvoiceView
