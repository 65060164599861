import React from 'react'

const IconMasterCard = props => (
  <svg width='1em' height='1em' viewBox='0 0 24 18' {...props}>
    <g transform='translate(0 .13)' fillRule='nonzero' fill='none'>
      <path
        d='M22.435 0H1.565C.701 0 0 .7 0 1.565v14.609c0 .864.7 1.565 1.565 1.565h20.87c.864 0 1.565-.7 1.565-1.565V1.565C24 .701 23.3 0 22.435 0z'
        fill='#003564'
      />
      <circle fill='#F01524' cx={9.391} cy={8.87} r={4.174} />
      <path
        d='M12 12.125c.049-.039.092-.084.14-.125h-.28c.048.041.091.086.14.125z'
        fill='#376BD1'
      />
      <path
        d='M14.609 4.696c-1.318 0-2.49.612-3.256 1.565h1.294c.13.163.245.34.351.522h-2.001a4.1 4.1 0 0 0-.256.521h2.514c.068.17.128.343.174.522h-2.858c-.043.17-.078.344-.1.522h3.06a4.2 4.2 0 0 1 .034.522h-3.13c0 .176.014.35.036.521h3.06a4.191 4.191 0 0 1-.102.522h-2.858c.046.178.102.353.17.522h2.514c-.074.18-.16.355-.256.522h-2.001c.106.183.224.357.355.521h1.294c-.152.19-.325.362-.508.522H11.854a4.153 4.153 0 0 0 2.754 1.043 4.174 4.174 0 0 0 0-8.347z'
        fill='#FEB415'
      />
    </g>
  </svg>
)

export default IconMasterCard
