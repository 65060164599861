import React from 'react'

const KeySearch = ({operatorid}) => {
  let env = "staging"
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = "production";
  }
  const token = window.localStorage.getItem('auth-token')

  return (
    <div className='content-box keysearch'>
      <cl-find-user-by-key
        mountPath={`/operators/${operatorid}/members`}
        apiUrl={`https://access-control.${env}.credlock.net/api`}
        token={token}
        >
      </cl-find-user-by-key>
    </div>
  )
}

export default KeySearch
