import React from 'react'
import { ActionButton } from 'components/Buttons'
import { DetailBox } from 'components/Boxes'

const TrialDays = ({ promotion }) => {
  const items = [
    { label: 'Number of trial days', value: promotion.trial_days }
  ]

  return (
    <DetailBox header='Trial Days' items={items}>
      <ActionButton
        txt='Edit trial days'
        icon='edit white'
        link={`/operators/${promotion.operator_id}/promotions/${promotion.id}/trial-days/edit`}
      />
    </DetailBox>
  )
}

export default TrialDays
