import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class SubMenu extends Component {
  constructor (props) {
    super(props)
    this.state = {
      active_menu: props.active || props.items[0].name,
      items: props.items
    }
  }

  render () {
    return (
      <div className='menu-bar'>
        <ul>{ this.state.items.map(item => this._menuItem(item)) }</ul>
      </div>
    )
  }

  _menuItem = (item) => {
    const MockClick = () => {
      this.setState({ active_menu: item.name })
    }

    return (
      <li key={item.name}>
        <Link className={item.name === this.state.active_menu ? 'active' : ''}
          to={{ pathname: item.path }}
          onClick={MockClick} >
          { item.name }
        </Link>
      </li>
    )
  }
}

export default SubMenu
