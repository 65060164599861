import { format, subMonths, addMonths, startOfMonth, endOfMonth, startOfYear } from 'date-fns'

const lastTwelveMonths = () => {
  let beginningOfMonthOneYearAgo = startOfMonth(subMonths(today(), 11))

  return {
    begin_date: formatDate(beginningOfMonthOneYearAgo),
    end_date: formatDate(today())
  }
}

const lifetime = () => {
  return {
    // TODO: We're using an artificial begin date that covers CH only
    begin_date: formatDate(new Date(2015, 2, 1)),
    end_date: formatDate(today())
  }
}

const currentYear = () => {
  return {
    begin_date: formatDate(startOfYear(today())),
    end_date: formatDate(today())
  }
}

const monthAtIndex = (index = 0) => {
  return {
    begin_date: formatDate(startOfMonth(addMonths(today(), index))),
    end_date: formatDate(endOfMonth(addMonths(today(), index)))
  }
}

const today = () => new Date()

const formatDate = date => format(date, 'YYYY-MM-DD')

export default {
  lastTwelveMonths,
  currentYear,
  lifetime,
  monthAtIndex
}
