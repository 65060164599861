import ExcelJS from 'exceljs/modern.browser'
import { saveAs } from 'file-saver'
import buildRow from './buildRow'

const buildColumn = (col) => {
  // We use data from the column if present, but defaults to 20
  let colWidth = (col.width || col.minWidth || 200) / 10

  // We ensure that the column width is at least as big as the Header content
  if (colWidth < col.Header.length)
    colWidth = col.Header.length

  const res = {
    header: col.Header,
    key: col.accessor,
    width: colWidth,
    // Uncomment the line below if we want to
    // auto hide columns that are hidden in the UI
    // hidden: !col.show
  }

  // It is possible to override the style of a column
  // by providing an object to exportFormat.
  // Example: { numFmt: 'dd/mm/yyyy' } to set the column to
  // Date type with the given format.
  if (col.exportFormat) {
    res.style = col.exportFormat
  }

  return res
}

const toExcel = (data, columns, context, fileName = 'export') => {
  const workbook = new ExcelJS.Workbook()
  const sheet = workbook.addWorksheet('export')

  sheet.columns = columns
    .filter(c => typeof c.Header === 'string')
    .map(col => buildColumn(col))

  data.forEach(row => {
    sheet.addRow(buildRow(columns, row, context))
  })

  workbook.xlsx
    .writeBuffer()
    .then(buffer => {
      saveAs(
        new Blob(
          [buffer],
          {type: 'application/vnd.ms-excel;charset=utf-8'}),
        fileName
      )
    })
}

export default toExcel
