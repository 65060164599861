const stateToParams = (state) => {
  let params = {}

  // Building params from react-table state
  if (state) {
    params = {
      page_size: state.pageSize,
      page: state.page,
    }

    if (state.filtered)
      params.filtered = state.filtered

    if (state.filter)
      params.filter = state.filter

    if (state.sorted && state.sorted[0]) {
      params['sortField'] = state.sorted[0].id
      params['sortDir'] = state.sorted[0].desc ? 'desc' : 'asc'
    }
  }

  return params
}

export default stateToParams
