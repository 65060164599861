// Finds out which mock file to load
// based on given url
const mock = (url) => {
  let parts = url.split('/')

  let list

  try {
    if (parts[1] === 'reports') { // Allows for reports without mocking data provided
      try {
        list = require('./mock-api/' + parts[parts.length - 1])
      } catch (err) {
        list = {}
      }
    } else if (parts.length > 2) {
      list = require('./mock-api/' + parts[parts.length - 1])
    } else {
      list = require('./mock-api/' + parts[0])
      if (parts.length > 1) {
        if (!list.data) return list

        // Lookup correct item from list by id
        let res = list.data.find(o => o.id === parts[1])

        // For membership merge in data from
        // member.json on single view
        if (parts[0] === 'memberships') {
          const member = require('./mock-api/member.json')
          return Object.assign({}, member, res)
        }

        return res
      }
    }
  } catch {
    list = { records: 0, data: [] }
  }

  return list
}

const filteredData = (original, filter) => {
  const { search, status, types } = filter

  let filteredRes = original

  if (status && status !== 'all') {
    filteredRes = filteredRes.filter((r) => {
      return r.status === status
    })
  }

  if (search) {
    filteredRes = filteredRes.filter(r =>
      r.name.startsWith(search)
    )
  }

  if (types) {
    filteredRes = filteredRes.filter((r) => {
      return types.includes(r.promotion_type)
    })
  }

  return filteredRes
}

// This is just to be able to have some search
// functionality with mock data in development
const mockFiltering = (state, res) => {
  if (!state) return res
  if (!state.filter) return res
  if (!res) return res

  const data = filteredData(res.data, state.filter)

  return {
    records: data.length,
    data: data
  }
}

const FetchWithError = (url, success, error, params = null) => {
  success(mockFiltering(params, mock(url)))
}

const Fetch = (url, success, params = null) => {
  success(mockFiltering(params, mock(url)))
}

const Post = (url, success, body, error = () => {}) => {
  success(body)
}

const Patch = (url, success, body, error = () => {}) => {
  success(body)
}

module.exports = {
  fetch: Fetch,
  fetchWithError: FetchWithError,
  post: Post,
  patch: Patch
}
