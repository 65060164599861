import React, { Component } from 'react'
import Select from 'react-select'
import Table, { Filters } from 'components/Table/index'
import { timeToLocale } from 'components/Helpers/dateToLocale'
import { reactStateToParams, capitalizeFirstLetter } from 'components/Helpers'
import LogAPI from 'services/api/log'
import { LogStatusBadge } from 'components/Badges'
import { Link } from 'react-router-dom'

const allTypes = [
  'access_log',
  'sms',
  'email',
  'ui-event',
  'system'
]

const TypeSelector = ({ onChange, filtered }) => {
  const filteredTypes = filtered.find(f => f.id === 'types')

  const onStatusChange = (ev) => {
    if (!ev || ev.length < 1)
      return filtered.filter(f => f.id !== 'types')

    return filtered
      .filter(f => f.id !== 'types')
      .concat(({ id: 'types', value: ev.map(e => e.value) }))
  }

  const option = (type) => ({
    value: type,
    label: type
  })

  const options = allTypes
    .map(option)

  const value = filteredTypes
    ? filteredTypes.value.map(option)
    : []

  return (
    <Select
      isMulti
      options={options}
      value={value}
      onChange={(ev) => onChange(onStatusChange(ev))}
    />
  )
}

const LogFilters = ({ filtered, setFiltered, search, searchChange }) => {
  return (
    <Filters
      filtered={filtered}
      setFiltered={setFiltered}
      withColumns={false}
      withSearch={true}
      onSubmit={search}
      onChange={searchChange}
    >
      <div>
        <h3>Filter by type</h3>
        <TypeSelector filtered={filtered} onChange={setFiltered} />
      </div>
    </Filters>
  )
}

class Log extends Component {
  constructor (props) {
    super(props)

    this.state = {
      log: [],
      operatorID: this.props.match.params.operatorID,
      memberID: this.props.match.params.memberID,
      pages: -1,
      tableState: {
        filter: {
          search: ''
        },
        filtered: []
      },
    }

    this.onFilterChange = this.onFilterChange.bind(this)
    this.search = this.search.bind(this)
    this.searchChange = this.searchChange.bind(this)
  }

  render () {
    return (
      <div className='index'>
        <LogFilters
          filtered={this.state.tableState.filtered}
          setFiltered={this.onFilterChange}
          withColumns={false}
          withSearch={true}
          onSubmit={this.search}
          onChange={this.searchChange}
        />
        <div className='content-box log'>
          <Table
            data={this.state.log}
            columns={this._columns()}
            pages={this.state.pages}
            loading={this.state.loading}
            filtered={this.state.tableState.filtered}
            serverSidePagination
            onFetchData={this._fetchLog}
          />
        </div>
      </div>
    )
  }

  searchChange (ev) {
    this.setState({ search: ev.target.value })
  }

  search (ev) {
    ev.preventDefault()

    // TODO: Refactor?
    // FIXME: Duplicate of promotion search hack
    let state = Object.assign({}, this.state.tableState)
    state.filter.search = this.state.search

    // We reset the page to first page on search
    this.setState({ page: 0 })

    this._fetchLog(
      state
    )
  }

  onFilterChange (filtered) {
    // console.log('onFilterChange called with filtered', filtered)

    let state = Object.assign({}, this.state.tableState)
    state.filtered = filtered

    this.setState(
      {
        // Hack to be able to change the table state
        tableState: state,
      }
    )

    this._fetchLog(state)
  }

  _columns = () => (
    [
      {
        Header: 'Type',
        accessor: 'type',
        minWidth: 180,
        Cell: props => {
          let value = props.value

          if (value === 'access_log') {
            value = props.original.context.access_point
          }

          if (value === 'ui-event') {
            value = 'my page'
          }

          return (
            <LogStatusBadge type={value} />
          )
        }
      },
      {
        Header: 'Date',
        accessor: 'date',
        minWidth: 180,
        Cell: props =>
          timeToLocale(props.value, this.props.timezone)
      },
      {
        Header: 'Event',
        accessor: 'event',
        minWidth: 200,
        Cell: props => {
          if (props.original.type === 'access_log') {
            return props.original.context.key.type.toUpperCase() + ' ' + props.original.context.key.code
          }

          return capitalizeFirstLetter(props.value)
        }
      },
      {
        Header: 'Touchpoint',
        accessor: 'touchpoint',
        minWidth: 160,
        Cell: props =>
          <Link
            to={`${this._baseURL()}/id`}>
            {capitalizeFirstLetter(props.value)}
          </Link>
      }
    ]
  )

  _baseURL () {
    const { operatorID, memberID } = this.state
    return `/operators/${operatorID}/members/${memberID}/log`
  }

  _fetchLog = (state, instance) => {
    const { operatorID, memberID } = this.state

    state.filter = this.state.tableState.filter

    this.setState(
      {
        loading: true,
        // Hack to be able to change the table state
        tableState: {
          page: state.page,
          pageSize: state.pageSize,
          filter: state.filter,
          filtered: state.filtered,
        },
        page: state.page,
      }
    )

    const params = reactStateToParams(state)
    LogAPI.fetchLog(operatorID, memberID, params, (res) => {
      this.setState({ log: res.data })
      this.setState({ pages: Math.ceil(res.records / state.pageSize) })
      this.setState({ loading: false })
    })
  }
}

export default Log
