import React from 'react'
import { Link } from 'react-router-dom'
import Icon from 'components/Icons'

const style = {
  position: 'relative',
  paddingBottom: '30px'
}

const TopBar = ({url, header, info}) => {
  const style = {
    marginTop: '-10px',
    marginLeft: '-24px',
    marginRight: '-24px',
    color: 'white',
    backgroundColor: '#1ab371',
    padding: '12px',
    fontFamily: 'MarkPro, sans-serif',
    fontSize: '16px'
  }

  return (
    <Link to={url}>
      <h2 style={style}>
        <span
          style={{
            float: 'right',
            textTransform: 'none',
            fontWeight: 'lighter'
          }}>
          { info }
        </span>
        { header }
      </h2>
    </Link>
  )
}

const Arrow = ({url}) => {
  const style = {
    position: 'absolute',
    right: '50px',
    top: '52%'
  }

  return (
    <div style={style}>
      <Link to={url}>
        <Icon
          type='arrow'
          direction='right'
          color='grey'
          style={{
            fontSize: '40px'
          }}
        />
      </Link>
    </div>
  )
}

const Grid2 = ({children}) => {
  const style = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  }

  return (
    <div style={style}>
      { children }
    </div>
  )
}

const Grid4 = ({children}) => {
  const style = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr'
  }

  return (
    <div style={style}>
      { children }
    </div>
  )
}

const Section = ({header, children}) =>
  <div className='box-section'>
    <h3>{header}</h3>
    { children }
  </div>

const Current = ({ match, children, obj, url, header, info }) =>
  <div className='box-wrapper single'>
    <div style={style} className='box'>
      <TopBar url={url} header={header} info={info} />
      <Arrow url={url} />
      { children }
    </div>
  </div>

export default Current

export {
  Grid2,
  Grid4,
  Section
}
