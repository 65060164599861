const API = require('./api')

/*
 * Fetch report with given reportName (matches name in reports service).
 *
 * Any parameters required by the report should be supplied using the reportParams object.
 */
const fetchReport = (reportName, reportParams, success) => {
  API.fetch(`/reports/${reportName}`, success, reportParams)
}

const fetchAvailableReports = success => {
  API.fetch('/reports', success)
}

module.exports = {
  fetchReport,
  fetchAvailableReports,
}
