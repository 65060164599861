import React, { useState, useEffect } from 'react'
import FreePassAPI from 'services/api/free_passes'
import { Redirect } from 'react-router-dom'
import {
  viewFreePassUrl,
  Breadcrumbs,
} from './components'
import {
  Form,
  Switch,
  Submit,
} from 'components/Forms'

const EditPriviligedAccess = ({ match }) => {
  const { id, operatorID: operatorId } = match.params
  const [updatedId, setUpdatedId] = useState(null)
  const [freePass, setFreePass] = useState(null)

  useEffect(() => {
    FreePassAPI.fetchFreePass(operatorId, id, setFreePass)
  }, [id, operatorId])

  if (!freePass) return null

  const onSubmit = data => {
    FreePassAPI.update(
      { ...data, id },
      id,
      successfulResult => setUpdatedId(successfulResult.id),
      errorResult => alert(errorResult) // TODO display error message(s)
    )
  }

  if (updatedId) return <Redirect to={viewFreePassUrl(updatedId, operatorId)} />

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <Breadcrumbs operatorId={operatorId} freePass={freePass} />
        Edit free pass
      </h1>
      <div className='content-box form'>
        <Form onSubmit={onSubmit} defaultValues={freePass}>
          <Switch
            label='Grant access to non-public areas such as technical rooms, PT offices etc.'
            name='privileged_access'
            checked={freePass.privileged_access ? true : null}
          />
          <Submit label='Update' />
        </Form>
      </div>
    </div>
  )
}

export default EditPriviligedAccess
