import React, { useState, useEffect } from 'react'
import PromotionsAPI from 'services/api/promotions'
import { Redirect } from 'react-router-dom'
import {
  viewPromotionUrl,
  Breadcrumbs,
} from './components'
import {
  Form,
  TextInput,
  Submit,
} from 'components/Forms'

const EditTrialDays = ({ match }) => {
  const { promotionID: id, operatorID: operatorId } = match.params
  const [promotion, setPromotion] = useState(null)
  const [isUpdated, setIsUpdated] = useState(false)
  const [validationErrors, setValidationErrors] = useState({})

  useEffect(() => PromotionsAPI.get(operatorId, id, setPromotion), [id, operatorId])

  if (!promotion) return null

  const onSubmit = data => {
    PromotionsAPI.update(
      { type: promotion.promotion_type, part: 'trial_days', id, ...data },
      _successfulResult => setIsUpdated(true),
      errorResult => setValidationErrors(errorResult)
    )
  }

  if (isUpdated) return <Redirect to={viewPromotionUrl(id, operatorId)} />

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <Breadcrumbs operatorId={operatorId} promotion={promotion} />
        Edit trial days
      </h1>
      <div className='content-box form'>
        <Form onSubmit={onSubmit} defaultValues={promotion} validationErrors={validationErrors}>
          <TextInput name='trial_days'/>
          <Submit label='Update' />
        </Form>
      </div>
    </div>
  )
}

export default EditTrialDays
