import React from 'react'

const PTPackage = () => {
  const token = process.env.REACT_APP_PT_TOKEN || window.localStorage.getItem('auth-token')
  window.sessionStorage.setItem('pts-token', token)

  return (
    <div className='index'>
      { /* eslint-disable-next-line */ }
      <h1></h1>
      <pt-package-admin></pt-package-admin>
    </div>
  )
}

export default PTPackage
