import React, { Component } from 'react'
import MembersAPI from 'services/api/members'
import { reactStateToParams } from 'components/Helpers'
import MemberListView from './MemberListView'
import { exportToExcel } from 'components/Table/helpers'
import KeySearch from './components/KeySearch'

export default class Members extends Component {
  constructor (props) {
    super(props)
    this.state = {
      members: [],
      selected_operator: props.selected_operator,
      pages: -1,
      page: 0,
      loading: true,
      tableState: {
        filter: {
          search: ''
        },
        filtered: []
      },
      error: false
    }

    this._fetchMembers = this._fetchMembers.bind(this)
    this.search = this.search.bind(this)
    this.searchChange = this.searchChange.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.exportToExcel = this.exportToExcel.bind(this)
  }

  render () {
    return (
      <div>
        <MemberListView
          error={this.state.error}
          url={this.props.match.url}
          operator={this.state.selected_operator}
          members={this.state.members}
          pages={this.state.pages}
          page={this.state.page}
          onPageChange={this.onPageChange}
          loading={this.state.loading}
          onFetchData={this._fetchMembers}
          search={this.search}
          filtered={this.state.tableState.filtered}
          searchChange={this.searchChange}
          onFilterChange={this.onFilterChange}
          exportToExcel={this.exportToExcel}
        />
        <KeySearch
          operatatorid={this.state.selected_operator}
        />
      </div>
    )
  }

  exportToExcel (columns) {
    const params = reactStateToParams(
      Object.assign(
        {},
        this.state.tableState,
        // We must set the pageSize to be the as big as the
        // expected result to get all of the records and not paginated
        { pageSize: this.state.records }
      )
    )

    exportToExcel(
      columns,
      params,
      this.state.selected_operator,
      MembersAPI.fetchMembers,
      {},
      'customer_export.xlsx'
    )
  }

  search (ev) {
    ev.preventDefault()

    // TODO: Refactor?
    // FIXME: Duplicate of promotion search hack
    let state = Object.assign({}, this.state.tableState)
    state.filter.search = this.state.search

    // We reset the page to first page on search
    this.setState({ page: 0 })

    this._fetchMembers(
      state
    )
  }

  onFilterChange (filtered) {
    // console.log('onFilterChange called with filtered', filtered)

    let state = Object.assign({}, this.state.tableState)
    state.filtered = filtered

    this.setState(
      {
        // Hack to be able to change the table state
        tableState: state,
      }
    )
  }

  onPageChange (page) {
    this.setState({ page: page })
  }

  searchChange (ev) {
    this.setState({ search: ev.target.value })
  }

  _fetchMembers = (state, instance) => {
    state.filter = this.state.tableState.filter

    this.setState(
      {
        loading: true,
        // Hack to be able to change the table state
        tableState: {
          page: state.page,
          pageSize: state.pageSize,
          filter: state.filter,
          filtered: state.filtered,
        },
        page: state.page,
      }
    )

    const params = reactStateToParams(state)

    MembersAPI.fetchMembers(this.state.selected_operator, params, (res) => {
      this.setState({ members: res.data })
      this.setState({ records: res.records })
      this.setState({ pages: Math.ceil(res.records / state.pageSize) })
      this.setState({ loading: false })
    }, (err) => {
      this.setState({ error: err.reason })
    })
  }
}
