import React from 'react'

const IconInfo = props => (
  <svg width='1em' height='1em' viewBox='0 0 20 20' {...props}>
    <defs>
      <path
        d='M12 22C6.486 22 2 17.515 2 12 2 6.487 6.486 2 12 2s10 4.486 10 10c0 5.515-4.486 10-10 10zm0-18c4.411 0 8 3.589 8 8s-3.589 8-8 8-8-3.589-8-8 3.589-8 8-8zm1 11v-4a1 1 0 0 0-1-1h-2v2h1v3H9v2h6v-2h-2zm-1-5.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5z'
        id='info_prefix__a'
      />
    </defs>
    <g transform='translate(-2 -2)' fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <mask id='info_prefix__b' fill='#fff'>
        <use xlinkHref='#info_prefix__a' />
      </mask>
      <g mask='url(#info_prefix__b)' fill={props.color || '#0F003F'}>
        <path d='M0 0h24v24H0z' />
      </g>
    </g>
  </svg>
)

export default IconInfo
