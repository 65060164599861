import React from 'react'
import SalesPromotionListView from './SalesPromotionListView'
import withPromotions from '../withPromotions'

const columns = [
  'code',
  'status',
  'name',
  'pt_hours',
  'signup_fee',
  'monthly_payment_schedule',
  'created_by.name',
  'updated_by.name',
  'trigger_location_ids',
  'trial_days',
  'sales_period_begins_at',
  'sales_period_ends_at',
  'created_at'
]

const filters = [
  'status',
  'locations'
]

const SalesPromotionListViewWrapper = (props) =>
  <SalesPromotionListView {...props} />

export default withPromotions(
  SalesPromotionListViewWrapper,
  { types: ['sales'] },
  columns,
  filters
)
