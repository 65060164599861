import React from 'react'
import { DetailBox } from '../../../../components/Boxes'
import { ActionButton } from '../../../../components/Buttons'

const SalesPeriod = ({ promotion }) => {
  const {
    sales_period_begins_at: beginsAt,
    sales_period_ends_at: endsAt
  } = promotion

  const items = [
    { label: 'Begin date', value: beginsAt, type: 'date' },
    { label: 'End date', value: endsAt, type: 'date' }
  ]

  return (
    <DetailBox header='Sales period' items={items}>
      <ActionButton
        txt='Edit sales period'
        icon='edit white'
        link={`/operators/${promotion.operator_id}/promotions/${promotion.id}/sales-period/edit`}
      />
    </DetailBox>
  )
}

export default SalesPeriod
