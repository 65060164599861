import React, { useState, useEffect } from 'react'
import AdminAPI from 'services/api/admins'
import { Redirect } from 'react-router-dom'
import {
  viewAdminUrl,
  Breadcrumbs,
} from '../components'
import {
  Form,
  FormLine,
  DateInput,
  Submit,
} from 'components/Forms'

const EditValidFromAndToDates = ({ match }) => {
  const { id, operatorID: operatorId } = match.params
  const [updatedId, setUpdatedId] = useState(null)
  const [admin, setAdmin] = useState(null)
  const [validationErrors, setValidationErrors] = useState({})

  useEffect(() => {
    AdminAPI.fetchAdmin(operatorId, id, setAdmin)
  }, [id, operatorId])

  if (!admin) return null

  const onSubmit = data => {
    AdminAPI.update(
      { ...data, part: 'dates', id },
      successfulResult => setUpdatedId(successfulResult.id),
      errorResult => setValidationErrors(errorResult)
    )
  }

  if (updatedId) return <Redirect to={viewAdminUrl(updatedId, operatorId)} />

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <Breadcrumbs operatorId={operatorId} admin={admin} />
        Edit admin
      </h1>
      <div className='content-box form'>
        <Form onSubmit={onSubmit} defaultValues={admin} validationErrors={validationErrors}>
          <FormLine>
            <DateInput name='begin_date'/>
            <DateInput name='end_date'/>
          </FormLine>
          <Submit label='Update' />
        </Form>
      </div>
    </div>
  )
}

export default EditValidFromAndToDates
