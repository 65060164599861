import React from 'react'

const IconTrash = props => (
  <svg width='1em' height='1em' viewBox='0 0 16 20' {...props}>
    <defs>
      <path
        d='M12.4 17C12.4 17.2 12.2 17.4 12 17.4 11.8 17.4 11.6 17.2 11.6 17L11.6 8.3C11.6 8 11.8 7.8 12 7.8 12.2 7.8 12.4 8 12.4 8.3L12.4 17ZM8.4 17C8.4 17.2 8.2 17.4 8 17.4 7.8 17.4 7.6 17.2 7.6 17L7.6 8.3C7.6 8 7.8 7.8 8 7.8 8.2 7.8 8.4 8 8.4 8.3L8.4 17ZM4.4 17C4.4 17.2 4.2 17.4 4 17.4 3.8 17.4 3.6 17.2 3.6 17L3.6 8.3C3.6 8 3.8 7.8 4 7.8 4.2 7.8 4.4 8 4.4 8.3L4.4 17ZM14.7 5.2C14.9 5.2 15.1 5.4 15.1 5.7L15.1 17.8C15.1 19 14.1 20 12.9 20L3.1 20C1.9 20 0.9 19 0.9 17.8L0.9 5.7C0.9 5.4 1.1 5.2 1.3 5.2L14.7 5.2ZM15.6 1.7C15.8 1.7 16 1.9 16 2.2L16 3.9C16 4.2 15.8 4.3 15.6 4.3L0.4 4.3C0.2 4.3 0 4.2 0 3.9L0 2.2C0 1.9 0.2 1.7 0.4 1.7L0.9 1.7C1.1 0.7 2 0 3.1 0L12.9 0C14 0 14.9 0.7 15.1 1.7L15.6 1.7Z'
        id='trash_prefix__a'
        fill='grey'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g>
        <mask id='trash_prefix__b'>
          <use xlinkHref='#trash_prefix__a' />
        </mask>
        <g mask='url(#trash_prefix__b)' fill={props.color || '#0F003F'}>
          <path d='M-2 0h21v21H-2z' />
        </g>
      </g>
    </g>
  </svg>
)

export default IconTrash
