import React from 'react'
import { Link } from 'react-router-dom'
import {
  BindingPeriod,
  CustomerPresentation,
  PaymentPlan,
  PromotionDetails,
  PromotionTriggers,
  PTHours,
  SalesPeriod,
  SignupFee,
  TrialDays
} from './components'
import ReferralRewards from './components/ReferralRewards'
import CorporateDetails from './components/CorporateDetails'
import CorporateReports from './components/CorporateReports'
import AppContext from 'AppContext'

const BreadCrumbs = ({promotion, match = 'k'}) =>
  <span className='breadcrumbs'>
    <Link to={`/operators/${match.params.operatorID}/promotions/list`}>
      Promotions
    </Link> >&nbsp;
    <Link to={`/operators/${match.params.operatorID}/promotions/list/${promotion.promotion_type}`}>
      { promotionName(promotion) }
    </Link>
  </span>

const promotionName = ({promotion_type: type}) =>
  `${type.charAt(0).toUpperCase() + type.slice(1)} promotion`

const PromotionView = ({promotion, match}) => {
  const operator = React.useContext(AppContext).operator

  if (!promotion) return null

  return (
    <div className='content-wrapper'>
      <h1 className={'with-breadcrumbs'}>
        <BreadCrumbs promotion={promotion} match={match} />
        <div className='truncate' style={{ width: '96%' }}>
          {promotion.name}
        </div>
      </h1>
      <div className='box-wrapper promotion'>
        <PromotionDetails promotion={promotion} />
        <div>
          <BindingPeriod promotion={promotion} />
          <TrialDays promotion={promotion} />
        </div>
        <PromotionTriggers promotion={promotion} />
        <PTHours promotion={promotion} />
        <SalesPeriod promotion={promotion} />
        <SignupFee promotion={promotion} currency={operator.currency} />

        <div>
          <PaymentPlan
            schedule={promotion.monthly_payment_schedule}
            currency={operator.currency}
            promotion={promotion}
          />
          <ReferralRewards promotion={promotion} />
          <CorporateReports promotion={promotion} />
          <CorporateDetails promotion={promotion} />
        </div>
        <CustomerPresentation promotion={promotion} />
      </div>
    </div>
  )
}

export default PromotionView
