import React from 'react'
import { Link } from 'react-router-dom'
import AuthAPI from 'services/api/auth'
import { Error, Input } from './index'

const Success = () => {
  return (
    <div className={'info'}>
      <h1>Password updated</h1>
      <p>
        Your password has been updated
      </p>
      <Link style={{ float: 'right' }} to={'/'}>
        Log in
      </Link>
    </div>
  )
}


const UpdatePassword = ({ location }) => {
  const [success, setSuccess] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [password, setPassword] = React.useState(null)

  const query = new URLSearchParams(location.search)
  const operator = query.get('operator')
  const token = query.get('token')

  const onChange = (e) => {
    setPassword(e.target.value)
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const args = {
      operator_id: operator,
      token: token,
      password: password,
    }

    AuthAPI.updatePassword(args, setSuccess, () => setError('Invalid or expired token'))
  }

  if (success) return <Success />

  return(
    <form onSubmit={onSubmit}>
      <h1>Create a new password</h1>
      <Input name={'password'} onChange={onChange} />

      <Error error={error} />

      <Link style={{ float: 'right' }} to={'/'}>
        Back to login
      </Link>

      <button
        type='submit'
        className='btn'
      >
        Submit
      </button>
    </form>
  )
}

export default UpdatePassword
