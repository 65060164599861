import React from 'react'

import humanize from './humanize'
import ErrorMessage from './ErrorMessage'
import { useFormContext } from "react-hook-form"

const CheckboxInput = ({ label, name, value, required = false, checked = false }) => {
  const { register, errors } = useFormContext()

  return (
    <label className='checkbox-container'>{ label ? label : humanize(value) }
      <input
        type = "checkbox"
        className = { errors[name] ? 'error' : '' }
        name = { name }
        value = { value }
        defaultChecked={ checked }
        ref={
          register({ required: required })
        }
      />
      <span className='checkmark' />
      <ErrorMessage name={name} errors={errors} />
    </label>
  )
}

export default CheckboxInput
