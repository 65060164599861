import React from 'react'
import AppContext from 'AppContext'

const PromotionsV2 = (props) => {
  let env = "staging"
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = "production";
  }

  const token = window.localStorage.getItem('auth-token')

  const { operator: {id, currency, timezone} } = React.useContext(AppContext)

  // We may have to wait for AppContext to be available
  if(!id) {
    return (
      <div className='index sales-promotions'>
      </div>
    )
  }

  // Must leave empty h1 there for element below not to be cropped...
  return (
    <div className='index sales-promotions'>
    { /* eslint-disable-next-line */ }
    <h1></h1>

    <cl-promotions
      apihost={`https://memberships.${env}.credlock.net`}
      mountPath={`/operators/${id}/promotions-v2`}
      operatorid={id}
      token={token}
      timezone={timezone}
      currency={currency}
    >
    </cl-promotions>
  </div>
  )
}

export default PromotionsV2

