import React from 'react'
import { StatusBadge } from 'components/Badges'
import { dateToLocale } from 'components/Helpers'
import Amount from 'components/Helpers/Amount'

const refundClass = (refund) =>
  isDeclined(refund) ? 'stopped' : 'refund'

const refundStatus = (refund) =>
  isDeclined(refund) ? 'refund declined' : 'refund'

const isDeclined = (refund) =>
  refund.status !== 'accepted'

const Refund = ({ refund }) =>
  <span className='refund-row'>
    <StatusBadge
      status={refundStatus(refund)}
      cssStatus={refundClass(refund)}
    />
    <span>{dateToLocale(refund.created_at)}</span>
    <Amount
      className='amount'
      amount={refund.amount}
      currency={refund.currency}
    />
    <span className='comment hidden phone'>
      { refund.comment }
    </span>
  </span>

const Refunds = ({ payments }) => {
  if (payments === undefined) {
    return null
  }

  const paymentsWithRefunds = payments.filter(p => p.refunds.length > 0)
  if (!paymentsWithRefunds.length > 0) return ''

  const refunds = paymentsWithRefunds.reduce((acc, cur) => {
    cur.refunds.forEach(r => {
      acc.push(Object.assign({ currency: cur.currency }, r))
    })
    return acc
  }, [])

  const style = {
    marginTop: '-10px',
    marginBottom: '0px',
    height: `${refunds.length * 30}px`
  }

  return (
    <p className='invoice-refunds hidden phone' style={style}>
      <span style={{ position: 'absolute' }}>
        { refunds.map((r) => {
          return <Refund key={r.id} refund={r} />
        })}
      </span>
    </p>
  )
}

export default Refunds
