const url = (match) => {
  if (!match) return
  if (!match.url) return
  const url = match.url
  let arr = url.split('/')
  arr.pop() // remove "/list" from url
  return arr.join('/')
}

export default url
