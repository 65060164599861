import React, { Component } from 'react'
import PromotionsAPI from 'services/api/promotions'
import AppContext from 'AppContext'

const withPromotion = (WrappedComponent) => {
  return class extends Component {
    static contextType = AppContext
    constructor (props) {
      super(props)

      this.state = {
        promotion: null
      }
    }

    fetchPromotion () {
      this.setState({ loading: true })

      const { operatorID, promotionID } = this.props.match.params

      PromotionsAPI.get(operatorID, promotionID, (res) => {
        this.setState({ promotion: res })
        this.setState({ loading: false })
      })
    }

    componentDidMount () {
      if (!this.props.match) return
      this.fetchPromotion()
    }

    render () {
      return (
        <WrappedComponent
          promotion={this.state.promotion}
          match={this.props.match}
          timezone={this.context.operator.timezone}
        />
      )
    }
  }
}

export default withPromotion
