import React from 'react'

const CurrentVsPreviousPeriod = ({ header, currentValue, percentageChange, footer, isGood }) => {
  const classNamesFor = percentageChange => {
    return `percentage ${isGood(percentageChange) ? 'good' : 'bad'}`
  }

  return (
    <div className='current-vs-previous-period'>
      <p className='period-header'>{ header }</p>
      <p className='absolute-number'>{ currentValue }</p>
      <p className={classNamesFor(percentageChange)}>
        { format(percentageChange) }
      </p>
      <p className='period-footer'>{ footer }</p>
    </div>
  )
}

const round = n => Math.round(n * 100) / 100

const format = percentage => `${percentage >= 0 ? '+' : ''}${round(percentage)}%`

export default CurrentVsPreviousPeriod
