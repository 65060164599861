import React from 'react'
import { ActionButton } from 'components/Buttons'
import { DetailBox } from 'components/Boxes'
import Amount from 'components/Helpers/Amount'

const SignupFee = ({ promotion, currency }) => {
  const value = <Amount amount={promotion.signup_fee} currency={currency} />

  const items = [
    { label: 'Sign-up fee', value: value }
  ]

  return (
    <DetailBox header='Sign-up Fee' items={items}>
      <ActionButton
        txt='Edit sign-up fee'
        icon='edit white'
        link={`/operators/${promotion.operator_id}/promotions/${promotion.id}/signup-fee/edit`}
      />
    </DetailBox>
  )
}

export default SignupFee
