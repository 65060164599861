import React from 'react'

const SyncUserKeys = ({operatorid, membershipid}) => {
  let env = "staging"
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = "production";
  }
  const token = window.localStorage.getItem('auth-token')

  return (
    <div className='content-box synckeys'>
      <cl-sync-user-keys
        mountPath={`/operators/${operatorid}/members`}
        apiUrl={`https://access-control.${env}.credlock.net/api`}
        token={token}
        ownerid={membershipid}
        >
      </cl-sync-user-keys>
    </div>
  )
}

export default SyncUserKeys
