const API = require('./api')

const search = (operatorID, state, success) => {
  const url = 'promotions'
  API.fetch(url, success, state)
}

const get = (operatorID, promotionID, success) => {
  const url = `promotions/${promotionID}`
  API.fetch(url, success)
}

const create = (args, operatorId, success, error) => {
  let url = process.env.REACT_APP_SERVER + '/promotions'
  API.post(url, success, args, error)
}

const update = (args, success, error) => {
  let url = process.env.REACT_APP_SERVER + '/promotions'
  API.patch(url, success, args, error)
}

module.exports = {
  search,
  get,
  create,
  update,
}
