import React from 'react'
import Icon from 'components/Icons'
import { dateToLocale } from 'components/Helpers'
import { Badge } from 'components/Badges'
import { DetailBox } from 'components/Boxes'

const getDeclinedActionCode = (payments) => {
  if (!payments) return

  const payment = [payments.length-1]
  if (!payment || !payment.provider_details) return

  return payment.provider_details.action_code || payment.provider_details.reason_code || payment.provider_details.error_code
}

const CreditCardIcon = ({pm}) => {
  if (!pm.brand) return null

  return (
    <span>
    { pm.brand }
      &nbsp;
      <Icon
        type={pm.brand.toLocaleLowerCase()}
        style={{
          marginLeft: '4px',
          fontSize: '20px',
          marginBottom: '-5px'
        }}
      />
    </span>
  )
}

const LastInvoice = ({ invoice }) => {
  if (!invoice) return null

  const declinedCode = getDeclinedActionCode(invoice.payments)
  const badgeText = declinedCode ? `${invoice.status.toUpperCase()} ${declinedCode}` : invoice.status.toUpperCase()

  return (
    <span>
      { dateToLocale(invoice.invoice_date) }
      &nbsp;&nbsp;&nbsp;
      <Badge
        txt={badgeText}
        className={`status ${invoice.status}`}
      />
    </span>
  )
}

const CreditCard = ({ creditCard, last_invoice }) => {
  const expirationDate =
    creditCard.expire
      ? creditCard.expire.slice(2, 4) + '/20' + creditCard.expire.slice(0, 2)
      : null

  const items = [
    { label: 'Type', value: <CreditCardIcon pm={creditCard} /> },
    { label: 'Credit card number', value: creditCard.details },
    { label: 'Expiration', value: expirationDate },
    { label: 'Last invoice', value: <LastInvoice invoice={last_invoice} /> }
  ]

  return (
    <DetailBox header='Credit Card' items={items} />
  )
}

const PaymentMethod = ({ current_payment_method, last_invoice }) => {
  if (!current_payment_method) return (<p>Loading</p>)

  if (current_payment_method.type === 'credit_card') {
    return (
      <CreditCard
        creditCard={current_payment_method}
        last_invoice={last_invoice}
      />
    )
  }

  return (
    <DetailBox header={'Payment Method'} />
  )
}

export default PaymentMethod
