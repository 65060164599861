import React, { useState } from 'react'
import Report from './components/Report'
import ReportPeriod from './components/ReportPeriod'
import formatYearMonth from './components/helpers/formatYearMonth'
import useReport from './components/helpers/useReport'

const ActivationAndChurnHistory = ({ location_id = '' }) => {
  const [reportArgs, setReportArgs] = useState({
    ...ReportPeriod.lastTwelveMonths(),
    location_id
  })

  const setReportPeriod = period => setReportArgs({...period(), location_id})

  let { data: churn } = useReport('dashboard-churn-history', reportArgs)
  let { data: activations, meta: activationsMeta } =
    useReport('dashboard-activations-history', reportArgs)

  if (!churn || !activations) {
    return (
      // We set up an empty graph to prevent page to "flicker"
      <div className={'report-wrapper filled'} style={{ height: '436px' }}>
      </div>
    )
  }

  return <Report
    chartData={toChartJS(activations, churn)}
    onReportPeriodChange={setReportPeriod}
    excelData={{ meta: activationsMeta, data: activations }} // TODO: Include churn data
    style={{ isFilled: true, withPadding: false }}
  />
}

const toChartJS = (activations, churn) => {
  return {
    type: 'line',
    data: {
      labels: churn.map(churnOnDate => churnOnDate.yearmonth),
      xLabels: activations.map(activationsOnDate => formatYearMonth(activationsOnDate.yearmonth)),
      datasets: [
        {
          label: 'Churn',
          data: churn.map(churnOnDate => churnOnDate.cancellations),
          lineColor: 'rgba(239, 66, 102, 1)',
          fillGradientStartColor: 'rgba(239, 66, 102, 0.5)',
          fillGradientStopColor: 'rgba(125, 96, 255, 0)'
        },
        {
          label: 'Activations',
          data: activations.map(activationsOnDate => activationsOnDate.activations),
          lineColor: 'rgba(125, 96, 255, 1)',
          fillGradientStartColor: 'rgba(125, 96, 255, 0.5)',
          fillGradientStopColor: '#24136d00'
        }
      ]
    },
    options: {
      scales: {
        xAxes: [ {
          ticks: {
            fontColor: 'white'
          }
        }],
        yAxes: [ {
          ticks: {
            fontColor: 'rgba(142, 142, 142, 0.8)'
          }
        }]
      }
    }
  }
}

export default ActivationAndChurnHistory
