import React from 'react'

const KeyActivation = ({operatorid, ownerid}) => {
  let env = "staging"
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = "production";
  }
  const token = window.localStorage.getItem('auth-token')

  return (
    <cl-user-key
      mountPath={`/operators/${operatorid}/free-passes/${ownerid}`}
      apiUrl={`https://access-control.${env}.credlock.net/api`}
      operatorid={operatorid}
      token={token}
      ownerid={ownerid}
      >
    </cl-user-key>
  )
}

export default KeyActivation
