import React, { useRef } from 'react'
import ReportPeriod from './ReportPeriod'
import toExcel from './helpers/toExcel'
import toPNG from './helpers/toPNG'
import styleChart from './helpers/styleChart'
import ExportMenu from './ExportMenu'
import ReportPeriodSelect from './ReportPeriodSelect'
import Chart from './Chart'

/*
 * Renders a report as a card with title, (optional) report period selector,
 * export menu (Excel/PNG), chart and (optional) data table.
 */
const Report = ({title, chartData, tableData, excelData, onReportPeriodChange, style}) => {
  /*
   * Capture reference to canvas.
   *
   * The canvas is required to style charts with gradients (created
   * using the context of the canvas) and to do PNG export (implemented
   * by grabbing canvas content).
   *
   * Chart.js (and its react wrapping lib) provides a reference to the
   * canvas as the 1st argument if a Function is supplied as data.
   */
  let canvasRef = useRef(null)
  const captureCanvas = canvas => {
    canvasRef.current = canvas // PDF

    return styleChart(chartData, canvasRef).data // style with gradients
  }

  return (
    <Wrapper {...style}>
      <Header
        title={title}
        onReportPeriodChange={onReportPeriodChange}
        onExcelExport={toExcel(excelData)}
        onPNGExport={toPNG(canvasRef)}
      />
      <Chart type={chartData.type} data={captureCanvas} options={chartData.options} />
      { tableData }
    </Wrapper>
  )
}

const Header = ({title, onReportPeriodChange, onPNGExport, onExcelExport}) => {
  const onReportPeriodChangeWrapper = event => {
    onReportPeriodChange(ReportPeriod[event.value])
  }

  return (
    <header>
      <h2>{ title }</h2>
      <div className='controls'>
        { onReportPeriodChange != null &&
        <ReportPeriodSelect onChange={onReportPeriodChangeWrapper} />
        }
        <ExportMenu toExcel={onExcelExport} toPNG={onPNGExport} />
      </div>
    </header>
  )
}

const Wrapper = ({ withPadding = true, isFilled = false, children }) => {
  let classNames = ['report-wrapper']
  if (withPadding) classNames = [...classNames, 'with-padding']
  if (isFilled) classNames = [...classNames, 'filled']

  return (
    <div className={classNames.join(' ')} >
      { children }
    </div>
  )
}

export default Report
