import React, { useEffect, useState } from 'react'
import ErrorMessage from './ErrorMessage'
import { useFormContext } from "react-hook-form"

const Switch = ({ label, name, checked }) => {
  const { register, errors } = useFormContext()

  const [value, setValue] = useState(false);

  const setChecked = (checked) => {
    if (checked === undefined) { return }
    checked = !!checked
    if (checked) {
      setValue(true)
    } else {
      setValue(false)
    }
  }

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return (
    <span>
      <span className='switch-label'>
        {label}
      </span>
      <label style={{opacity: 1}}>
        <span className='switch'>
          <input
            type='checkbox'
            name={ name }
            checked={ value }
            onChange={ event => setChecked(event.target.checked) }
            value={ value }
            ref={ register }
          />
          <span className='slider round' />
        </span>
        <div>
          <ErrorMessage name={name} errors={errors} />
        </div>
      </label>
    </span>
  )
}

export default Switch
