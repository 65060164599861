import React from 'react'
import {
  ActivationStatus,
  ChurnStatus,
  TrialStatus,
} from 'components/Reports'


/* eslint-disable camelcase */
const Status = () => {
  return (
    <div className='report-box-wrapper'>
      <ActivationStatus />
      <TrialStatus />
      <ChurnStatus />
    </div>
  )
}

export default Status
