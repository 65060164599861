import React from 'react'
import Modal from 'components/Modal'

const ProcessingModal = () =>
  <Modal className='promotion'>
    <h1 className='modal-header'>Moving member...</h1>
    <div className='modal-content' style={{ textAlign: 'center' }}>
      <p>Wil be done shortly</p>
    </div>
  </Modal>

export default ProcessingModal
