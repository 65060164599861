import React from 'react'
import { StatusBadge } from '../Badges'
import CheckBox from '../CheckBoxes'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import LinkColumn from './components/LinkColumn'
import { DEMO } from 'Constants'

const responsiveClass = (hideFor) => {
  let responsiveClass = ' hidden'

  responsiveClass += hideFor.phone ? ' phone' : ''
  responsiveClass += hideFor.tablet ? ' tablet' : ''
  responsiveClass += hideFor.tablet_portrait ? ' small_devices' : ''
  return responsiveClass
}

const addExtraInfoToColumn = (column) => {
  if (column.hide_for) {
    column.headerClassName = column.className += responsiveClass(column.hide_for)
  }

  if (column.accessor === 'status' && !column.Cell) {
    column.Cell = props => <StatusBadge status={props.value || 'active'} />
    // column.minWidth = 75
  }

  if (column.accessor === 'selector') {
    column.Cell = () => <CheckBox checked={false} />
    column.Header = () => <CheckBox checked={false} />
    column.minWidth = 32
  }
}

const ReactTableColumns = (columns, url = null, fields = null, changeFields = null) => {
  let tableColumns = columns.map((oldColumn) => {
    let column = Object.assign({}, oldColumn)
    addExtraInfoToColumn(column)
    return column
  })

  url && tableColumns.push({
    Header: () => {
      if (!fields) return ''

      return (
        <select multiple onChange={changeFields} defaultValue={fields.filter(f => f.show).map(f => f.id)}>
          {
            fields.map(f =>
              <option key={f.id} value={f.id}>{f.label}</option>
            )
          }
        </select>
      )
    },
    filterable: false,
    sortable: false,
    accessor: null,
    maxWidth: 110,
    Cell: ({ original }) => <LinkColumn url={url} obj={original} id={original.uuid || original.id} />
  })

  return tableColumns
}

const Table = (props) => {
  const {
    columns, data, config, url, defaultSorted, onFetchData,
    page, pages, serverSidePagination, loading, filterable, defaultFiltered,
    showPagination, style, defaultPageSize = 10, SubComponent, sortable, fields,
    filtered, onFilteredChange
  } = props

  // Unless minRows is provided we use the defaultPageSize while loading
  // to prevent flickering, and 1 unless loading.
  const minRows = config && config.minRows ? config.minRows : loading ? data && data.length > 0 && data.length < defaultPageSize ? data.length : defaultPageSize : 1

  // Just freezing to ensure that we don't mutate the original array
  columns.map(column => Object.freeze(column))

  return (
    <ReactTable
      {...props}
      getTdProps={() =>
        ({
          // This makes the content centered vertically in the column
          style:
            {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }
        })
      }
      style={style}
      data={data}
      page={page}
      pages={pages}
      manual={DEMO ? false : serverSidePagination}
      columns={ReactTableColumns(columns, url, fields)}
      sortable={sortable}
      loading={loading}
      minRows={minRows}
      resizable={false}
      defaultPageSize={defaultPageSize}
      defaultSorted={defaultSorted}
      onFetchData={onFetchData} // Request new data when things change
      showPageJump={false}
      showPageSizeOptions={false}
      filterable={filterable}
      defaultFiltered={defaultFiltered}
      showPagination={showPagination}
      SubComponent={SubComponent}
      filtered={filtered}
      onFilteredChange={onFilteredChange}
    />
  )
}

export default Table
