import React from 'react'
import Amount from 'components/Helpers/Amount'
import SalesPeriod from '../../components/SalesPeriod'
import {
  monthsWithDiscount,
  PromotionAmountOrPercentage
} from '../../../components/paymentSchedule'
import {
  promotionReferrerRewardsToText
} from '../../../components/referrerRewards'
import AppContext from 'AppContext'
import Current, { Grid2, Grid4, Section } from 'components/Boxes/Current'

const MonthsWithDiscounts = ({ promotion, currency }) =>
  monthsWithDiscount(promotion.monthly_payment_schedule)
    .map(m =>
      <Section
        key={m.begin_month_index}
        header={`Month ${m.begin_month_index}`}
      >
        <PromotionAmountOrPercentage
          scheduleEntry={m}
          currency={currency}
        />
      </Section>
    )

const CurrentReferral = ({promotion, baseURL}) => {
  const { currency } = React.useContext(AppContext).operator

  // Just ignore if the component hasn't received a promotion yet
  if (!promotion) return null

  const url = `${baseURL}/${promotion.id}`

  return (
    <Current
      obj={promotion}
      url={url}
      header='CURRENT REFERRAL PROMOTION'
      info='You can only have 1 current referral promotion at the time'
    >
      <Grid2>
        <Section header='Sales period'>
          <SalesPeriod
            style={{ fontSize: '36px' }}
            promotion={promotion}
            time={false}
          />
        </Section>
        <Section header='Referrer rewards'>
          { promotionReferrerRewardsToText(promotion) }
        </Section>
      </Grid2>
      <Grid4>
        <Section header='Signup fee'>
          <Amount currency={currency} amount={promotion.signup_fee || 0} />
        </Section>
        <MonthsWithDiscounts promotion={promotion} currency={currency} />
      </Grid4>
    </Current>
  )
}

export default CurrentReferral
