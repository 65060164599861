import React from 'react'
import Amount, { toHumanReadable } from 'components/Helpers/Amount'

const compare = (last, compare) => {
  const normalize = object => {
    return JSON.stringify({
      fee_as_amount: object.fee_as_amount,
      fee_as_percentage: object.fee_as_percentage
    })
  }
  if (!last) {
    return false
  }

  return normalize(last) === normalize(compare)
}

const groupByPrice = content => {
  let last = null
  return content.reduce((acc, cur) => {
    if (compare(last, cur)) {
      const index = acc.length - 1
      acc[index] = [ ...acc[index], cur ]
    } else {
      acc.push([cur])
    }

    last = cur
    return acc
  }, [])
}

const monthString = (scheduleGroup) => {
  const startIndex = scheduleGroup[0].begin_month_index
  const endIndex = scheduleGroup[scheduleGroup.length - 1].end_month_index

  let monthString = ''

  if (startIndex === endIndex) {
    monthString = `${startIndex}`
  } else if (endIndex === null) {
    monthString = `${startIndex}->`
  } else {
    monthString = `${startIndex}-${endIndex}`
  }

  return monthString
}

const isFeeAsAmount = (scheduleGroup) =>
  scheduleGroup[0].fee_as_amount != null

const percentageOrAmount = (scheduleGroup, currency, asText) => {
  if (isFeeAsAmount(scheduleGroup)) {
    if (asText) return toHumanReadable(scheduleGroup[0].fee_as_amount, currency)
    
    return (
      <Amount
        amount={scheduleGroup[0].fee_as_amount}
        currency={currency}
      />
    )
  } else {
    return scheduleGroup[0].fee_as_percentage + '% of Monthly fee'
  }
}

const paymentPlan = (schedule, currency, asText = false) =>
  groupByPrice(schedule).map(scheduleGroup => {
    return {
      label: monthString(scheduleGroup),
      value: percentageOrAmount(scheduleGroup, currency, asText)
    }
  })

const PaymentPlan = ({ schedule, currency }) =>
  <span>
    {
      paymentPlan(schedule, currency).map(i =>
        <span
          key={i.label}
          style={{
            display: 'grid',
            gridTemplateColumns: '40px 1fr'
          }}
        >
          <span style={{ textAlign: 'right', paddingRight: '3px' }}>
            { i.label }:
          </span>
          <span>
            { i.value }
          </span>
        </span>
      )
    }
  </span>

const paymentPlanAsText = (schedule, currency) =>
  paymentPlan(schedule, currency, true).map(pm => `${pm.label} ${pm.value}`).join('\n')

export default PaymentPlan

export {
  paymentPlanAsText
}
