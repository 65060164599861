import React from 'react'

const LocationIcon = props => (
  <svg width='1em' height='1em' viewBox='0 0 15 21' {...props}>
    <path
      d='M7.5 11.375c-2.19 0-3.97-1.768-3.97-3.938S5.31 3.5 7.5 3.5c2.19 0 3.97 1.767 3.97 3.938 0 2.17-1.78 3.937-3.97 3.937M7.5 0C3.364 0 0 3.336 0 7.438c0 3.966 6.857 13.005 7.149 13.389A.44.44 0 0 0 7.5 21a.44.44 0 0 0 .351-.173C8.143 20.443 15 11.404 15 7.437 15 3.335 11.636 0 7.5 0'
    />
  </svg>
)

export default LocationIcon
