import React from 'react'
import AppContext from 'AppContext'

const ReportList = () => {
  const { operator: { id: operatorId } } = React.useContext(AppContext)

  return (
    <div className='index'>
      <h1>Reports</h1>
      <div className='content-box'>
        <reports-list report-base-path={`/operators/${operatorId}/reports`}></reports-list>
      </div>
    </div>
  )
}

export default ReportList
