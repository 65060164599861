import React from 'react'
import PromotionTable from '../components/PromotionTable'

const SalesPromotionListView = (props) => {
  if (!props.operator.timezone) return null

  return (
    <div className='content-box promotions'>
      <PromotionTable {...props} />
    </div>
  )
}

export default SalesPromotionListView
