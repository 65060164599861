import { useState, useEffect } from 'react'

import ReportsAPI from 'services/api/reports'

/*
 * Custom hook that facilitates fetching reports
 */
const useReport = (name, args) => {
  const [queryResult, setQueryResult] = useState(null)

  useEffect(() => {
    ReportsAPI.fetchReport(
      name,
      args,
      result => setQueryResult(result)
    )
  }, [name, args])

  // return emtpy object on no query result to allow destructuring at the client side
  return queryResult || {}
}

export default useReport
