import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import API from 'services/api/promotions'
import AppContext from 'AppContext'
import {
  Form,
  FormLine,
  TextInput,
  TextAreaInput,
  DateInput,
  Submit,
  FormGroup,
  FormButtons,
  LocationSelect,
} from 'components/Forms'
import { MonthlyPaymentSchedule } from '../components'
import { sanitizePaymentSchedule } from '../components/paymentSchedule'

const NewSalesPromotion = () => {
  const { operator: { id: operatorId } } = React.useContext(AppContext)
  const [newPromotionId, setNewPromotionId] = useState(null)
  const [validationErrors, setValidationErrors] = useState({})

  const onSubmit = data => {
    API.create(
      {
        type: 'sales',
        ...data,
        signup_fee: toMoney(data.signup_fee),
        trial_days: toInteger(data.trial_days),
        pt_hours: toInteger(data.pt_hours),
        monthly_payment_schedule: sanitizePaymentSchedule(data.monthly_payment_schedule)
      },
      operatorId,
      successfulResult => setNewPromotionId(successfulResult.id),
      errorResult => setValidationErrors(errorResult)
    )
  }

  const viewPromotionUrl = (id, operatorId) => `/operators/${operatorId}/promotions/${id}`

  if (newPromotionId) return <Redirect to={viewPromotionUrl(newPromotionId, operatorId)} />

  const defaultValues = {
    sales_period_begins_at: new Date(),
    trial_days: 0,
    signup_fee: null,
    pt_hours: 0,
    monthly_payment_schedule: Array(16).fill({ fee_as_percentage: 100, fee_as_amount: null }),
  }


  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        New sales promotion
      </h1>
      <div className='form'>
        <Form onSubmit={onSubmit} defaultValues={defaultValues} validationErrors={validationErrors}>
          <FormGroup header='Details'>
            <TextInput name='name'/>
          </FormGroup>
          <FormGroup header='Trigger locations'>
            <LocationSelect name='trigger_location_ids' />
          </FormGroup>
          <FormGroup header='Sales period'>
            <FormLine>
              <DateInput name='sales_period_begins_at' label='Begin date'/>
              <DateInput name='sales_period_ends_at' label='End date'/>
            </FormLine>
          </FormGroup>
          <FormGroup header='Billing'>
            <TextInput name='trial_days'/>
            <TextInput name='signup_fee'/>
            <MonthlyPaymentSchedule />
          </FormGroup>
          <FormGroup header='PT'>
            <TextInput name='pt_hours' label='PT hours'/>
          </FormGroup>
          <FormGroup header='Presentation'>
            <TextAreaInput name='pre_signup_presentation' style={{width: '30em', height: '15em'}}/>
            <TextAreaInput name='post_signup_presentation'style={{width: '30em', height: '15em'}}/>
          </FormGroup>
          <FormButtons>
            <Submit label='Add sales promotion' />
          </FormButtons>
        </Form>
      </div>
    </div>
  )
}

// Form value may be empty string. Ensure null is submitted to keep backend happy
const toInteger = value => (value || value === 0) ? parseInt(value) : null
const toMoney = value => (value || value === 0) ? parseFloat(value)*100 : null

export default NewSalesPromotion
