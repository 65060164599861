import React from 'react'

import humanize from './humanize'

const ErrorMessage = ({ name, errors }) => {
  if (!errors[name]) return null

  let msg = ''
  if (typeof errors[name] === 'string') {
    msg = errors[name]
  }
  if (typeof errors[name] === 'object') {
    if (Array.isArray(errors[name])) {
      msg = errors[name].join(". ")
    } else {
      msg = errors[name].message || errors[name].type
    }
  }

  return (
    <span className='error'>
      { humanize(msg) }
    </span>)
}

export default ErrorMessage
