import React from 'react'
import { ActionButton } from 'components/Buttons'
import { DetailBox } from 'components/Boxes'
import Amount from '../../../../components/Helpers/Amount'
import groupByPrice from '../components/groupPaymentScheduleByPrice'

const monthString = (scheduleGroup) => {
  const startIndex = scheduleGroup[0].begin_month_index
  const endIndex = scheduleGroup[scheduleGroup.length - 1].end_month_index

  let monthString = ''

  if (startIndex === endIndex) {
    monthString = `Month ${startIndex}`
  } else if (endIndex === null) {
    monthString = `Months ${startIndex}-->`
  } else {
    monthString = `Months ${startIndex}-${endIndex}`
  }

  return monthString
}

const isFeeAsAmount = (scheduleGroup) =>
  scheduleGroup[0].fee_as_amount != null

const percentageOrAmount = (scheduleGroup, currency) => {
  if (isFeeAsAmount(scheduleGroup)) {
    return (
      <Amount
        amount={scheduleGroup[0].fee_as_amount}
        currency={currency}
      />
    )
  } else {
    return scheduleGroup[0].fee_as_percentage + '% of Monthly fee'
  }
}

const items = (schedule, currency) =>
  groupByPrice(schedule).map(scheduleGroup => {
    return {
      label: monthString(scheduleGroup),
      value: percentageOrAmount(scheduleGroup, currency)
    }
  })

const PaymentPlan = ({ schedule, currency, promotion }) =>
  <DetailBox header='Payment plan' items={items(schedule, currency)}>
    <ActionButton
      txt='Edit payment plan'
      icon='edit white'
      link={`/operators/${promotion.operator_id}/promotions/${promotion.id}/payment-plan/edit`}
    />
  </DetailBox>

export default PaymentPlan
