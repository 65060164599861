import React from 'react'

const MaintenanceIcon = props => (
  <svg width='1em' height='1em' viewBox='0 0 21 21' {...props}>
    <path
      d='M7.075 11.012c.084 0 .166-.009.247-.024l2.758 2.835c-.008.059-.018.118-.018.178a.435.435 0 0 1-.131.31l-5.494 5.93A2.54 2.54 0 0 1 2.625 21c-.674 0-1.33-.273-1.803-.746l-.082-.085A2.559 2.559 0 0 1 0 18.375c0-.675.272-1.33.746-1.804l6.036-5.446a.437.437 0 0 1 .293-.113zM17.681 5.25L13.329 9.6l-1.956-1.903 4.377-4.378V2.187c0-.165.093-.317.241-.391l3.5-1.75a.436.436 0 0 1 .505.082l.875.875a.435.435 0 0 1 .082.505l-1.75 3.5a.438.438 0 0 1-.39.242H17.68zm2.643 12.374a2.28 2.28 0 0 1 .675 1.624c0 .966-.785 1.751-1.75 1.751a2.274 2.274 0 0 1-1.62-.672L6.548 8.937a1.31 1.31 0 0 0-1.278-.351c-.416.111-.7.164-.895.164-1.2 0-2.36-.5-3.184-1.375A4.339 4.339 0 0 1 .008 4.111a.441.441 0 0 1 .228-.358.472.472 0 0 1 .452 0L3.08 5.188a2.379 2.379 0 0 0 1.283-1.886L1.69 1.698a.44.44 0 0 1-.212-.345.443.443 0 0 1 .161-.37A4.328 4.328 0 0 1 4.283.001c1.058-.013 2.132.424 2.97 1.26 1.071 1.071 1.504 2.482 1.252 4.077-.067.428.074.86.377 1.156l11.442 11.13z'
      id='maintenance_a'
    />
  </svg>
)

export default MaintenanceIcon
