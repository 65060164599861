import React, { useState } from 'react'
import Icon from 'components/Icons'

const DropDownContent = ({ isOpen, children }) => {
  if (!isOpen) return null

  return (
    <div className='dropdown-content'>
      { children }
    </div>
  )
}

const DropDown = (props) => {
  const [isOpen, toggleIsOpen] = useState(false)

  return (
    <div className='dropdown'>
      <button onClick={() => toggleIsOpen(!isOpen)} className='dropdown-toggle'>
          New free pass&nbsp;<Icon type={'pluss'} color={'white'} />
      </button>
      <DropDownContent isOpen={isOpen} children={props.children} />
    </div>
  )
}

export default DropDown
