import React from 'react'
import { baseUrl, prettyViewRoles } from './index'
import { filterAndLinkArrowColumn } from 'components/Table'
import { Link } from 'react-router-dom'

const roleFilterMethod = (filter, row) => {
  if (filter.value === 'all') return true

  return row[filter.id].includes(filter.value)
}

const LinkValue = ({ operator, original, value }) => {
  const url = `${baseUrl(operator)}/${original.id}`

  return (
    <Link to={{ pathname: url, state: { original } }}>
      {value}
    </Link>
  )
}

const SelectorWrapper = ({ onChange, filter, children }) =>
  <select
    onChange={event => onChange(event.target.value)}
    style={{ width: "100%" }}
    value={filter ? filter.value : 'all'}
  >
    { children }
  </select>


const RoleSelector = ({ onChange, filter, roles }) =>
  <SelectorWrapper onChange={onChange} filter={filter}>
    <option value="all">All</option>
    { roles.map(r => <option key={r.id} value={r.name}>{r.name}</option>) }
  </SelectorWrapper>

const StatusSelector = ({ onChange, filter }) =>
  <SelectorWrapper onChange={onChange} filter={filter}>
    <option value={'all'}>All</option>
    <option value={'active'}>Active</option>
    <option value={'inactive'}>Inactive</option>
  </SelectorWrapper>

const defaultColumns = (setFilterable, roles, operator) => [
  {
    width: 150,
    Header: 'First name',
    id: 'first_name',
    accessor: a => a.profile.first_name,
    show: true,
    Cell: props =>
      <LinkValue operator={operator} {...props} />
  },
  {
    width: 150,
    Header: 'Last name',
    id: 'last_name',
    accessor: a => a.profile.last_name,
    show: true,
    Cell: props =>
      <LinkValue operator={operator} {...props} />
  },
  {
    width: 150,
    Header: 'Status',
    accessor: 'status',
    show: true,
    Filter: ({ filter, onChange }) =>
      <StatusSelector onChange={onChange} filter={filter} />
  },
  {
    Header: 'Mobile prefix',
    accessor: 'profile.mobile.prefix',
    id: 'mobile_prefix',
    show: false
  },
  {
    Header: 'Mobile number',
    accessor: 'profile.mobile.number',
    id: 'mobile_number',
    show: false
  },
  {
    Header: 'E-mail',
    accessor: 'credentials.username',
    id: 'credentials.username',
    show: false
  },
  {
    Header: 'Roles',
    id: 'roles',
    accessor: a => a.roles,
    show: true,
    Cell: p => prettyViewRoles(p.original.roles),
    filterMethod: roleFilterMethod,
    Filter: ({ filter, onChange }) =>
      <RoleSelector onChange={onChange} filter={filter} roles={roles} />
  },
  filterAndLinkArrowColumn(setFilterable, 'administrators')
]

export default defaultColumns
