import React from 'react'

const IconVisa = props => (
  <svg width='1em' height='1em' viewBox='0 0 24 18' {...props}>
    <g fillRule='nonzero' fill='none'>
      <path fill='#E6E6E6' d='M0 3.782h24v9.913H0z' />
      <path
        d='M1.565 17.87h20.87c.864 0 1.565-.702 1.565-1.566v-2.609H0v2.609c0 .864.7 1.565 1.565 1.565z'
        fill='#E79800'
      />
      <path
        d='M22.435.13H1.565C.701.13 0 .83 0 1.695v2.609h24V1.695C24 .831 23.3.13 22.435.13zM9.515 11.504h1.362l.852-4.996h-1.362zM14.475 8.514c-.476-.231-.768-.386-.765-.62 0-.207.247-.43.78-.43.436-.006.788.083 1.142.25l.185-1.084a3.516 3.516 0 0 0-1.22-.21c-1.346 0-2.294.678-2.302 1.65-.008.717.676 1.118 1.193 1.357.53.245.709.401.706.62-.003.335-.423.488-.815.488-.604 0-.909-.094-1.457-.342l-.191 1.118c.318.14.906.26 1.517.267 1.432 0 2.36-.67 2.371-1.707.004-.568-.359-1-1.144-1.357zM19.308 6.512h-1.052c-.326 0-.57.09-.714.415l-2.022 4.578h1.43s.234-.615.287-.75l1.744.002c.041.175.166.748.166.748h1.264l-1.103-4.993zm-1.679 3.221c.056-.144.612-1.57.723-1.873.184.861.005.025.408 1.873h-1.131zM8.373 6.511L7.04 9.918l-.142-.692L6.42 6.93c-.083-.316-.322-.41-.618-.422H3.607l-.017.104a5.74 5.74 0 0 1 1.43.548l1.211 4.34 1.441-.002L9.816 6.51H8.373z'
        fill='#1A1876'
      />
    </g>
  </svg>
)

export default IconVisa
