import React, { Component } from 'react'
import { hasAccess } from 'components/Helpers'
import NoAccess from '../../NoAccess'
import AdminAPI from 'services/api/admins'
import AdminView from './AdminView'

const permission = 'admins.admin.get'

const adminInState = (location) =>
  location && location.state && location.state.obj

class Admin extends Component {
  constructor (props) {
    super(props)
    this.state = {
      admin: null
    }
  }

  componentDidMount () {
    if (!hasAccess(this.props.user, permission)) {
      return
    }

    if (adminInState(this.props.location)) {
      this.setState({ admin: this.props.location.state.obj })
      return
    }

    const { operatorID, adminUUID } = this.props.match.params

    AdminAPI.fetchAdmin(operatorID, adminUUID, (admin) => {
      this.setState({ admin: admin })
    })
  }

  render () {
    if (!this.state.admin) {
      return (
        <NoAccess />
      )
    }

    return (
      <AdminView
        operator={this.props.match.params.operatorID}
        admin={this.state.admin}
      />
    )
  }
}

export default Admin
