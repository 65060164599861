import React from 'react'

const HouseIcon = props => (
  <svg width='1em' height='1em' viewBox='0 0 21 20' {...props}>
    <path
      d='M19.973 10.129l-9.166-10a.428.428 0 0 0-.614 0l-9.166 10a.416.416 0 0 0 .307.698h2.083v8.75c0 .23.187.416.417.416H8c.23 0 .417-.186.417-.416v-6.25h4.166v6.25c0 .23.186.416.417.416h4.166c.23 0 .417-.186.417-.416v-8.75h2.083a.417.417 0 0 0 .307-.698'
      fill={props.color}
    />
  </svg>
)

export default HouseIcon
