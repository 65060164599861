import React from 'react'
import BreadCrumbs from 'components/BreadCrumbs'

/**
 * @component
 * @description A wrapper around the BreadCrumbs component that always
 * adds thee base link to "Distributors" as first element.
 * All other link elements must be provided in the list array in the
 * format of { path: '/link/', text: 'The text to display' }
 *
 * @example
 * <PaidPassBreadCrumbs
 *  operatorId={'65aa9029-87e0-43a2-98aa-8b360be4da2b'}
 *  list={[{ path: `/operators/${operatorId}/paid-passes/distributors/${id}` }]}
 * />
 *
 * NOTE: The paths must be complete
 */
const PaidPassBreadCrumbs = ({ list = [], operatorId }) => {
  const base = {
    path: `/operators/${operatorId}/paid-passes`,
    text: 'Distributors'
  }

  return (
    <BreadCrumbs
      list={[base].concat(list)}
    />
  )
}

export default PaidPassBreadCrumbs
