import React from 'react'
import { PrivateRoute } from 'components/WithAuth'
import { NavLink } from 'react-router-dom'
import SalesPromotionList from '../sales/SalesPromotionList'
import CorporatePromotionList from '../corporate/CorporatePromotionList'
import ReferralPromotionList from '../referral/ReferralPromotionList'

const checkActive = (match, location) => {
  const lastPart = location.pathname.split('/').pop()
  return lastPart === 'list' || lastPart === 'sales'
}

// Note passing all props into PrivateRoute might screw things up!!!
// Check this out or always pass props explicitly!
const Route = ({path, component, ...rest}) =>
  <PrivateRoute
    path={`${rest.match.url}${path}`}
    permission='memberships.promotion.list'
    component={component}
    user={rest.user}
    match={rest.match}
  />

const Navigation = ({match}) =>
  <ul>
    <li>
      <NavLink exact to={`${match.url}`} isActive={checkActive}>
        Sales
      </NavLink>
    </li>
    <li>
      <NavLink to={`${match.url}/corporate`}>
        Corporate
      </NavLink>
    </li>
    <li>
      <NavLink to={`${match.url}/referral`}>
        Referral
      </NavLink>
    </li>
  </ul>

const Routes = (props) => {
  const routeItems = [
    { path: '/sales', component: SalesPromotionList },
    { path: '/corporate', component: CorporatePromotionList },
    { path: '/referral', component: ReferralPromotionList }
  ]

  return (
    [
      <PrivateRoute
        key='index-sales'
        exact path={`${props.match.url}`}
        permission='memberships.promotion.list'
        component={SalesPromotionList}
        user={props.user}
        match={props.match}
      />,
      routeItems.map(r =>
        <Route
          key={r.path}
          path={r.path}
          component={r.component}
          {...props}
        />
      )
    ]
  )
}

const PromotionNavigation = (props) =>
  <div className='menu-bar'>
    <Navigation match={props.match} />
    <Routes {...props} />
  </div>

export default PromotionNavigation
