import React from 'react'
import Typography from './Typography'
import FormDemo from './FormDemo'
import BadgesDemo from './BadgesDemo'
import ButtonsDemo from './ButtonsDemo'

const StyleGuide = () =>
  <div style={{paddingTop: '70px', paddingLeft: '40px', paddingRight: '40px', backgroundColor: 'white'}}>
    <Typography />
    <FormDemo />
    <BadgesDemo />
    <ButtonsDemo />
  </div>

export default StyleGuide
