import { saveAs } from 'file-saver'

const toPNG = canvasRef => {
  return () => {
    let canvas = canvasRef.current

    canvas.toBlob(function (blob) {
      saveAs(blob, 'chart.png')
    })
  }
}

export default toPNG
