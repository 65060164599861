import React, { useState } from 'react'
import Report from './components/Report'
import ReportPeriod from './components/ReportPeriod'
import formatYearMonth from './components/helpers/formatYearMonth'
import useReport from './components/helpers/useReport'

const IBHistoryWithRollingAverage = ({ location_id = '' }) => {
  const [reportArgs, setReportArgs] = useState({ ...ReportPeriod.lastTwelveMonths(), location_id })

  const setReportPeriod = period => setReportArgs({...period(), location_id})

  let { data: rollingAverageIBs } = useReport('dashboard-avg-ib', reportArgs)
  let { data: IBHistory, meta: IBHistoryMeta } = useReport('dashboard-ib-history', reportArgs)

  if (!rollingAverageIBs || !IBHistory) return null

  return <Report
    title='membership growth'
    chartData={toChartJS(rollingAverageIBs, IBHistory)}
    excelData={{ meta: IBHistoryMeta, data: IBHistory }} // TODO: Include rolling avg ib
    onReportPeriodChange={setReportPeriod}
    style={{ isFilled: false, withPadding: true }}
  />
}

const toChartJS = (rollingAverageIBs, IBHistory) => {
  return {
    type: 'line',
    data: {
      labels: IBHistory.map(ib => ib.yearmonth),
      xLabels: IBHistory.map(ib => formatYearMonth(ib.yearmonth)),
      datasets: [
        {
          label: 'IB 12mth rolling avg',
          data: rollingAverageIBs.map(avgIB => avgIB.twelve_months_moving_avg),
          lineColor: 'rgba(239, 66, 102, 1)',
          fillGradientStartColor: 'rgba(239, 66, 102, 0.5)',
          fillGradientStopColor: 'rgba(239, 66, 102, 0)'
        },
        {
          label: 'IB',
          data: IBHistory.map(ib => ib.ib),
          lineColor: 'rgba(125, 96, 255, 1)',
          fillGradientStartColor: 'rgba(125, 96, 255, 0.5)',
          fillGradientStopColor: 'rgba(125, 96, 255, 0)'
        }
      ]
    }
  }
}

export default IBHistoryWithRollingAverage
