import React from 'react'

const IconSearch = props =>
  <svg width='1em' height='1em' viewBox='0 0 24 22' {...props}>
    <path
      d='M19.707,18.293 L14.314,12.9 C15.403,11.504 16,9.799 16,8 C16,5.863 15.167,3.854 13.656,2.344 C12.146,0.832 10.137,0 8,0 C5.863,0 3.854,0.832 2.344,2.344 C0.833,3.854 0,5.863 0,8 C0,10.137 0.833,12.146 2.344,13.656 C3.854,15.168 5.863,16 8,16 C9.799,16 11.504,15.404 12.9,14.314 L18.293,19.706 L19.707,18.293 Z M8,14 C6.397,14 4.891,13.376 3.758,12.243 C2.624,11.11 2,9.603 2,8 C2,6.398 2.624,4.891 3.758,3.758 C4.891,2.624 6.397,2 8,2 C9.603,2 11.109,2.624 12.242,3.758 C13.376,4.891 14,6.398 14,8 C14,9.603 13.376,11.11 12.242,12.243 C11.109,13.376 9.603,14 8,14 Z'
      fill={props.color}
    />
  </svg>

export default IconSearch
