import React from 'react'

// Used https://svgr.now.sh/ to convert svg file to react component
// Can be used locally https://github.com/smooth-code/svgr
// NOTE: The id's has to be changed after export from Sketch..
// otherwise the links overwrites each other between svgs
// Should find a better way to this
const GradientLock = props => (
  <svg
    width={124}
    height={169}
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...props}
  >
    <title>{`Icon/Lock`}</title>
    <defs>
      <path id='gradient-lock-b' d='M311 105h1093v562H311z' />
      <filter
        x='-1.3%'
        y='-1.8%'
        width='102.6%'
        height='105%'
        filterUnits='objectBoundingBox'
        id='gradient-lock-a'
      >
        <feOffset dy={4} in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur
          stdDeviation={4}
          in='shadowOffsetOuter1'
          result='shadowBlurOuter1'
        />
        <feColorMatrix
          values='0 0 0 0 0.0470588235 0 0 0 0 0 0 0 0 0 0.2 0 0 0 0.1 0'
          in='shadowBlurOuter1'
        />
      </filter>
      <path
        d='M119.708 71.191v-21.9c0-19.413-15.794-35.208-35.208-35.208-19.414 0-35.208 15.795-35.208 35.209v21.9c-8.697 8.893-14.084 21.04-14.084 34.433 0 27.18 22.118 49.292 49.292 49.292 27.174 0 49.292-22.111 49.292-49.292 0-13.393-5.387-25.54-14.084-34.434zM84.5 28.167c11.647 0 21.125 9.478 21.125 21.125v11.872c-6.408-3.07-13.562-4.83-21.125-4.83-7.563 0-14.717 1.76-21.125 4.83V49.292c0-11.647 9.478-21.125 21.125-21.125zm0 112.666c-19.414 0-35.208-15.794-35.208-35.208 0-19.414 15.794-35.208 35.208-35.208 19.414 0 35.208 15.794 35.208 35.208 0 19.414-15.794 35.208-35.208 35.208zm0-14.083c5.834 0 10.563-4.729 10.563-10.563 0-5.833-4.73-10.562-10.563-10.562-5.834 0-10.563 4.729-10.563 10.563 0 5.833 4.73 10.562 10.563 10.562z'
        id='gradient-lock-c'
      />
      <linearGradient x1='.989%' y1='100%' x2='100%' y2='0%' id='gradient-lock-e'>
        <stop stopColor='#24136D' offset='0%' />
        <stop stopColor='#EF4266' offset='100%' />
      </linearGradient>
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path fill='#F4F3F6' d='M-796-144H644v898H-796z' />
      <g transform='translate(-796 -144)'>
        <use fill='#000' filter='url(#gradient-lock-a)' xlinkHref='#gradient-lock-b' />
        <use fill='#FFF' xlinkHref='#gradient-lock-b' />
      </g>
      <g transform='translate(-23)'>
        <path d='M0 0h169v169H0z' />
        <mask id='gradient-lock-d' fill='#fff'>
          <use xlinkHref='#gradient-lock-c' />
        </mask>
        <g mask='url(#gradient-lock-d)' fill='url(#gradient-lock-e)'>
          <path d='M0 0h169v169H0z' />
        </g>
      </g>
    </g>
  </svg>
)

export default GradientLock
