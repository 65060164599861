import React, { Component } from 'react'

import { ActionButton } from 'components/Buttons/index'

import RefundModal from '../RefundModal'

class RefundButton extends Component {
  constructor (props) {
    super(props)

    this.state = {
      modalIsOpen: false
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal () {
    this.setState({ modalIsOpen: true })
  }

  closeModal () {
    this.setState({ modalIsOpen: false })
  }

  render () {
    if (this.state.modalIsOpen) {
      return (
        <RefundModal
          invoice={this.props.invoice}
          onClose={this.closeModal}
        />
      )
    } else {
      return (
        <ActionButton
          txt='Refund'
          icon='refund white'
          onClick={this.openModal}
        />
      )
    }
  }
}

export default RefundButton
