import React from 'react'
import { Link } from 'react-router-dom'

import { Badge } from 'components/Badges'
import { countDeclinedPayments } from './Helpers'

// TODO: Refactor this mess
const InvoiceBadge = ({ invoice, operatorID, memberID }) => {
  let badgeText
  if (invoice.status === 'charged') {
    badgeText = invoice.status
  } else if (invoice.status === 'scheduled') {
    badgeText = `${invoice.status} (${invoice.total})`
    return (
      <Link to={`/operators/${operatorID}/members/${memberID}/scheduled-invoices`}>
        <Badge className={`status ${invoice.status}`} txt={badgeText.toUpperCase()} />
      </Link>
    )
  } else {
    badgeText = `${invoice.status} (${countDeclinedPayments(invoice)})`
  }

  return <Badge className={`status ${invoice.status}`} txt={badgeText.toUpperCase()} />
}

export default InvoiceBadge
